// @flow

import { Spinner } from 'react-bootstrap'
import { ALERT_TYPE, Alert, ContentDiv } from 'components/ReUsable'
import TagList, { StyledHeader } from './tagList'
import { useTagContext } from './context/tagContext'

const TagContent = () => {
  const { personal, loading, groups, readError } = useTagContext()
  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  return (
    <main role="main">
      <ContentDiv>
        {readError ? (
          <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} />
        ) : (
          <>
            <TagList tags={personal} />
            <hr/>
            <StyledHeader>Group Tags:</StyledHeader>
            {Object.keys(groups).map((k: string) => {
              return <TagList key={k} tags={groups[k].tags || []} groupName={groups[k].name} groupSlug={k} />
            })}
          </>
        )}
      </ContentDiv>
    </main>
  )
}

export default TagContent
