// @flow

import styled from 'styled-components'

import {
  Col,
  CardSectionTitle,
  CardSection,
  DataRow,
  DataRowTitle,
  DataRowValue,
  ShowDataValue
} from 'components/ReUsable'

import FuelCartridgesListSection from './fuelCartridgesListSection'
import FuelCartridgesOverviewSection from './fuelCartridgesOverviewSection'

import { capFuelPercentageValue } from 'utils/helpers'
import LevelSensor from './levelSensor'
import { AnimatedCardSection } from 'components/ReUsable/Card'

const RemainingTotalFuelSpan = styled.span`
  padding: 1rem;
`

export const Cartridge = styled.div`
  display: inline-block;
  padding: 0.2rem;
  position: relative;
  max-width: 5.1rem;
`

export const LevelMax = styled.div`
  position absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  right: 0.5rem;
  top: 1.4rem;
`

export const Level = styled.div<{height: number}>`
  position absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-primary);
  height: ${(props: any) => props.height}%;
  z-index: 1;

  ${(props: any) => {
    if (props.height <= 10) {
      return 'background: var(--color-alert-error);'
    } else if (props.height <= 40) {
      return 'background: var(--color-alert-warning);'
    }
  }}
`

type Props = {
  fuelCellCartridges: {
    remainingTotalFuelPercent: string,
    connectedCartridges: Array<any>,
    isFuelLevelBelowSensor: boolean
  },
  isExpanded: boolean,
  isConnected?: boolean,
  permissions?: any
}

export default function FuelCartridgesSection (props: Props) {
  const { fuelCellCartridges, isExpanded } = props

  let mode = ''
  // Check if the user prefers a dark color scheme
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    mode = '-dark'
  }

  return (
    <Col>
      <CardSectionTitle>
        <span>Fuel Cartridges</span>
      </CardSectionTitle>
      { fuelCellCartridges && fuelCellCartridges.connectedCartridges.length > 1 &&
        <CardSection >
          <DataRow>
            <DataRowTitle>Remaining total fuel</DataRowTitle>
            <DataRowValue>
              {
                fuelCellCartridges &&
                <Cartridge>
                  <LevelMax>
                    <Level height={capFuelPercentageValue(fuelCellCartridges.remainingTotalFuelPercent)}></Level>
                  </LevelMax>
                  <img alt='Remaining total fuel' src={`/imgs/fuel-cartridges${mode}.svg`} />
                </Cartridge>
              }
              <RemainingTotalFuelSpan>
                <ShowDataValue data={ fuelCellCartridges } property={ 'remainingTotalFuelPercent' } suffix={ '%' } showZero decimals={2}/>
              </RemainingTotalFuelSpan>
            </DataRowValue>
          </DataRow>
        </CardSection>
      }
      { fuelCellCartridges && fuelCellCartridges.connectedCartridges.length > 1 &&
        <CardSection>
          <DataRowTitle>Fuel cartridges overview</DataRowTitle>
          <FuelCartridgesOverviewSection {...props}></FuelCartridgesOverviewSection>
        </CardSection>
      }
      { fuelCellCartridges && fuelCellCartridges.connectedCartridges.length > 1 &&
        <CardSection>
          <DataRowTitle>Fuel cartridge sensor</DataRowTitle>
          <LevelSensor isFuelLevelBelowSensor={fuelCellCartridges.isFuelLevelBelowSensor} />
        </CardSection>
      }

      { fuelCellCartridges && fuelCellCartridges.connectedCartridges.length === 1 &&
        <CardSection>
          <FuelCartridgesListSection {...props}></FuelCartridgesListSection>
        </CardSection>
      }

      { fuelCellCartridges && fuelCellCartridges.connectedCartridges.length > 1 &&
        <AnimatedCardSection {...{ height: '100%', expanded: isExpanded, noSpace: true }}>
          <CardSection style={{ margin: '0' }}>
            <FuelCartridgesListSection {...props}></FuelCartridgesListSection>
          </CardSection>
        </AnimatedCardSection>
      }
    </Col>
  )
}
