import React from 'react'
import styled from 'styled-components'

interface MinMaxNumberInput {
  min: number
  max: number
  value: number | undefined
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const StyledInput = styled.input`
  padding-left: 8px;
  padding-right: 8px;
`

const MinMaxNumberInput: React.FC<MinMaxNumberInput> = ({ min, max, value, name, onChange, disabled }) => {
  //  const [formValue, setFormValue] = useState(value)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    // Convert input value to a number
    const numericValue = Number(newValue)

    // Check if the value is within the min and max range
    if (newValue === '' || (numericValue >= min && numericValue <= max)) {
      //  setFormValue(formValue)
      onChange(e)
    }
  }

  return <StyledInput name={name} type="text" value={value} onChange={handleChange} min={min} max={max} disabled={disabled} />
}

export default MinMaxNumberInput
