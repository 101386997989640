// @flow

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

type Props = {
  show: boolean,
  title: string,
  className?: string,
  id?: string,
  size?: 'sm' | 'lg' | 'xl',
  children: React.ReactNode,
  hideCloseButton?: boolean,
  onClose?: () => void,
}

export const SectionTitle = styled.p`
  color: var(--color-text);
  font-family: digital-serial;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
`

export const NavigationArrow = styled(FontAwesomeIcon)<FontAwesomeIconProps & { disabled?: boolean }>`
  color: ${(props) => props.disabled ? 'var(--color-disabled)' : 'var(--color-primary)'};
  font-size: 2.5rem;
  transition: all 0.3s;
  flex: 0 0 4rem;
  min-width: 44px;

  &:hover, &:active {
    color: ${(props) => props.disabled ? 'var(--color-disabled)' : 'var(--color-primary-hover)'};;
  }
`

export const PreviousButton = styled(NavigationArrow)`
  &:hover, &:active {
    transform: translate(-0.2rem);
  }
`

export const NextButton = styled(NavigationArrow)`
  &:hover, &:active {
    transform: translate(0.2rem);
  }
`

export default function Dialog (props: Props) {
  const defaultClass = 'modal fade show'

  if (!props.show) {
    return null
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      keyboard={false}
      id={ `${props.id ? props.id : ''}` }
      className={ `${defaultClass} ${props.className ? props.className : ''}` }
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
      size={props.size || undefined}
      aria-labelledby={ props.id }
    >
      <Modal.Header closeButton={!props.hideCloseButton}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.children }
      </Modal.Body>
    </Modal>
  )
}
