// @flow

import styled from 'styled-components'
import { getStatusMessage } from 'helpers/status_message'

import { Col, Gap, Row, StatusIndicator } from 'components/ReUsable'

import { getLastUpdatedValue, formatTimestamp } from 'utils/timeCalculations'

type Props = {
  values: {
    state: string
    lastUpdated: string
    isConnected: boolean
  }
  status?: string
  'data-cy'?: string
  inServiceMode?: boolean
}

const colorSelect = (status: string) => {
  switch (status) {
    case 'warning':
      return 'var(--color-alert-warning)'
    case 'unknown':
    case 'error':
    case 'emergency_frost_protection':
    case 'transportLock':
      return 'var(--color-alert-error)'
    case 'inService':
      return 'var(--color-alert-warning)'
    default:
      return 'var(--color-text-muted)'
  }
}

const DetailStateIndicator = styled.div`
  font-family: open-sans-regular;
  width: 100%;
`

const TimeDifference = styled.span`
  font-family: open-sans-bold;
`

export const StateText = styled.span`
  font-family: open-sans-bold;
  color: ${(props: any) => colorSelect(props.status)};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function DetailState (props: Props) {
  const { state, isConnected, lastUpdated } = props.values

  return (
    <DetailStateIndicator>
      <Col>
        {props.inServiceMode ? (
          <Row noWrap={true} data-cy="service-section">
            <StateText {...{ status: 'inService' }}>In service</StateText>
          </Row>
        ) : null}
        <Row noWrap={true} data-cy="status-section">
          <StatusIndicator status={state} isConnected={isConnected} />
          <Gap width="0.2rem" />
          <Col>
            <StateText {...{ status: state }} data-cy="page-device-details-state">
              {`State: ${getStatusMessage(state)}`}
            </StateText>
          </Col>
        </Row>
        {isConnected === false && (
          <Row noWrap={true}>
            <span>
              Online:{' '}
              <TimeDifference>
                {getLastUpdatedValue(lastUpdated)} {lastUpdated && `(${formatTimestamp(lastUpdated)})`}
              </TimeDifference>
            </span>
          </Row>
        )}
      </Col>
    </DetailStateIndicator>
  )
}
