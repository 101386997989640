// @flow

import styled from 'styled-components'

import {
  Row,
  Col,
  PageTitle,
  PageSubTitle,
  Text,
  Container
} from 'components/ReUsable'

const Link = styled.a`
  text-decoration: underline;
`

const SubTitle = styled.span`
  color: var(--color-primary);
  font-size: 14px;
`

const List = styled.ul`
  color: var(--color-text);
  font-family: open-sans-regular;
  font-size: 14px;
  line-height: 18px;
`

export default function Gdpr () {
  window.scrollTo(0, 0)

  return (
    <Container className={'bg-content mt-5 p-5'}>
      <Row>
        <Col>
          <PageTitle>Privacy Policy</PageTitle>
          <Text>As of November 2023</Text>
          <PageSubTitle>Table of contents</PageSubTitle>
          <SubTitle>
            I.Identity and contact details of the data controller<br/>
            II.Contact details of the data protection officer<br/>
            III.General information on data processing<br/>
            IV.Rights of the data subject<br/>
            V.Registration and data processing on the platform<br/>
            VI.Use of cookies<br/>
            VII.Contact via email<br/>
            VIII.Hosting<br/>
            IX.Analytics of device data<br/>
            X.Use of Matomo<br/>
          </SubTitle>
          <PageSubTitle>
            I.Identity and contact details of the data controller
          </PageSubTitle>
          <Text>
            The data controller responsible in accordance with the purposes
            of the General Data Protection Regulation (GDPR) of the
            European Union and other data protection regulations is:
          </Text>
          <Text>
            SFC Energy AG<br/>
            Eugen-Saenger-Ring 7<br/>
            85649 Brunnthal<br/>
            Germany<br/>
            Phone: +49 89 67 35 92-0<br/>
            Fax: +49 89 67 35 92-169<br/>
            Mail: info@sfc.com<br/>
          </Text>
          <PageSubTitle>
            II.Contact details of the data protection officer
          </PageSubTitle>
          <Text>
            The designated data protection officer is:
          </Text>
          <Text>
            DataCo GmbH<br/>
            Dachauer Straße 65<br/>
            80335 Munich<br/>
            Germany<br/>
            +49 89 7400 45840<br/>
            <Link href='https://www.dataguard.de'>
              www.dataguard.de
            </Link>
            <br/>
          </Text>
          <PageSubTitle>
            III.General information on data processing
          </PageSubTitle>
          <Text>
            On this page we inform you about the privacy policy applicable
            on the EFOY Fuel Cell management portal of SFC Energy AG
            (&quot;Platform&quot;). The Platform is an offer of SFC Energy AG, Eugen-
            Saenger-Ring 7, 85649 Brunnthal, Germany (&quot;SFC Energy AG&quot;, &quot;we&quot;
            or &quot;us&quot;).
          </Text>
          <Text>
            The EFOY Fuel Cell management portal of SFC Energy AG offers
            users the opportunity to view data on the current status of
            their EFOY Fuel Cells. To do this, users can create an account
            and add fuel cells to it.
          </Text>
          <SubTitle>
            1. Scope of the processing of personal data
          </SubTitle>
          <Text>
            As a matter of principle, we only process personal data of our
            users to the extent that this is necessary for the provision of
            a functioning platform as well as our content and services. The
            processing of personal data of our users is regularly carried
            out only after the consent of the user or the active request
            for the creation of a user account. An exception applies in
            those cases where it is not possible to obtain prior consent
            for actual reasons and the processing of the data is required
            by legal regulations.
          </Text>
          <SubTitle>
            2. Purpose of the processing of personal data
          </SubTitle>
          <Text>
            Explicit purposes of the processing result from Chapters V to
            VIII.
          </Text>
          <SubTitle>
            3. Legal basis for data processing
          </SubTitle>
          <Text>
            Where consent is appropriate for processing personal data, Art.
            6 (1) (1) (a) GDPR serves as the legal basis to obtain the
            consent of the data subject for the processing of their data.
          </Text>
          <SubTitle>
            4. Data removal and storage duration
          </SubTitle>
          <Text>
            The personal data of the data subject will be erased or
            restricted as soon as the purpose of its storage has been
            accomplished. Additional storage may occur if this is provided
            for by the European or national legislator within the EU
            regulations, law, or other relevant regulations to which the
            data controller is subject. Restriction or erasure of the data
            also takes place when the storage period stipulated by the
            aforementioned standards expires, unless there is a need to
            prolong the storage of the data for the purpose of concluding
            or fulfilling the respective contract.
          </Text>
          <SubTitle>
            5. Right to withdraw the data protection consent declaration
          </SubTitle>
          <Text>
            The User may object to the processing by sending an e-mail to
            info@sfc.com, unless the processing is necessary for the
            performance of the contract.
          </Text>
          <PageSubTitle>
            IV.Rights of the data subject
          </PageSubTitle>
          <Text>
            When your personal data is processed, you are a data subject
            within the meaning of the GDPR and have the following rights:
          </Text>
          <SubTitle>
            1. Right to information
          </SubTitle>
          <Text>
            You may request the data controller to confirm whether your
            personal data is processed by them.
          </Text>
          <Text>
            If such processing occurs, you can request the following
            information from the data controller:
          </Text>
          <List>
            <li>The purpose for which the personal data is processed.</li>
            <li>The categories of personal data being processed.</li>
            <li>
              The recipients or categories of recipients to whom the
              personal data have been or will be disclosed.
            </li>
            <li>
              The planned duration of the storage of your personal data
              or, if specific information is not available, criteria
              for determining the duration of storage.
            </li>
            <li>
              The existence of the right to request from the controller
              rectification or erasure of personal data or restriction
              of processing of personal data concerning you or to
              object to such processing.
            </li>
            <li>
              The existence of the right to lodge a complaint with a
              supervisory authority.
            </li>
            <li>
              Where personal data are not collected from you any
              available information as to their source.
            </li>
            <li>
              The existence of automated decision-making including
              profiling under Article 22 (1) and Article 22 (4) GDPR
              and, in certain cases, meaningful information about the
              data processing system involved, and the scope and
              intended result of such processing on the data subject.
            </li>
          </List>
          <Text>
            You have the right to request information on whether your
            personal data will be transmitted to a third country or an
            international organization. In this context, you can then
            request for the appropriate guarantees in accordance with Art.
            46 GDPR in connection with the transfer.
          </Text>
          <Text>
            Your right to information may be limited where it is likely
            that such restriction will render impossible or seriously
            impede the achievement of scientific or statistical purposes
            and that such limitation is necessary for the achievement of
            scientific or statistical purposes.
          </Text>
          <SubTitle>
            2. Right to rectification
          </SubTitle>
          <Text>
            You have a right to rectification and/or modification of the
            data, if your processed personal data is incorrect or
            incomplete. The data controller must correct the data without
            delay
          </Text>
          <Text>
            Your right to rectification may be limited to the extent that
            it is likely to render impossible or seriously impair the
            achievement of the purposes of the research or statistical work
            and the limitation is necessary for the achievement of the
            purposes of the research or statistical work.
          </Text>
          <SubTitle>
            3. Right to the restriction of processing
          </SubTitle>
          <Text>
            You may request the restriction of the processing of your
            personal data under the following conditions:
          </Text>
          <List>
            <li>
              If you challenge the accuracy of your personal data for a
              period that enables the data controller to verify the
              accuracy of your personal data.
            </li>
            <li>
              The processing is unlawful, and you oppose the erasure of
              the personal data and instead request the restriction of
              their use instead.
            </li>
            <li>
              The data controller or its representative no longer need
              the personal data for the purpose of processing, but you
              need it to assert, exercise or defend legal claims; or
            </li>
            <li>
              If you have objected to the processing pursuant to Art.
              21 (1) GDPR and it is not yet certain whether the
              legitimate interests of the data controller override your
              interests.
            </li>
          </List>
          <Text>
            If the processing of personal data concerning you has been
            restricted, this data may – with the exception of data storage
            – only be used with your consent or for the purpose of
            asserting, exercising or defending legal claims or protecting
            the rights of another natural or legal person or for reasons of
            important public interest of the Union or of a Member State.
          </Text>
          <Text>
            If the processing has been restricted according to the
            aforementioned conditions, you will be informed by the data
            controller before the restriction is lifted.
          </Text>
          <Text>
            Your right to restrict the processing may be limited where it
            is likely that such restriction will render impossible or
            seriously impede the achievement of scientific or statistical
            purposes and that such limitation is necessary for the
            achievement of scientific or statistical purposes.
          </Text>
          <SubTitle>
            4. Right to erasure
          </SubTitle>
          <Text>
            a) Obligation to erase
          </Text>
          <Text>
            If you request from the data controller to delete your personal
            data without undue delay, they are required to do so
            immediately if one of the following applies:
          </Text>
          <List>
            <li>
              Personal data concerning you is no longer necessary for
              the purposes for which they were collected or processed.
            </li>
            <li>
              You withdraw your consent on which the processing is
              based pursuant to Art. 6 (1) (1) (a) and Art. 9 (2) (a)
              GDPR and where there is no other legal basis for
              processing the data.
            </li>
            <li>
              According to Art. 21 (1) GDPR you object to the
              processing of the data and there are no longer overriding
              legitimate grounds for processing, or you object pursuant
              to Art. 21 (2) GDPR.
            </li>
            <li>
              The recipients or categories of recipients to whom the
              personal data have been or will be disclosed.
            </li>
            <li>
              Your personal data has been processed unlawfully.
            </li>
            <li>
              The personal data must be deleted to comply with a legal
              obligation in Union law or Member State law to which the
              data controller is subject.
            </li>
            <li>
              Your personal data was collected in relation to
              information society services offered pursuant to Art. 8
              (1) GDPR.
            </li>
          </List>
          <Text>
            b) Information to third parties
          </Text>
          <Text>
            If the data controller has made your personal data public and
            must delete the data pursuant to Art. 17 (1) GDPR, they shall
            take appropriate measures, including technical means, to inform
            data processors who process the personal data, that a request
            has been made to delete all links to such personal data or
            copies or replications of the personal data, taking into
            account available technology and implementation costs to
            execute the process.
          </Text>
          <Text>
            c) Exceptions
          </Text>
          <Text>
            The right to deletion does not exist if the processing is
            necessary
          </Text>
          <List>
            <li>
              to exercise the right to freedom of speech and
              information;
            </li>
            <li>
              to fulfil a legal obligation required by the law of the
              Union or Member States to which the data controller is
              subject, or to perform a task of public interest or in
              the exercise of public authority delegated to the
              representative.
            </li>
            <li>
              for reasons of public interest in the field of public
              health pursuant to Art. 9 (2) (h) and Art. 9 (2) (i) and
              Art. 9 (3) GDPR.
            </li>
            <li>
              for archival purposes of public interest, scientific or
              historical research purposes or for statistical purposes
              pursuant to Art. 89 (1) GDPR, to the extent that the law
              referred to in subparagraph (a) is likely to render
              impossible or seriously affect the achievement of the
              objectives of that processing, or
            </li>
            <li>
              to enforce, exercise or defend legal claims.
            </li>
          </List>
          <SubTitle>
            5. Right to information
          </SubTitle>
          <Text>
            If you have the right of rectification, erasure or restriction
            of processing over the data controller, they are obliged to
            notify all recipients to whom your personal data have been
            disclosed of the correction or erasure of the data or
            restriction of processing, unless this proves to be impossible
            or involves a disproportionate effort.
          </Text>
          <Text>
            You reserve the right to be informed about the recipients of
            your data by the data controller.
          </Text>
          <SubTitle>
            6. Right to data portability
          </SubTitle>
          <Text>
            You have the right to receive your personal data given to the
            data controller in a structured and machine-readable format. In
            addition, you have the right to transfer this data to another
            person without hindrance by the data controller who was
            initially given the data, if:
          </Text>
          <List>
            <li>
              the processing is based on consent in accordance with
              Art. 6 (1) (1) (a) GDPR or Art. 9 (2) (a) GDPR or
              performance of a contract in accordance with Art. 6 (1)
              (1) (b) GDPR and
            </li>
            <li>
              the processing is done by automated means.
            </li>
          </List>
          <Text>
            In exercising this right, you also have the right to transmit
            your personal data directly from one person to another, insofar
            as this is technically feasible. Freedoms and rights of other
            persons shall not be affected.
          </Text>
          <Text>
            The right to data portability does not apply to the processing
            of personal data necessary for the performance of a task in the
            public interest or in the exercise of official authority
            delegated to the data controller.
          </Text>
          <SubTitle>
            7. Right to object
          </SubTitle>
          <Text>
            For reasons that arise from your particular situation, you
            have, at any time, the right to object to the processing of
            your personal data pursuant to Art. 6 (1) (1) (e) or 6 (1) (1)
            (f) GDPR; this also applies to profiling based on these
            provisions.
          </Text>
          <Text>
            The data controller will no longer process the personal data
            concerning you unless he can demonstrate compelling legitimate
            grounds for processing that outweigh your interests, rights and
            freedoms, or the processing is for the purpose of enforcing,
            exercising or defending legal claims.
          </Text>
          <Text>
            If the personal data relating to you are processed for direct
            marketing purposes, you have the right to object at any time to
            the processing of your personal data in regard to such
            advertising; this also applies to profiling associated with
            direct marketing.
          </Text>
          <Text>
            If you object to processing for direct marketing purposes, your
            personal data will no longer be processed for these purposes.
          </Text>
          <Text>
            Regardless of Directive 2002/58/EG, you have the option, in the
            context of the use of information society services, to exercise
            your right to object to automated decisions that use technical
            specifications.
          </Text>
          <Text>
            Your right to objection may be limited where it is likely that
            such restriction will render impossible or seriously impede the
            achievement of scientific or statistical purposes and that such
            limitation is necessary for the achievement of scientific or
            statistical purposes.
          </Text>
          <SubTitle>
            8. Right to withdraw the data protection consent declaration
          </SubTitle>
          <Text>
            You have the right to withdraw your consent at any time. The
            withdrawal of consent does not affect the legality of the
            processing carried out on the basis of the consent until the
            withdrawal.
          </Text>
          <SubTitle>
            9. Automated decisions on a case-by-case basis, including
            profiling
          </SubTitle>
          <Text>
            You have the right to not be subject to a decision based solely
            on automated processing – including profiling – that will have
            a legal effect or substantially affect you in a similar manner.
            This does not apply if the decision:
          </Text>
          <List>
            <li>
              is required for the conclusion or execution of a contract
              between you and the data controller,
            </li>
            <li>
              is permitted by the Union or Member State legislation to
              which the data controller is subject, and where such
              legislation contains appropriate measures to safeguard
              your rights and freedoms and legitimate interests, or
            </li>
            <li>
              is based on your explicit consent.
            </li>
          </List>
          <Text>
            However, these decisions must not be based on special
            categories of personal data under Art. 9 (1) GDPR, unless Art.
            9 (2) (a) or Art. 9 (2) (b) GDPR applies and reasonable
            measures have been taken to protect your rights and freedoms as
            well as your legitimate interests.
          </Text>
          <Text>
            With regard to the cases referred to in (1) and (3), the data
            controller shall take appropriate measures to uphold your
            rights and freedoms as well as your legitimate interests,
            including the right to obtain assistance from the data
            controller or his representative, to express your opinion on
            the matter, and to contest the decision.
          </Text>
          <SubTitle>
            10. Right to complain to a supervisory authority
          </SubTitle>
          <Text>
            Without prejudice to any other administrative or judicial
            remedy, you shall have the right to complain to a supervisory
            authority, in the Member State of your residence, or your place
            of work or place of alleged infringement, if you believe that
            the processing of the personal data concerning you violates the
            GDPR.
          </Text>
          <Text>
            The supervisory authority to which the complaint has been
            submitted shall inform the complainant of the status and
            results of the complaint, including the possibility of a
            judicial remedy pursuant to Art. 78 GDPR.
          </Text>
          <PageSubTitle>
            V.Registration and data processing on the platform
          </PageSubTitle>
          <SubTitle>
            1. Scope of the processing of personal data
          </SubTitle>
          <Text>
            On our platform, we offer users the opportunity to register by
            providing personal data. The data is entered in an input mask
            and transmitted to us and stored. The data is not passed on to
            third parties. The following data is collected during the
            registration process:
          </Text>
          <List>
            <li>Name</li>
            <li>Email address</li>
            <li>Login password</li>
            <li>Phone number</li>
            <li>Address</li>
            <li>Country</li>
            <li>Company</li>
            <li>Website URL</li>
          </List>
          <Text>
            As part of the registration process, the user&apos;s consent to the
            processing of this data is obtained.
          </Text>
          <SubTitle>
            2. Purpose of the processing of personal data
          </SubTitle>
          <Text>
            User registration is required for the provision of certain
            content and services on our platform. Individual fuel cells can
            only be added and their status viewed via a user account.
          </Text>
          <SubTitle>
            3. Legal basis for the processing of personal data
          </SubTitle>
          <Text>
            The legal basis for the registration is the consent according
            to Art. 6 (1) (1) (a) GDPR. In addition, we process personal
            data on the basis of our legitimate interest in providing a
            functional platform (Art. 6 (1) (1) (f) GDPR).
          </Text>
          <SubTitle>
            4. Data deletion and storage duration
          </SubTitle>
          <Text>
            The data is deleted as soon as it is no longer required to
            achieve the purpose for which it was collected.
          </Text>
          <Text>
            This is the case for the data collected during the registration
            process when the registration on our platform is cancelled or
            modified.
          </Text>
          <SubTitle>
            5. Right to object and to withdraw the data protection consent
            declaration
          </SubTitle>
          <Text>
            As a user, you have the option to cancel the registration and
            delete your user account at any time under the menu item &quot;User
            Account&quot;. You can have the data stored about you changed at any
            time. To do so, send an informal e-mail to info@sfc.com.
          </Text>
          <PageSubTitle>
            VI.Use of cookies
          </PageSubTitle>
          <SubTitle>
            1. Description and scope of data processing
          </SubTitle>
          <Text>
            Our platform uses cookies. Cookies are text files that are
            stored in the Internet browser or by the Internet browser on
            the user&apos;s computer system. If a user calls up the platform, a
            cookie may be stored on the user&apos;s operating system. This
            cookie contains a characteristic character string that enables
            the browser to be uniquely identified when the platform is
            called up again.
          </Text>
          <Text>
            We use cookies to make our Platform more user-friendly. Some
            elements of our platform require that the calling browser can
            be identified even after a page change.
          </Text>
          <Text>
            The following data is stored and transmitted in the cookies:
          </Text>
          <List>
            <li>Login information</li>
            <li>Use of platform functions</li>
          </List>
          <Text>
            The user data collected in this way is pseudonymized by
            technical precautions. Therefore, an assignment of the data to
            the calling user is no longer possible. The data is not stored
            together with other personal data of the users.
          </Text>
          <SubTitle>
            2. Purpose of data processing
          </SubTitle>
          <Text>
            The purpose of using technically necessary cookies is to
            simplify the use of websites or platforms for users. Some
            functions of our platform cannot be offered without the use of
            cookies. For these, it is necessary that the browser is
            recognized even after a page change.
          </Text>
          <Text>
            The user data collected through technically necessary cookies
            are not used to create user profiles.
          </Text>
          <SubTitle>
            3. Legal basis for data processing
          </SubTitle>
          <Text>
            The legal basis for the processing of personal data using
            technically necessary cookies is Art. 6 (1) (1) (f) GDPR.
          </Text>
          <SubTitle>
            4. Duration of storage, possibility of objection and deletion
          </SubTitle>
          <Text>
            Cookies are stored on the user&apos;s computer and transmitted from
            it to our site. Therefore, you as a user also have full control
            over the use of cookies. By changing the settings in your
            Internet browser, you can disable or restrict the transmission
            of cookies. Cookies that have already been stored can be
            deleted at any time. This can also be done automatically. If
            cookies are deactivated for our platform, it may no longer be
            possible to fully use all functions of the platform.
          </Text>
          <Text>
            If you use a Safari browser from version 12.1, cookies are
            automatically deleted after seven days. This also applies to
            opt-out cookies, which are set to prevent tracking measures.
          </Text>
          <PageSubTitle>
            VII.Contact via email
          </PageSubTitle>
          <SubTitle>
            1. Description and scope of data processing
          </SubTitle>
          <Text>
            You can contact us via the email address provided on our
            platform. In this case the personal data of the user
            transmitted with the email will be stored.
          </Text>
          <Text>
            The data will be used exclusively for the processing of the
            conversation.
          </Text>
          <SubTitle>
            2. Purpose of data processing
          </SubTitle>
          <Text>
            If you contact us via email, this also constitutes the
            necessary legitimate interest in the processing of the data.
          </Text>
          <SubTitle>
            3. Legal basis for data processing
          </SubTitle>
          <Text>
            The legal basis for the processing of data transmitted while
            sending an email is Art. 6 (1) (1) (f) GDPR. If the purpose of
            the email contact is to conclude a contract, the additional
            legal basis for the processing is Art. 6 (1) (b) GDPR.
          </Text>
          <SubTitle>
            4. Duration of storage
          </SubTitle>
          <Text>
            The data will be deleted as soon as it is no longer necessary
            to achieve the purpose for which it was collected. For personal
            data sent by email, this is the case when the respective
            conversation with the user has ended. The conversation ends
            when it can be concluded from the circumstances that the matter
            in question has been conclusively resolved.
          </Text>
          <SubTitle>
            5. Objection and removal
          </SubTitle>
          <Text>
            The user has the option to revoke his consent to the processing
            of personal data at any time. If the user contacts us by e-mail
            at info@sfc.com, he can object to the storage of his personal
            data at any time. In such a case, the conversation cannot be
            continued.
          </Text>
          <Text>
            In this case, all personal data stored while establishing
            contact will be deleted.
          </Text>
          <PageSubTitle>
            VIII.Hosting
          </PageSubTitle>
          <Text>
            The platform is hosted on Microsoft Azure servers. Third
            parties do not have access to server log files.
          </Text>
          <Text>
            The servers automatically collect and store information in so-called server log files, which your browser automatically
            transmits when you visit the platform. The data is deleted
            after 7 days. The stored information is:
          </Text>
          <List>
            <li>Browser type and browser version</li>
            <li>Operating system used</li>
            <li>Referrer URL</li>
            <li>Host name of the accessing computer</li>
            <li>Date and time of the server request</li>
            <li>The request sent</li>
            <li>IP address</li>
          </List>
          <Text>
            This data is not merged with other data sources. The collection
            of this data is based on Art. 6 (1) (1) (f) GDPR. The platform
            operator has a legitimate interest in the technically error-free presentation and optimization of its platform - for this
            purpose, the server log files must be collected.
          </Text>
          <Text>
            The location of the server of the platform is geographically in
            the Netherlands.
          </Text>
          <PageSubTitle>
            IX.Analytics of device data
          </PageSubTitle>
          <SubTitle>
            1. Description and scope of data processing
          </SubTitle>
          <Text>
          We process data of the fuel cells for analytical purposes
          in connection with the use of our fuel cell cloud solution.
          This includes information generated during the use of the
          cloud solution, such as usage data (e. g. system state,
          lifetime, parameters etc.), technical data (e. g. voltage,
          currents, fill level etc.), log files, and location data.
          The data is anonymized, and it is not possible to generate user profiles.
          </Text>
          <SubTitle>
            2. Purpose of data processing
          </SubTitle>
          <Text>
          The data processing is carried out for analytical purposes
          to optimize the usage of our fuel cell cloud solution,
          identify issues, improve performance, and develop new features.
          By analyzing the data, we can gain insights that enable us
          to enhance the user experience and tailor our service
          to the needs of our users.
          </Text>
          <SubTitle>
            3. Legal basis for data processing
          </SubTitle>
          <Text>
            The legal basis for the processing of data for analytical purposes
            is based on the legitimate interest of the user
            according to Art. 6 (1) (1) (f) GDPR.
          </Text>
          <SubTitle>
            4. Duration of storage
          </SubTitle>
          <Text>
            The data will be deleted as soon as it is no longer
            necessary to achieve the purpose for which it was collected.
          </Text>
          <SubTitle>
            5. Objection and removal
          </SubTitle>
          <Text>
            The user may object to the processing. The objection
            can be sent to info@sfc.com. If you exercise your right
            to object, we request that you provide us with the grounds
            for your request as to why we should not process your
            personal information in the previous way. Upon receipt
            of your objection, we will review the facts and either stop
            or adapt the data processing, or explain to you our
            compelling and legitimate grounds on the basis of
            which we will continue the processing.
          </Text>
          <PageSubTitle>
            X.Use of Matomo
          </PageSubTitle>
          <SubTitle>
            1. Scope of processing of personal data
          </SubTitle>
          <Text>
            We use the open source tracking tool <Link href="https://matomo.org/" target="_blank">Matomo</Link> to analyse the surfing behavior of our users.
            Matomo places a cookieon your computer. This allows
            personal data to be stored and evaluated, in particular
            the user&apos;s activity (in particular which pages have
            been visited and which elements have been clicked on), device
            and browser information (in particular the IP address and the
            operating system), data on the advertisements displayed
            (in particular which advertisements have been displayed and
            whether the user has clicked on them) and also data on advertising
            partners (in particular pseudonymised user IDs).<br/>
            The software is set so that the IP addresses are not stored
            completely, but 2 bytes of the IP address are masked for
            anonymization (e.g.: 192.168.xxx.xxx). In this way, it is
            no longer possible to assign the shortened IP address to the calling
            computer. The data is stored in our MySQL database, logs or
            report data are not sent to Matomo servers.
          </Text>
          <Text>
            For more information about Matomo&apos;s collection and storage of data,
            please visit: <Link href="https://matomo.org/privacy-policy/" target="_blank">https://matomo.org/privacy-policy/</Link>
          </Text>
          <SubTitle>
            2. Purpose of data processing
          </SubTitle>
          <Text>
          The processing of users&apos; personal data enables us to analyse
          the surfing behaviour of our users. By evaluating the data collected,
          we are able to compile information on the use of the individual
          components of our online presence. This helps us to constantly
          improve our online presence and its user-friendliness.
          </Text>
          <SubTitle>
            3. Legal basis for the processing of personal data
          </SubTitle>
          <Text>
            The legal basis for the processing of personal data is the user&apos;s
            given consent in accordance with Art. 6 (1) (a) GDPR.
          </Text>
          <SubTitle>4. Duration of storage</SubTitle>
          <Text>
            Your personal information will be stored for as long as is necessary
            to fulfill the purposes described in this Privacy Policy or as
            required by law, e.g. for tax and accounting purposes.
          </Text>
          <SubTitle>
            5. Exercising your rights
          </SubTitle>
          <Text>
            You have the right to revoke your declaration of consent under
            data protection law at any time. The revocation of the consent does
            not affect the lawfulness of the processing carried out on the basis
            of the consent up to the revocation.
          </Text>
          <Text>
            You can prevent Matomo from collecting and processing your personal data
            by preventing the storage of cookies from third parties on your computer,
            by using the &quot;Do Not Track&quot; function of a supporting browser,
            by deactivating the execution of script code in your browser or by using
            a script blocker such as <Link href="https://noscript.net/" target="_blank">NoScript</Link> or <Link href="https://www.ghostery.com" target="_blank">Ghostery</Link> in your browser.
          </Text>
          <Text>With the following link you can deactivate the processing of your personal data by Matomo: <Link href='https://matomo.org/privacy-policy/' target='_blank'>https://matomo.org/privacy-policy/</Link></Text>
          <Text>
            For more information on objection and removal options against Matomo please visit: <Link href="https://matomo.org/privacy-policy/" target="_blank">
            https://matomo.org/privacy-policy/
          </Link>
          </Text>
          <Text>
            This privacy policy has been created with the assistance
            of <Link href='https://www.dataguard.de'>DataGuard</Link>.
          </Text>
        </Col>
      </Row>
    </Container>
  )
}
