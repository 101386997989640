import { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useNotificationContext } from './context/NotificationContext'
import {
  StyledRemoveMenu,
  StyledPrimaryFontAwesomeIcon,
  StyledAlertFontAwesomeIcon
} from 'components/Application/UserAccount/accessToken'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-light-svg-icons'
import { TrashButton } from 'components/ReUsable'

type NotificationInfoType = {
  publishDate: string
  userConfirmations: number
  id: string
}

const SyledNotificationInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: open-sans-regular;
  font-size: 13px;
  margin-top: 10px;
  max-width: 384px;
  @media (min-width: 1200px) {
    min-width: min(75vw, 500px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`

const StyledEditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
  background: transparent;

  &:hover {
    border: 1px solid var(--color-text);
  }
`

const StyledEditIcon = styled(FontAwesomeIcon)`
  min-width: 1.4rem;
  height: auto;
  color: var(--color-text);
`

export default function NotificationInfo ({
  publishDate,
  userConfirmations,
  id
}: NotificationInfoType) {
  const { setMode, setSelectedNotification, deleteNotification, allowWrite } =
    useNotificationContext()
  const [startRemove, setStartRemove] = useState<boolean>(false)
  return (
    <SyledNotificationInfo data-cy="notice-info">
      <div>
        {`Publish at: ${moment(publishDate).format('DD.MM.YYYY HH:mm')}`}
        <br />
        {`(id: ${id})`}
        <br />
        {`Confirmed by ${userConfirmations} user${
          userConfirmations > 1 ? 's' : ''
        }`}
      </div>
      {allowWrite ? (
        <ButtonsContainer>
          <StyledEditButton onClick={() => {
              setMode('EDIT')
              setSelectedNotification(id)
            }} data-cy="edit-notice-btn">
            <StyledEditIcon icon={faPen as any} className='icon-dark-mode-ready' />
          </StyledEditButton>
          {startRemove ? (
            <StyledRemoveMenu>
              <p>Delete?</p>&nbsp;
              <StyledPrimaryFontAwesomeIcon
              data-cy="delete-notice-approve"
                icon={['far', 'circle-check'] as any}
                onClick={() => deleteNotification(id)}
              />
              &nbsp;
              <StyledAlertFontAwesomeIcon
                data-cy="delete-notice-cancel"
                icon={['far', 'circle-xmark'] as any}
                onClick={() => setStartRemove(false)}
              />
              &nbsp;
            </StyledRemoveMenu>
            ) : (
              <TrashButton bordered={true} onClick={() => setStartRemove(true)} size={35} dataCy='delete-notice-btn'/>
            )
          }
        </ButtonsContainer>
      ) : null}
    </SyledNotificationInfo>
  )
}
