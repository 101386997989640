// @flow

import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
type ButtonTypes = 'submit' | 'reset' | 'button'

type Props = {
  className?: string,
  id?: string,
  disabled?: boolean,
  type?: ButtonTypes,
  width?: string,
  form?: string,
  color?: string,
  children: any,
  loading?: boolean,
  onClick?: (e: any) => void,
  name?: string,
  style?: any
}

const Element = styled.button`
  &, &:hover {
    ${(props: any) => {
      switch (props.color) {
        case 'primary':
        default:
          return `
            color: var(--color-lightest);
            background: var(--color-primary);
            border-color: var(--color-primary);
          `
      }
    }}
  }

  &:enabled:hover {
    transform: scale(0.95);
    opacity: 0.9;
  }

  border-radius: var(--dim-border-radius);
  white-space: nowrap;
  min-height: 3rem;
  min-width: ${(props: any) => props.width || '11rem'};
  font-weight: 400;
  font-size: .85rem;
  font-family: DigitalSerialBold;
  text-transform: uppercase;
  margin: 0.2rem 0;
  box-shadow: none;
  padding: 0 ${(props: any) => props.loading ? '0.3rem' : '1rem'} 0 1rem;
  letter-spacing: .6px;

  border-style: solid;
  vertical-align: middle;
  transition: transform 0.3s, opacity 0.3s;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  &:active {
    outline: none;
    box-shadow: 0 0 0 0.5rem var(--color-primary-30);
  }

  &:disabled {
    opacity: 1;
    filter: saturate(0.5);

    ${props => {
      switch (props.color) {
        case 'primary':
        default:
          return `
            color: var(--color-lightest);
            background: var(--color-disabled);
            border-color: var(--color-disabled);

            @media (prefers-color-scheme: dark) {
              border: 1px solid var(--color-accordion-table-border);
            }
          `
      }
    }}
  }
  &.no-text-transform {
    text-transform: none
  }
`
export default function Button (props: Props) {
  const { loading, ...buttonProps } = props
  return (
    <Element
      className={ `${props.className ? props.className : ''}` }
      id={ `${props.id ? props.id : ''}` }
      type={ props.type }
      width={ props.width }
      form={ props.form }
      disabled={ props.disabled || loading }
      onClick={ props.onClick }
      color={ props.color }
      {...buttonProps}
    >
      { props.children }{ loading && <Spinner animation='border' variant='light' size='sm' />}
    </Element>
  )
}
