import { useEffect, useRef } from 'react'
import { useAuth0 } from 'utils/react_auth0_spa'

const StaticHome = () => {
  const footerCopyRight = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const currentYear = new Date().getFullYear()
    if (footerCopyRight?.current !== null) {
      footerCopyRight.current.innerHTML = `© SFC Energy AG ${currentYear}`
    }
  })
  const { loginWithRedirect } = useAuth0()
  const setSessionAndRedirect = async () => {
    // set session
    const session = localStorage.getItem('session')
    if (session !== 'auth-set') localStorage.setItem('session', 'auth-set')
    // redirect to login page
    await loginWithRedirect({
      appState: { targetUrl: window.location.pathname }
    })
  }

  return (
    <div className="main static-home">
      <header className="header-nav">
        <a href="/">
          <img className="logo-image show" height="55" width="115" src="/imgs/logo.svg" alt="Home" />
        </a>
        <a href="https://www.sfc.com/en/contact/" target="_blank" className="home-button--medium" rel="noreferrer">
          <img src="/imgs/homepage/phone-alt.svg" alt="Phone" />
          <span>Contact and Support</span>{' '}
        </a>
      </header>
      <div className="home-hero">
        <div className="home-hero-container">
          <div className="green-line"></div>
          <h1>
            <span className="efoy">EFOY </span>
            <span className="title">CLOUD</span>
          </h1>
          <h2>
            Remote monitoring
            <br />
            for EFOY Fuel Cells
          </h2>
          <div className="home-button-container">
            <a className="home-button" onClick={setSessionAndRedirect}>
              <span>Login</span>
              <img src="/imgs/homepage/angle-right.svg" alt="&gt;" />
            </a>
            <a className="home-button-outline" onClick={setSessionAndRedirect}>
              <span>Register Now</span>
              <img src="/imgs/homepage/angle-right-primary.svg" alt="&gt;" />
              <div className="triangle"></div>
            </a>
          </div>
        </div>
        <img src="/imgs/homepage/hero-image.png" alt="hero" className="hero-image" />
        {/* Other content goes here */}
      </div>
      <div className="home-info-panel">
        <div className="panel-content">
          <div className="panel-content-block">
            <div className="green-line--small"></div>
            <h2>EFOY Cloud</h2>
            <p>
              EFOY Fuel Cells are often used in remote locations and require the option of secure remote access. EFOY Cloud gives you access to your methanol fuel cells from anywhere so you can
              monitor continuous operation via laptop, tablet or smartphone. All you need for this is an Internet connection to your fuel cells.
              <br />
              <br />
              Gain insight into the current operational status of individual fuel cells, track historical data, and monitor the fill levels of EFOY fuel cartridges with ease.
            </p>
            <a href="https://www.efoy-pro.com/en/service/efoy-cloud/" rel="noreferrer" target="_blank" className="home-button">
              <span>Learn more</span>
              <img src="/imgs/homepage/angle-right.svg" alt="&gt;" />
            </a>
          </div>
          <div className="panel-content-block">
            <div className="green-line--small"></div>
            <h2>EFOY Cloud licenses</h2>
            <p>
              The functions of the EFOY Cloud license depend on the license level selected for the respective EFOY. The license level is linked to the device.
              <br />
              <br />
              When you purchase a license for your EFOY, you will receive a code which, when entered, gives you access to the corresponding license.
            </p>
            <a href="https://www.efoy-pro.com/en/service/cloud-license/" rel="noreferrer" target="_blank" className="home-button">
              <span>Learn more</span>
              <img src="/imgs/homepage/angle-right.svg" alt="&gt;" />
            </a>
          </div>
        </div>
      </div>
      <div className="up-to-date">
        <div className="panel-content">
          <div className="green-line--big"></div>
          <div>
            <h2 style={{ marginBottom: 12 }}>Stay up to date!</h2>
            <p>With the SFC newsletter you are always up to date. Register now for free and find out what`s new before anyone else!</p>
          </div>
          <div>
            <a href="https://www.sfc.com/newsletter/" rel="noreferrer" target="_blank" className="home-button">
              <span>Subscribe</span>
              <img src="/imgs/homepage/angle-right.svg" alt="&gt;" />
            </a>
          </div>
        </div>
      </div>
      <footer className="text-muted">
        <div className="footer-grey-panel"></div>
        <div className="footer-content">
          <div>
            <a target="_blank" href="https://www.sfc.com" rel="noreferrer">
              <img className="footer-logo" src="/imgs/homepage/sfc-logo.svg" />
            </a>
            <div className="social-networks">
              <a target="_blank" href="https://www.facebook.com/EFOYde/" rel="noreferrer">
                <img src="/imgs/homepage/facebook.png" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/sfc-energy-ag" rel="noreferrer">
                <img src="/imgs/homepage/linkedin.png" />
              </a>
              <a target="_blank" href="https://www.xing.com/pages/sfcenergyag" rel="noreferrer">
                <img src="/imgs/homepage/xing.png" />
              </a>
            </div>
          </div>
          <div className="static-links">
            <a target="_blank" href="https://www.efoy-cloud.com/imprint" rel="noreferrer">
              {' '}
              Imprint{' '}
            </a>
            <a target="_blank" href="/gdpr">
              {' '}
              Data Privacy{' '}
            </a>
          </div>
        </div>
        <div className="footer-copyright" ref={footerCopyRight}>
          © SFC Energy AG <span id="current-year"></span>
        </div>
      </footer>
    </div>
  )
}

export default StaticHome
