// @flow

import { useState } from 'react'
import styled from 'styled-components'

import { Text, PermissionCheck, Card, Input, Row, Col, Gap, Button } from 'components/ReUsable'
import { StyledSubTitle } from 'components/ReUsable/HeaderBar'
import { DevicePermissions } from './hooks/useAlerts'
import { DEVICE_DETAILS_PERMISSIONS_LIST, IDENTITY_NOT_EXISTS } from 'appConstants'
import { useFormik } from 'formik'
import { performIdentityCheck } from 'apis/groups'
import { createAlertConfigurationRequest } from 'apis/alertsv2'
import { emailRegex } from 'utils/helpers'
import Label from 'components/ReUsable/Label'

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

export const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  position: relative;
  border: 1px solid var(--color-border-light);
  border-radius: 0.4rem;

  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`

const ErrorSpan = styled.span`
  font-size: 12px;
  color: var(--color-alert-error);
  font-family: open-sans-regular;
  letter-spacing: 0.14px;
  @media (max-width: 991px) {
    margin-top: 0 !important;
  }

  @media (min-width: 991.98px) {
    position: absolute;
    bottom: -18px;
    left: 0;
  }
`

const StyledNewForm = styled.div`
  min-width: 100%;
  padding-bottom: 2rem;

  @media (min-width: 991.98px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const StyledInput = styled(Input)`
  min-width: 16rem;
  @media (min-width: 991.98px) {
    min-width: 20rem;
  }
`

const StyledButton = styled(Button)`
  min-height: 33px;
  margin-top: 0.75rem;
  @media (min-width: 991.98px) {
    margin-top: 0;
  }
`

type Props = {
  serialNumber: string
  permissions: DevicePermissions
  onAlertConfigAdded: (email: string, arr: { id: string; email: string }[]) => void
}

const NewAlertUser = ({ serialNumber, permissions, onAlertConfigAdded }: Props) => {
  const [success, setSuccess] = useState(false) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | number>('')

  //  Add new Alert configuration
  const addAlertConfiguration = async () => {
    try {
      setSuccess(false)
      setError('')
      const newConfiguration = await createAlertConfigurationRequest(serialNumber, { email: formik.values.email })
      if (newConfiguration.status >= 400) {
        throw new Error(newConfiguration.title || 'An error occured. Please try again later.')
      } else {
        setSuccess(true)
        return newConfiguration
      }
    } catch (e: any) {
      setError(e.message)
      return false
    }
  }

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async (values, { resetForm }) => {
      const email = values.email
      const identityExists = await performIdentityCheck(values.email)
      if (!identityExists) {
        formik.setErrors({ email: IDENTITY_NOT_EXISTS })
        return
      }
      const addAlertResponse = await addAlertConfiguration()
      if (addAlertResponse) {
        resetForm()
        onAlertConfigAdded(email, addAlertResponse)
      }
    },
    validate: async (values) => {
      const errors: { email?: string } = {}
      if (!values.email) {
        errors.email = 'Required!'
      }
      if (!emailRegex.test(values.email)) {
        errors.email = 'Invalid Email!'
      }
      return errors
    },
    validateOnChange: true,
    validateOnBlur: true
  })

  return (
    <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE}>
      <form id="add-new-alert-user-form" onSubmit={formik.handleSubmit}>
        <Row>
          <Col base="60%">
            <StyledSubTitle>New alert config</StyledSubTitle>
          </Col>
        </Row>
        <Row>
          <StyledNewForm>
            <Label htmlFor="name">Email</Label>
            <Gap width="20px" />
            <div style={{ position: 'relative' }}>
              <StyledInput
                name="email"
                type="text"
                placeholder="User Email"
                className={`form-control ${formik.errors.email ? 'input-errored' : ''}`}
                onChange={formik.handleChange}
                value={formik.values.email}
                autoFocus={true}
                data-cy="input-new-email"
              />
              <ErrorSpan className="error">{formik.errors.email || error}</ErrorSpan>
            </div>
            <Gap width="20px" />
            <StyledButton type="submit" disabled={(formik.errors.email && formik.errors.email.length > 0) || formik.values.email.length === 0} data-cy="new-email-submit">
              Add email address
            </StyledButton>
          </StyledNewForm>
        </Row>
      </form>
    </PermissionCheck>
  )
}

export default NewAlertUser
