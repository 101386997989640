// @flow

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'components/ReUsable'

import { createDeviceExport, checkDeviceExport, getDeviceExport } from 'actions'
import AlertConfigMessages from 'components/ReUsable/Alert/alertConfigMessages'

const CenterContentDiv = styled.div`
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.p`
  color: var(--color-text-muted);
  font-family: open-sans-regular;
  font-size: 14px;
  font-weight: normal;
  padding: 20px 0 20px 0;
`

type Props = {
  pageDialog: {
    requestErrorMessage: string
  }
  report?: {
    exportId: number
    finished: boolean
  }
}

const selectDeviceState = (state) => ({
  serialNumber: state.devices.current.serialNumber,
  report: state.devices.report
})

const DeviceExportDownload = (props: Props) => {
  const dispatch = useDispatch()
  const { serialNumber, report } = useSelector(selectDeviceState)
  const { pageDialog } = props

  const addDeviceExport = (obj) => dispatch(createDeviceExport(obj))
  const pingDeviceExport = (obj) => dispatch(checkDeviceExport(obj))
  const FetchDeviceExport = (obj) => dispatch(getDeviceExport(obj))

  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined)

  useEffect(() => {
    addDeviceExport({ serialNumber })
  }, [])

  useEffect(() => {
    if (!report.finished && !intervalId && report.exportId) {
      const id = setInterval(() => {
        report.exportId && pingDeviceExport({ id: report.exportId })
      }, 5000)
      setIntervalId(id)
    } else if (report.finished) clearInterval(intervalId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.exportId, report.finished])

  if (!report.finished) {
    return (
      <CenterContentDiv>
        <AlertConfigMessages pageDialog={pageDialog} />
        <SectionTitle>Generating Device Report</SectionTitle>
        <Spinner animation="border" variant="secondary" />
      </CenterContentDiv>
    )
  }

  return (
    <CenterContentDiv>
      <SectionTitle>Device report is ready</SectionTitle>
      <Button onClick={() => FetchDeviceExport({ id: report.exportId })}>Device Report</Button>
    </CenterContentDiv>
  )
}

export default DeviceExportDownload
