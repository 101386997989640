// @flow

import styled from 'styled-components'

import {
  Row,
  Col,
  CardSectionTitle,
  CardSection,
  DataRow,
  DataRowTitle,
  DataRowValue,
  ShowDataValue,
  CircularGraph,
  UpgradeLicenseButton
} from 'components/ReUsable'

import {
  Cartridge,
  LevelMax,
  Level
} from 'components/Application/Device/FuelCartridges/fuelCartridgesSection'

import { getPropertyTitle } from 'helpers/battery'

import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'

import { useDispatch } from 'react-redux'
import { togglePageDialog } from 'actions'
import { mapTypesToNames } from '../Dialog/BatterySettings/batteryTypeSection'
import { calculateDivisorValue } from '../Dialog/BatterySettings/batteryVoltageSection'
import { AnimatedCardSection } from 'components/ReUsable/Card'

type BatteryData = {
  batteryType?: string;
  voltageBattery?: string;
  stateOfCharge?: string;
  chargingCurrent?: string;
  batteryCapacity?: string;
  switchOnDelay?: string;
  deepDischargeProtection?: string;
  cutOffTime?: number;
  minimumChargeTime?: number;
  maximumChargeTime?: number;
  maxStateOfCharge?: string;
  maxDepthOfDischarge?: string;
  switchOffVoltage?: string;
  chargingCycles?: {
    auto: ChangingCycleValues;
    manual: ChangingCycleValues;
  };
};

type ChangingCycleValues = {
  average: number;
  count: number;
};

type Props = {
  batteryData: BatteryData;
  confirmResetChargingCycle: () => void;
  isConnected: boolean;
  isExpanded: boolean;
};

const DataRowTitleStyled = styled(DataRowTitle)`
  font-family: digital-serial;
`

const BatteryCardSectionTitle = styled(CardSectionTitle)`
  display: flex;
  justify-content: space-between;
`

const convertChargingTime = (batteryData: BatteryData) => {
  if (!batteryData) return {}

  const autoCycle = batteryData?.chargingCycles?.auto
  const manual = batteryData?.chargingCycles?.manual

  //  Convert seconds value coming from backend into hours.
  const convert = (seconds: any) => {
    return Math.round(Number.parseInt(seconds) / (60 * 60))
  }

  if (autoCycle && manual) {
    return {
      ...batteryData,
      chargingCycles: {
        ...batteryData.chargingCycles,
        auto: {
          ...autoCycle,
          average: convert(autoCycle?.average)
        },
        manual: {
          ...manual,
          average: convert(manual?.average)
        }
      }
    }
  }

  return batteryData
}

export const convertBatteryName = (batteryData: BatteryData) => {
  if (!batteryData || !batteryData.batteryType) {
    return batteryData
  }

  const displayName = mapTypesToNames(batteryData.batteryType)

  return { ...batteryData, batteryType: displayName } as BatteryData
}

const convertSecondsValues = (batteryData: BatteryData) => {
  if (!batteryData) {
    return batteryData
  }

  const convertedBatteryData = {
    ...batteryData,
    cutOffTime: batteryData.cutOffTime
      ? Math.round(batteryData.cutOffTime / calculateDivisorValue('cutOffTime'))
      : undefined,
    minimumChargeTime: batteryData.minimumChargeTime
      ? Math.round(batteryData.minimumChargeTime /
        calculateDivisorValue('minimumChargeTime'))
      : undefined,
    maximumChargeTime: batteryData.maximumChargeTime
      ? Math.round(batteryData.maximumChargeTime /
        calculateDivisorValue('maximumChargeTime'))
      : undefined
  } as BatteryData

  return convertedBatteryData as BatteryData
}

function SocOrVoltageGraph (props: { data: BatteryData }) {
  const { stateOfCharge } = props.data
  const hasSocValue = stateOfCharge && Number(stateOfCharge) >= 0

  if (hasSocValue) {
    return (
      <CircularGraph
        data={props.data}
        property={'stateOfCharge'}
        decimals={0}
        suffix={'%'}
        title="SOC"
      ></CircularGraph>
    )
  }

  return (
    <CircularGraph
      data={props.data}
      property={'voltageBattery'}
      suffix={'V'}
      title="Voltage"
    ></CircularGraph>
  )
}

export const batteryIsTypeEfoy = (batteryData: BatteryData) =>
  batteryData.batteryType === 'efoy_battery'

export default function BatterySection (props: Props) {
  const { confirmResetChargingCycle, isConnected, isExpanded } = props
  const dispatch = useDispatch()
  let mode = ''
  // Check if the user prefers a dark color scheme
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    mode = '-dark'
  }
  const isEfoyBattery = batteryIsTypeEfoy(props.batteryData)
  let batteryData = convertChargingTime(props.batteryData) as any
  batteryData = convertBatteryName(batteryData)
  batteryData = convertSecondsValues(batteryData)
  return (
    <Col>
      <BatteryCardSectionTitle
        data-cy="battery-card-section"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <span>Battery</span>
        <UpgradeLicenseButton
          isConnected={isConnected}
          handleClick={() =>
            dispatch(togglePageDialog({ dialogId: 'edit-battery-dialog' }))
          }
          permissionNames={[DEVICE_DETAILS_PERMISSIONS_LIST.BATTERY_WRITE]}
        />
      </BatteryCardSectionTitle>
      <Row>
        <Col>
          <CardSection>
            <DataRowTitle>Battery type</DataRowTitle>
            <DataRowValue data-cy="battery-type-value">
              <ShowDataValue data={batteryData} property={'batteryType'} />
            </DataRowValue>
            <DataRowValue data-cy="battery-type-img-section">
              {batteryData === undefined ||
              Object.entries(batteryData).length === 0 ||
              (batteryData && batteryData?.batteryType === 'No Battery') ? (
                <img
                  height="60"
                  width="65"
                  alt="Battery type"
                  src={`/imgs/battery${mode}.svg`}
                />
              ) : (
                <Cartridge>
                  <LevelMax>
                    <Level {...{ height: 100 }}></Level>
                  </LevelMax>
                  <img alt="Full battery" src={`/imgs/battery${mode}.svg`}/>
                </Cartridge>
              )}
            </DataRowValue>
          </CardSection>
        </Col>
        <Col>
          <CardSection data-cy="battery-charging-cycles-section">
            <DataRowTitle
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span>Charging cycles</span>
              <UpgradeLicenseButton
                isConnected={isConnected}
                defaultButton="redo"
                handleClick={confirmResetChargingCycle}
                permissionNames={[DEVICE_DETAILS_PERMISSIONS_LIST.RESET_CYCLES]}
              />
            </DataRowTitle>
            <DataRowTitleStyled>Automatic</DataRowTitleStyled>
            <DataRowValue data-cy="averageChargingTimeAutomatic-value">
              <ShowDataValue
                data={batteryData?.chargingCycles?.auto}
                showZero={true}
                property={'count'}
              />{' '}
              / ø&#8239;
              <ShowDataValue
                data={batteryData?.chargingCycles?.auto}
                showZero={true}
                property={'average'}
              />
              h
            </DataRowValue>
            <DataRowTitleStyled>Manual</DataRowTitleStyled>
            <DataRowValue data-cy="averageChargingTimeManual-value">
              <ShowDataValue
                data={batteryData?.chargingCycles?.manual}
                showZero={true}
                property={'count'}
              />{' '}
              / ø&#8239;
              <ShowDataValue
                data={batteryData?.chargingCycles?.manual}
                showZero={true}
                property={'average'}
              />
              h
            </DataRowValue>
          </CardSection>
        </Col>
      </Row>
      <CardSection>
        <Row>
          <Col alignItems="center" alignAlways={true} justifyContent="center">
            <SocOrVoltageGraph data={batteryData} />
          </Col>
          <Col alignItems="center" alignAlways={true} justifyContent="center">
            <CircularGraph
              data={batteryData}
              property={'chargingCurrent'}
              suffix={'A'}
              title="Charging current"
            ></CircularGraph>
          </Col>
        </Row>
      </CardSection>
      <AnimatedCardSection {...{ 'data-cy': 'battery-data', height: '100%', expanded: isExpanded }}>
        <DataRow>
          <DataRowTitle>{getPropertyTitle('capacity')}</DataRowTitle>
          <DataRowValue>
            <ShowDataValue
              data={batteryData}
              property={'capacity'}
              suffix={'Ah'}
            />
          </DataRowValue>
        </DataRow>
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('beginOfChargeVoltage')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'beginOfChargeVoltage'}
                suffix={'V'}
                decimals={1}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('endOfChargeVoltage')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'endOfChargeVoltage'}
                suffix={'V'}
                decimals={1}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>{getPropertyTitle('cutOffCurrent')}</DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'cutOffCurrent'}
                suffix={'A'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>{getPropertyTitle('cutOffTime')}</DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'cutOffTime'}
                suffix={'min'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>{getPropertyTitle('maximumChargeTime')}</DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'maximumChargeTime'}
                suffix={'h'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>{getPropertyTitle('minimumChargeTime')}</DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'minimumChargeTime'}
                suffix={'min'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>{getPropertyTitle('switchOnDelay')}</DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'switchOnDelay'}
                suffix={'s'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {!isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('deepDischargeProtectionVoltage')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                property={'deepDischargeProtectionVoltage'}
                suffix={'V'}
                decimals={1}
              />
            </DataRowValue>
          </DataRow>
        )}
        {isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('maximumStateOfCharge')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                showZero={true}
                property={'maximumStateOfCharge'}
                suffix={'%'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('maximumDepthOfDischarge')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                showZero={true}
                property={'maximumDepthOfDischarge'}
                suffix={'%'}
              />
            </DataRowValue>
          </DataRow>
        )}
        {isEfoyBattery && (
          <DataRow>
            <DataRowTitle>
              {getPropertyTitle('deepDischargeProtectionSOC')}
            </DataRowTitle>
            <DataRowValue>
              <ShowDataValue
                data={batteryData}
                showZero={true}
                property={'deepDischargeProtectionSOC'}
                suffix={'%'}
              />
            </DataRowValue>
          </DataRow>
        )}
      </AnimatedCardSection>
    </Col>
  )
}
