// @flow

import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'

import {
  Text,
  PermissionCheck,
  Card,
  Button,
  NumberInput
} from 'components/ReUsable'
import { DevicePermissions } from './hooks/useAlerts'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { AlertConfiguration, alertDefaultValues, LowerConfig, AlertsObjectConfig } from './alertTypes'
import {
  Form
} from 'react-bootstrap'
import { unitMapping } from '../Device/Multisense/micros'
import Label from 'components/ReUsable/Label'
import { setEnabledKeys } from './helper'
import { NEW_API_ACTIVE } from 'featureToggles'

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

export const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  position: relative;
  border: 1px solid var(--color-border-light);
  border-radius: 0.4rem;

  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  margin-bottom: 1rem;
  margin-top: .5rem;
  margin-left: .5rem;
  flex-wrap: wrap;

  @media (min-width: 991.98px) {
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    gap: .75rem;
    flex-wrap: nowrap;
  }
`

export const StyledMultiSenseRow = styled(StyledRow)`
  margin-left: 2rem;
`

export const StyledCol = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-text);
  font-family: open-sans-bold;
  font-weight: bold;
  font-size: 16px;
  gap: .5rem;
  @media (min-width: 991.98px) {
    line-height: 33px;
    min-height: 33px;
  }
`

export const StyledMultiSenseCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  font-size: 20px;
  font-weight: normal;
  flex-wrap: wrap;

  & > input, & > input:focus, & > input:focus-visible {
    font-family: digital-serial;
    width: 64px;
    border: 1px solid var(--color-disabled);
    border-radius: 4px;
    outline: none;
    font-family: digital-serial;
    font-size: 14px;
    color: var(--color-text);

    @media (prefers-color-scheme: dark) {
      background: transparent;
      border: 1px solid var(--color-text);
    }

    @media (min-width: 991.98px) {
      font-size: 20px;
      width: 80px;
    }
  }

  @media (prefers-color-scheme: dark) {
    & > input:focus {
      box-shadow: 0 0 0 0.25rem rgba(150, 150, 151, 0.25);
    }

    & > input, & > input:focus, & > input:focus-visible {
      color: var(--color-text);
    }
  }

  & > span {
    font-weight: normal;
    font-size: 14px;
    @media (min-width: 991.98px) {
      font-size: 18px;
    }
  }
`

export const StyledHighLowLabel = styled.span`
  display: inline-block;
  width: 24px;

  @media (min-width: 991.98px) {
    width: 36px;
  }
`

export const StyledUnitLabel = styled.span`
  display: inline-block;
  width: 20px;
  font-family: digital-serial;
  font-size: 16px !important;
  @media (min-width: 991.98px) {
    font-size: 24px !important;
    width: 44px;
  }
`

export const StyledAboveBelow = styled.span`
  display: inline-block;
  width: 72px;

  @media (min-width: 991.98px) {
    width: 86px;
  }
`

export const StyledMultiSenseBlockContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 991.98px) {
    margin-left: 2rem;
  }

  & > div {
    margin-bottom: 1rem;
    margin-top: 0;

    @media (min-width: 991.98px) {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }
`

export const StyledLabelCol = styled(StyledCol)`
  min-width: 70%;
  @media (min-width: 991.98px) {
    min-width: 30%;
  }
  @media (max-width: 991px) {
    & > label {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

export const StyledValueCol = styled(StyledCol)`
  color: var(--color-text);
  font-size: 16px;
  font-family: digital-serial;
  line-height: 22px;

  @media (min-width: 991.98px) {
    line-height: 30px;
    font-size: 26px;
  }

  & > input, & > input:focus, & > input:focus-visible {
    font-family: digital-serial;
    width: 64px;
    border: 1px solid var(--color-disabled);
    border-radius: 4px;
    outline: none;
    font-family: digital-serial;
    font-size: 14px;
    color: var(--color-text);

    @media (prefers-color-scheme: dark) {
      background: transparent;
      border: 1px solid var(--color-text);
    }

    @media (min-width: 991.98px) {
      font-size: 20px;
      width: 80px;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    & > input:focus {
      box-shadow: 0 0 0 0.25rem rgba(150, 150, 151, 0.25);
    }
  }

  & > span {
    font-weight: normal;
  }

  img {
    width: 20px;
    height: auto;
    position: relative;
    bottom: 2px;
  }
`

export const StyledCheckbox = styled.input`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0; 
  @media (prefers-color-scheme: dark) {
    background-color: var(--color-text);
  }
`

const ErrorMessage = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: var(--color-alert-error);
  font-family: open-sans-regular;
  letter-spacing: 0.14px;
  
  @media (min-width: 991.98px) {
    margin-left: 1rem;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

type Props = {
  permissions: DevicePermissions;
  selectedUserId: string;
  selectedConfiguration: AlertConfiguration;
  userType: string;
  handleSubmit: (values: AlertsObjectConfig) => void
  setFormIsDirty: (value: boolean) => void
  formIsDirty: boolean
}

const AlertConfigForm = ({ permissions, selectedConfiguration, selectedUserId, userType, handleSubmit, setFormIsDirty, formIsDirty }: Props) => {
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const isInitialRender = useRef(true)
  const isInitialRenderMultiSense = useRef(true)
  const [checkAllMultiSense, setCheckAllMultiSense] = useState<boolean>(false)
  const selectedConfigurationWithDefaults = {
    ...selectedConfiguration.alerts,
    batterySOC: {
                  ...selectedConfiguration.alerts?.batterySOC,
                  lower: {
                  ...selectedConfiguration.alerts?.batterySOC.lower,
                            threshold: selectedConfiguration.alerts?.batterySOC.lower.threshold
                            ? selectedConfiguration.alerts?.batterySOC.lower.threshold
                            : alertDefaultValues.batterySOC.threshold
                          }
                },
    batteryVoltage: {
                      ...selectedConfiguration.alerts?.batteryVoltage,
                      lower: {
                      ...selectedConfiguration.alerts?.batteryVoltage.lower,
                                threshold: selectedConfiguration.alerts?.batteryVoltage.lower.threshold
                                ? selectedConfiguration.alerts?.batteryVoltage.lower.threshold
                                : alertDefaultValues.batteryVoltage.threshold
                              }
                    },
    fuelLevel: {
                ...selectedConfiguration.alerts?.fuelLevel,
                lower: {
                ...selectedConfiguration.alerts?.fuelLevel.lower,
                          threshold: selectedConfiguration.alerts?.fuelLevel.lower.threshold
                          ? selectedConfiguration.alerts?.fuelLevel.lower.threshold
                          : alertDefaultValues.fuelLevel.threshold
                        }
              }
  }
  const formik = useFormik(
    {
      initialValues: { ...selectedConfigurationWithDefaults },
      onSubmit: (values) => {
        setFormIsDirty(false)
        handleSubmit(values as AlertsObjectConfig)
      },
      validate: (values) => {
        const errors = {}
        Object.entries(values).filter(([key]) => key !== 'multiSense').map(([key, val]) => {
          const lowerVal = (val as LowerConfig).lower
          if (lowerVal?.enabled && (!lowerVal?.threshold)) {
            errors[key] = 'Required'
          }
          if (lowerVal?.threshold && alertDefaultValues[key].unit === '%' && (lowerVal?.threshold < 0 || lowerVal?.threshold > 100)) {
            errors[key] = 'Please provide a value between 0 and 100.'
          }
        })

        const multiSenseValues = values.multiSense

        if (multiSenseValues && NEW_API_ACTIVE) {
          Object.entries(multiSenseValues).filter(([key]) => key.indexOf('io') === -1).map(([key, value]) => {
            const lowerVal = value?.lower
            if (lowerVal?.enabled) {
              if (!lowerVal?.threshold || !lowerVal?.clear) {
                errors[`${key}Lower`] = 'Required'
              }
            }
            if ((lowerVal?.threshold && lowerVal?.clear) && +lowerVal?.threshold > +lowerVal?.clear) {
              errors[`${key}Lower`] = 'Low needs to be less then clear above.'
            }
            const upperVal = value?.upper
            if (upperVal?.enabled) {
              if (!upperVal?.threshold || !upperVal?.clear) {
                errors[`${key}Upper`] = 'Required'
              }
            }

            if ((upperVal?.threshold && upperVal?.clear) && +upperVal?.clear > +upperVal?.threshold) {
              errors[`${key}Upper`] = 'High needs to be more then clear below.'
            }
          })
        }

        return errors
      },
      enableReinitialize: userType === 'ADMIN'
    }
  )

  useEffect(() => {
    formik.resetForm({ values: { ...selectedConfigurationWithDefaults as any } })
    if (selectedConfiguration.alerts) {
      setCheckAll(false)
    }
    if (selectedConfiguration.alerts && selectedConfiguration.alerts.multiSense) {
      setCheckAllMultiSense(false)
    }
  }, [selectedUserId])

  useEffect(() => {
    if (isInitialRenderMultiSense.current) {
      isInitialRenderMultiSense.current = false
      return
    }
    if (!selectedConfiguration.alerts || !selectedConfiguration.alerts.multiSense) {
      return
    }
    const newObj = setEnabledKeys({ ...formik.values.multiSense }, checkAllMultiSense)
    formik.setValues({ ...formik.values, multiSense: { ...newObj } })
  }, [checkAllMultiSense])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    if (!selectedConfiguration.alerts) {
      return
    }
    const newObj = setEnabledKeys({ ...formik.values }, checkAll)
    formik.setValues(newObj).then(() => {
      if (selectedConfiguration?.alerts?.multiSense) {
        setCheckAllMultiSense(checkAll)
      }
    })
  }, [checkAll])

  useEffect(() => {
    if (formik.dirty) {
      setFormIsDirty(true)
    }
  }, [formik.dirty, formik.values])

  if (!selectedConfiguration.alerts) {
    return <></>
  }

  return (
    <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE}
      >
        <>
          <br/>
          <StyledRow>
            <StyledCol>
              <StyledCheckbox id="allConfigCheckBoxes"
                className="form-check-input"
                type='checkbox'
                checked={checkAll}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCheckAll(e.target.checked)
                  setFormIsDirty(true)
                }}
            />
            </StyledCol>
            <StyledLabelCol>
              <Label for="allConfigCheckBoxes">
               All
              </Label>
            </StyledLabelCol>
          </StyledRow>
          <Form id='update-device-alert-config-form' onSubmit={ formik.handleSubmit }>
            {Object.entries(selectedConfiguration.alerts).filter(([key]) => key !== 'multiSense').map(([key]) => (
              <StyledRow key={key}>
                <StyledCol>
                  <StyledCheckbox type='checkbox'
                        id={`checkBox-${key}`}
                        className="form-check-input"
                        checked={!!formik.values[key]?.enabled || !!formik.values[key]?.lower?.enabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let fieldName = ''
                            if ('enabled' in formik.values[key] && typeof formik.values[key]?.enabled === 'boolean') {
                              fieldName = `${key}.enabled`
                            } else {
                              fieldName = `${key}.lower.enabled`
                            }
                            formik.setFieldValue(fieldName, e.target.checked)
                            setFormIsDirty(true)
                          }
                        }
                  />
                </StyledCol>
                <StyledLabelCol>
                  <Label for={`checkBox-${key}`}>{alertDefaultValues[key]?.title}</Label>
                </StyledLabelCol>
                { formik.values[key]?.lower
                ? <><StyledValueCol>
                    <NumberInput
                      id={`${key}.lower.threshold`}
                      name={`${key}.lower.threshold`}
                      value={formik.values[key]?.lower?.threshold}
                      onChange={(v: string) => formik.setFieldValue(`${key}.lower.threshold`, v)}
                      onBlur={formik.handleBlur}
                    />
                    <span>{alertDefaultValues[key].unit}</span>
                </StyledValueCol>
                <StyledCol>
                  {formik.errors[key] ? <ErrorMessage data-cy={`error.${key}.lower`}>{formik.errors[key]}</ErrorMessage> : null}
                </StyledCol>
                </>
                : <></>
                }
              </StyledRow>
            ))}
            {selectedConfiguration.alerts.multiSense && NEW_API_ACTIVE
            ? <StyledRow>
              <StyledCol>
                <StyledCheckbox
                  id="allMultiSenseCheckboxes"
                  className="form-check-input"
                  type='checkbox' checked={checkAllMultiSense || false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCheckAllMultiSense(e.target.checked)
                    setFormIsDirty(true)
                  }}
                />
              </StyledCol>
              <StyledLabelCol>
                <Label for="allMultiSenseCheckboxes">MultiSense</Label>
              </StyledLabelCol>
            </StyledRow>
            : null
            }
            {selectedConfiguration.alerts.multiSense && NEW_API_ACTIVE && Object.entries(selectedConfiguration.alerts.multiSense).filter(([key, value]) => value !== null).map(([key, value]) => { // eslint-disable-line @typescript-eslint/no-unused-vars
              if (!value) {
                return
              }
              const hasLowerUpperBounds = value.upper && value.lower

              if (hasLowerUpperBounds) {
                return <StyledMultiSenseBlockContainer key={key}>
                  <StyledRow>
                    <StyledCol>
                      <StyledCheckbox type='checkbox'
                        id={`multiSense.${key}.lower.enabled`}
                        name={`multiSense.${key}.lower.enabled`}
                        className="form-check-input"
                        checked={formik.values.multiSense &&
                          formik.values.multiSense[key] &&
                          formik.values.multiSense[key].lower &&
                          'enabled' in formik.values.multiSense[key].lower &&
                          typeof formik.values.multiSense[key]?.lower?.enabled === 'boolean' &&
                          formik.values.multiSense[key].lower.enabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e)
                          setFormIsDirty(true)
                        }}
                      />
                    </StyledCol>
                    <StyledLabelCol>
                      <Label for={`multiSense.${key}.lower.enabled`}>{`${key.toUpperCase()} - ${value?.function} - lower bound`}</Label>
                    </StyledLabelCol>
                    { formik.values.multiSense && formik.values.multiSense[key]
                    ? <StyledMultiSenseCol>
                          <StyledHighLowLabel>low</StyledHighLowLabel>
                          <NumberInput
                            id={`multiSense.${key}.lower.threshold`}
                            name={`multiSense.${key}.lower.threshold`}
                            value={formik.values.multiSense[key].lower?.threshold || ''}
                            onChange={(v: string) => formik.setFieldValue(`multiSense.${key}.lower.threshold`, v)}
                            onBlur={formik.handleBlur}
                          />
                          <StyledUnitLabel>{unitMapping[formik.values.multiSense[key].unit]}</StyledUnitLabel>
                          <StyledAboveBelow>clear above</StyledAboveBelow>
                          <NumberInput
                            value={formik.values.multiSense[key].lower?.clear || ''}
                            id={`multiSense.${key}.lower.clear`}
                            name={`multiSense.${key}.lower.clear`}
                            onChange={(v: string) => formik.setFieldValue(`multiSense.${key}.lower.clear`, v)}
                            onBlur={formik.handleBlur}
                          />
                          <StyledUnitLabel>{unitMapping[formik.values.multiSense[key].unit]}</StyledUnitLabel>
                          {formik.errors[`${key}Lower`] ? <ErrorMessage data-cy={`error-multiSense.${key}.lower`}>{formik.errors[`${key}Lower`]}</ErrorMessage> : null}
                      </StyledMultiSenseCol>
                  : null}
                  </StyledRow>

                  <StyledRow>
                    <StyledCol>
                      <StyledCheckbox type='checkbox'
                        id={`multiSense.${key}.upper.enabled`}
                        className="form-check-input"
                        checked={formik.values.multiSense &&
                          formik.values.multiSense[key] &&
                          formik.values.multiSense[key].upper &&
                          'enabled' in formik.values.multiSense[key].upper &&
                          typeof formik.values.multiSense[key]?.upper?.enabled === 'boolean' &&
                          formik.values.multiSense[key].upper.enabled}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(e.target.id, e.target.checked)
                            setFormIsDirty(true)
                          }}
                      />
                    </StyledCol>
                    <StyledLabelCol>
                      <Label for={`multiSense.${key}.upper.enabled`}>{`${key.toUpperCase()} - ${value?.function} - upper bound`}</Label>
                    </StyledLabelCol>
                    { formik.values.multiSense && formik.values.multiSense[key]
                    ? <StyledMultiSenseCol>
                          <StyledHighLowLabel>high</StyledHighLowLabel>
                          <NumberInput
                            value={formik.values.multiSense[key].upper?.threshold || ''}
                            id={`multiSense.${key}.upper.threshold`}
                            name={`multiSense.${key}.upper.threshold`}
                            onChange={(v: string) => formik.setFieldValue(`multiSense.${key}.upper.threshold`, v)}
                            onBlur={formik.handleBlur}
                          />
                          <StyledUnitLabel>{unitMapping[formik.values.multiSense[key].unit]}</StyledUnitLabel>
                          <StyledAboveBelow>clear below</StyledAboveBelow>
                          <NumberInput
                            value={formik.values.multiSense[key].upper?.clear || ''}
                            id={`multiSense.${key}.upper.clear`}
                            name={`multiSense.${key}.upper.clear`}
                            onChange={(v: string) => formik.setFieldValue(`multiSense.${key}.upper.clear`, v)}
                            onBlur={formik.handleBlur}
                          />
                          <StyledUnitLabel>{unitMapping[formik.values.multiSense[key].unit]}</StyledUnitLabel>
                          {formik.errors[`${key}Upper`] ? <ErrorMessage data-cy={`error-multiSense.${key}.upper`}>{formik.errors[`${key}Upper`]}</ErrorMessage> : null}
                      </StyledMultiSenseCol>
                  : null}
                  </StyledRow>
                </StyledMultiSenseBlockContainer>
              } else {
                return (
                  <StyledMultiSenseBlockContainer key={key}>
                    <StyledRow>
                      <StyledCol>
                        <StyledCheckbox type='checkbox'
                          id={`multiSense.${key}.enabled`}
                          name={`multiSense.${key}.enabled`}
                          className="form-check-input"
                          checked={formik.values.multiSense &&
                            formik.values.multiSense[key] &&
                            'enabled' in formik.values.multiSense[key] &&
                            typeof formik.values.multiSense[key]?.enabled === 'boolean' &&
                            formik.values.multiSense[key]?.enabled}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              formik.handleChange(e)
                              setFormIsDirty(true)
                            }}
                        />
                      </StyledCol>
                      <StyledLabelCol>
                        <Label for={`multiSense.${key}.enabled`}>{`${key.toUpperCase()} - ${value?.function} - state changed`}</Label>
                      </StyledLabelCol>
                    </StyledRow>
                  </StyledMultiSenseBlockContainer>
                )
              }
            })}
            <StyledButtonContainer>
              <Button disabled={Object.keys(formik.errors).length > 0 || !formIsDirty} type="submit" data-cy="submit-button">
                Save changes
              </Button>
            </StyledButtonContainer>
          </Form>
        </>
    </PermissionCheck>
  )
}

export default AlertConfigForm
