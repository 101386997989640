import { format } from 'date-fns'
import Highcharts from 'highcharts'

// Extend the Point interface to include the highlight function
declare module 'highcharts' {
  interface Point {
    highlight(event?: Event): void
  }
  interface Chart {
    syncExtremes?: any // Add the property here with appropriate type if known
  }
}

Highcharts.Pointer.prototype.reset = function () {
  return undefined
}
/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair
 */
Highcharts.Point.prototype.highlight = function () {
  this.onMouseOver() // Show the hover marker
}

// add hover function on formatter and xaxis plotlines
Highcharts.Axis.prototype.drawCrosshair = function (event: any, opt: any) {
  const allExistingGraphs = ['History', 'Multisense', 'IOs']
  const thisChart = opt.series.chart
  if (!thisChart) return
  const Index = allExistingGraphs.findIndex((graph) => graph === thisChart.container.querySelector('.highcharts-title')?.innerText)
  if (Index === -1) return
  // Unused logic up ahead for a future use
  // get all events from the plotlines
  const EventData = this.chart.xAxis[0]?.options.plotLines?.map((plotLine) => plotLine.value) as number[]
  const targetElement = event.target || event.srcElement
  if (!targetElement) return
  if (event.type === 'mousemove' && targetElement.className === 'highcharts-tooltip-event') {
    const hoverEvents = document.querySelectorAll('.highcharts-plot-line-label') as NodeListOf<HTMLElement>
    const exactEvent = Array.from(hoverEvents).find((hoverEvent) => hoverEvent.innerText === event.target.innerHTML) as HTMLElement
    const exactEventIndex = Array.from(hoverEvents).findIndex((hoverEvent) => hoverEvent.innerText === event.target.innerHTML)
    const hoverEventColor = window.getComputedStyle(exactEvent).backgroundColor
    const tooltips = document.querySelectorAll('.event-warning') as NodeListOf<HTMLElement>

    tooltips.forEach((tooltip) => {
      // get first element of tooltip and second element as well
      const tooltipKey = tooltip.firstElementChild as HTMLElement
      const tooltipValue = tooltip.lastElementChild as HTMLElement
      // change the text to the event name
      tooltipKey.innerText =
        hoverEventColor === 'rgb(255, 0, 255)'
          ? `FM Port Change - ${event.target.innerText.replace(/^\D+/g, '')}`
          : hoverEventColor === 'rgb(255, 175, 79)'
          ? 'Warning'
          : `Error - ${event.target.innerText.replace(/^\D+/g, '')}`
      // get hovered timestamp
      const hoveredTimestamp = new Date(EventData[exactEventIndex])
      // change the text to the browser date format
      // format should be '18.04.24 13:32 h' as in the example
      tooltipValue.innerText = `${format(new Date(hoveredTimestamp), 'yyyy-MM-dd HH:mm')} h` // 18.04.24 13:32 h
      tooltipKey.style.color = hoverEventColor
      targetElement.addEventListener('mousemove', () => {
        // for future use //console.log('inside the mousemove event', event.clientX, event.clientY)
        if (tooltip !== null) {
          tooltip.classList.remove('hidden')
          tooltipKey.style.paddingLeft = `${0}px`
        }
      })

      targetElement.addEventListener('mouseout', () => {
        // for future use // console.log('Outside the event Area', event.clientX, event.clientY, event.timeStamp)
        if (tooltip !== null) {
          tooltip.classList.add('hidden')
        }
      })
    })
  }
}

// export the enhancements done
export default Highcharts
