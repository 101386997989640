// @flow

import { useAuth0 } from 'utils/react_auth0_spa'
import { integrationTestRunning } from 'utils/helpers'
import styled from 'styled-components'

const StyledLogoutLink = styled.a`
  color: var(--color-lightest) !important;
`

function InfoBar ({ hideLogout }: { hideLogout?: boolean }) {
  const { loading, isAuthenticated, logout } = useAuth0()
  const userHasLocalSession = localStorage.getItem('session') === 'auth-set' || process.env.npm_lifecycle_event === 'test' || integrationTestRunning(process.env.REACT_APP_ENV_MODE)
  if (((!isAuthenticated || loading) && !integrationTestRunning(process.env.REACT_APP_ENV_MODE)) || !userHasLocalSession) {
    return null
  }

  return (
    <div className="info-bar container-fluid">
      {!hideLogout ? (
        <div className="info-nav">
          <div className="col-12 justify-content-end d-flex align-self-center">
            <ul className="list-inline info-list">
              <li className="list-inline-item">
                <StyledLogoutLink className="info-links" href="#logout" onClick={() => logout()}>
                  SIGN OUT
                </StyledLogoutLink>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default InfoBar
