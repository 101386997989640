import { useState, useEffect } from 'react'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import styled from 'styled-components'
import FilterIcon from './filterIcon'
import { Button, ButtonCancel, StatusIndicator } from 'components/ReUsable'
import { getStatusMessage, getStatusGroupMessage } from 'helpers/status_message'
import { useDispatch, useSelector } from 'react-redux'
import { applyDeviceFilter } from 'actions'
import { containsElement, isSubset } from 'utils/helpers'
import { NEW_API_ACTIVE } from 'featureToggles'

const StyledFilterButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  position: relative;
  top: 1px;
`

const StyledPopOverContent = styled.div`
  padding: 10px;
  padding-top: 16px;
  min-width: 360px;
  max-height: 50vh;
  overflow-y: auto;
`

const StyledListContainer = styled.div`
  display: block;  
  margin-bottom: 1rem;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
`

const StyledSaveButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
`

const StyledExpandButton = styled.button<{expanded: boolean}>`
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: -.2rem;

  & > img {
    width: 1.4rem;
    height: auto;
    transform: ${(props: {expanded: boolean}) => props.expanded ? 'rotate(90deg)' : ''};
  }
`

export const StyledInput = styled.input`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0;
  background-color: var(--color-checkbox-default-background);
  &:checked {
    background-color: var(--color-checkbox-background);
    border-color: var(--color-checkbox-background);
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(46, 47, 48, 0.1);
  }    
`

const StyledLabel = styled.label`
  display: flex;
  font-family: open-sans-regular;
  color: var(--color-table-text);
  font-weight: bold;
  font-size: 1rem;
  gap: 4px;
  outline: none !important;
  cursor: pointer;
  &::first-letter {
    text-transform: capitalize;
  }
  &::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }
`

const StyledStatusLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: .5rem;

`

const compoundState = [
  'off',
  'standby',
  'in_operation',
  'shut_down',
  'frost_protection',
  'deep_discharge_protection',
  'transport_lock_procedure',
  'transport_lock',
  'reset',
  'factory_defaults',
  'error',
  'emergency_frost_protection',
  'pending',
  'can_update',
  'warning',
  'start_phase',
  'unknown'
]

const compoundStateGroupMapping = {
  status_ideal: [
    'off',
    'standby',
    'in_operation',
    'shut_down',
    'frost_protection',
    'deep_discharge_protection',
    'transport_lock_procedure',
    'reset',
    'factory_defaults',
    'pending',
    'can_update',
    'start_phase',
    'unknown'
  ],
  attention_required: ['warning'],
  action_required: ['transport_lock', 'error', 'emergency_frost_protection'],
  inactive: compoundState
}

const getSelectedGroupsByStatusSelection = (currentFilterValues: {active: string[], inactive: string[]}) => {
  const selectedGroups:string[] = []

  if (isSubset(compoundStateGroupMapping.inactive, currentFilterValues.inactive)) {
    selectedGroups.push('inactive')
  }

  if (isSubset(compoundStateGroupMapping.action_required, currentFilterValues.active)) {
    selectedGroups.push('action_required')
  }

  if (isSubset(compoundStateGroupMapping.attention_required, currentFilterValues.active)) {
    selectedGroups.push('attention_required')
  }

  if (isSubset(compoundStateGroupMapping.status_ideal, currentFilterValues.active)) {
    selectedGroups.push('status_ideal')
  }

  return selectedGroups
}

const getNotEmptyGroupsByStatusSelection = (currentFilterValues: {active: string[], inactive: string[]}) => {
  const notEmptyGroups:string[] = []

  if (containsElement(currentFilterValues.inactive, compoundStateGroupMapping.inactive)) {
    notEmptyGroups.push('inactive')
  }

  if (containsElement(currentFilterValues.active, compoundStateGroupMapping.action_required)) {
    notEmptyGroups.push('action_required')
  }

  if (containsElement(currentFilterValues.active, compoundStateGroupMapping.attention_required)) {
    notEmptyGroups.push('attention_required')
  }

  if (containsElement(currentFilterValues.active, compoundStateGroupMapping.status_ideal)) {
    notEmptyGroups.push('status_ideal')
  }

  return notEmptyGroups
}

type SelectedStatus = {active: string[], inactive: string[]}

const emptyState: SelectedStatus = { active: [], inactive: [] }

export default function FilterCompoundStatePopOver ({ id, onFilterOpen } : { id: string, onFilterOpen: () => void }) {
  if (!NEW_API_ACTIVE) {
    return null
  }

  const dispatch = useDispatch()
  //  Read current set filter values from redux.
  const currentFilterValues = useSelector((state: any) => state.devices?.filter ? { ...emptyState, ...state.devices?.filter.compoundState } : { ...emptyState })
  //  Indicates if compound state filter is set.
  const filterActive = currentFilterValues.active.length > 0 || currentFilterValues.inactive.length > 0
  //  Group lists are the main groups such as: status_ideal, attention_required, action_required, inactive
  const [selectedGroupList, setSelectedGroupList] = useState<string[]>([])
  //  Each selected compound state is put into the inactive or active property of this object.
  //  Initally we read it from Redux and store it as currentFilterValues.
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatus>(currentFilterValues)
  //  Hold the expanded groups as a string list. By default we expand status_ideal group.
  const [expandedGroupList, setExpandedGroupList] = useState<string[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  //  Popover management
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (anchorEl) {
      onFilterOpen()
    }
  }, [anchorEl])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const idValue = open ? id : undefined

  //  Handle Main Group selection.
  const handleStateGroupSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.value
    const checked = e.target.checked

    //  Update Group selection list
    setSelectedGroupList(prevState => checked ? [...prevState, key] : prevState.filter((s:string) => s !== key))
    //  Update sub options inside this group
    if (key === 'inactive') {
      setSelectedStatus(prevState => ({ ...prevState, inactive: checked ? compoundStateGroupMapping[key] : [] }))
    } else {
      setSelectedStatus(prevState => ({
                ...prevState,
                  active: checked
                    ? [...new Set([...prevState.active, ...compoundStateGroupMapping[key]])]
                    : prevState.active.filter((s:string) => compoundStateGroupMapping[key].indexOf(s) === -1)
              })
      )
    }
  }

  //  Handle Sub Group selection.
  const handleSingleStateSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueArr = e.target.value.split('##')
    const groupName = valueArr[0]
    const key = valueArr[1]
    const checked = e.target.checked

    const selectedStatusKey = groupName === 'inactive' ? 'inactive' : 'active'

    setSelectedStatus(prevState => ({
      ...prevState,
      [selectedStatusKey]: checked
      ? [...prevState[selectedStatusKey], key]
      : prevState[selectedStatusKey].filter((s:string) => s !== key)
    }))

    //  If one sub status is unchecked then we set parent status also to unchecked.
    if (!checked) {
      setSelectedGroupList(prevState => prevState.filter((s:string) => s !== groupName))
    } else {
      //  Check if the current selected status is the last unselected element inside this group
      //  If this is the case we also select the group
      if (isSubset(compoundStateGroupMapping[groupName], [...selectedStatus[selectedStatusKey], key])) {
        setSelectedGroupList(prevState => [...prevState, groupName])
      }
    }
  }

  //  Handle expand or collapse state for each Main group.
  const handleExpandGroupSelection = (key: string) => {
    setExpandedGroupList(prevState => prevState.indexOf(key) === -1 ? [...prevState, key] : prevState.filter((s:string) => s !== key))
  }

  //  Save selected compound states into the Redux store.
  const handleSave = () => {
    //  Edge case if nothing is selected then send null.
    const valueToSend = selectedStatus.active.length === 0 && selectedStatus.inactive.length === 0 ? null : selectedStatus
    dispatch(applyDeviceFilter({ name: 'compoundState', value: valueToSend }))
  }

  //  On component load: Check for each group if all of it's sub elements are already selected,
  //  if yes add also this group into the selectedGroups array
  useEffect(() => {
    const selectedGroups = getSelectedGroupsByStatusSelection(currentFilterValues)
    const notEmptyGroups = getNotEmptyGroupsByStatusSelection(currentFilterValues)
    setSelectedGroupList(selectedGroups)
    setExpandedGroupList(notEmptyGroups)
  }, [])

  return (
    <>
      <StyledFilterButton onClick={handleOpen} data-cy={`filterBy-${id}`}>
        <FilterIcon filterName='name' active={filterActive} />
      </StyledFilterButton>
      <Popover
        id={idValue}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
      <ClickAwayListener onClickAway={handleSave}>
        <section>
          <StyledPopOverContent>
            {Object.entries(compoundStateGroupMapping).map(([key, value]) => (
              <StyledListContainer key={`${key}`}>
                <StyledStatusLine>
                  <StyledExpandButton data-cy={`${key}-expand`} expanded={expandedGroupList.indexOf(key) > -1} onClick={() => handleExpandGroupSelection(key)}>
                    <img alt="" src="/imgs/arrow-right.svg" />
                  </StyledExpandButton>
                  <StyledInput type="checkbox" className="form-check-input" id={key} value={key} onChange={handleStateGroupSelection} checked={selectedGroupList.indexOf(key) > -1} />
                  <StyledLabel htmlFor={key}>
                    <StatusIndicator status={key} isConnected={key !== 'inactive'}/>
                    {getStatusGroupMessage(key)}
                  </StyledLabel>
                </StyledStatusLine>
                {value.map((val: string) => {
                  return (
                    <StyledStatusLine key={`${key}##${val}`} style={{ marginLeft: '3rem', marginBottom: '.3rem', display: expandedGroupList.indexOf(key) > -1 ? 'flex' : 'none' }}>
                      <StyledInput type="checkbox"
                             className="form-check-input"
                             id={`${key}##${val}`}
                             value={`${key}##${val}`}
                             checked={key === 'inactive' ? selectedStatus.inactive.indexOf(val) > -1 : selectedStatus.active.indexOf(val) > -1}
                             onChange={handleSingleStateSelection} />
                      <StyledLabel htmlFor={`${key}##${val}`}>
                        <StatusIndicator status={key} isConnected={key !== 'inactive'}/>
                        {getStatusMessage(val)}
                      </StyledLabel>
                    </StyledStatusLine>
                  )
                })}
              </StyledListContainer>
            ))}
          </StyledPopOverContent>
          <StyledButtonContainer>
              <ButtonCancel
                onClick={() => dispatch(applyDeviceFilter({ name: 'compoundState', value: null }))}
                data-cy="cancelFilter">
                  Cancel
              </ButtonCancel>
              <StyledSaveButton onClick={handleSave} data-cy="saveFilter">
                Apply Filter
              </StyledSaveButton>
            </StyledButtonContainer>
        </section>
      </ClickAwayListener>
    </Popover>
    </>
  )
}
