// @flow

import { useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'

import { Button, OutlineButton, Tooltip } from 'components/ReUsable'
import Form from 'components/ReUsable/Form'

import BatteryVoltagesSections from './batteryVoltagesSections'
import BatteryTypeSection from './batteryTypeSection'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertedUnitValueBack } from './batteryVoltageSection'
import { handleRequestSaveBatteryConfig } from 'actions'
import { useDispatch } from 'react-redux'

const ButtonSection = styled.div`
  height: 20px;
  margin-top: 30px;
`

const SectionTitle = styled.div`
  color: var(--color-text-muted);
  font-family: open-sans-regular;
  font-size: 14px;
  font-weight: normal;
  padding: 20px 0 20px 0;
`

type Props = {
  serialNumber: string,
  type: string,
  types: string[],
  loadingRequest: boolean
  deviceState: {
    isConnected: boolean,
    lastUpdated: string,
    state: string
  },
  isDeviceInChargingState: boolean
}

export default function BatterySettingsForm (props: Props) {
  const { type, serialNumber, isDeviceInChargingState } = props
  const [formEdited, setFormEdited] = useState<boolean>(false)
  const [resetTrigger, setResetTrigger] = useState(0)
  const dispatch = useDispatch()
  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues: { type: type },
      onSubmit: (values) => {
        //  Do not send object props with value null.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const filterNullValues = Object.entries(values).filter(([key, value]) => value !== null)
        //  Convert all values to numbers and also convert time related values to seconds.
        const convertValues = filterNullValues.map(([key, value]) => key !== 'type' ? [key, convertedUnitValueBack(key, +value)] : [key, value])
        dispatch(handleRequestSaveBatteryConfig(
          { serialNumber: serialNumber, batteryFieldsValues: Object.fromEntries(convertValues) }
        ))
      }
    }
  )

  const handleResetConfig = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    setResetTrigger(resetTrigger + 1)
    setFormEdited(true)
  }

  return (
    <Form handleSubmit={formik.handleSubmit} id={'battery-settings-form'}>
      <SectionTitle>
        Battery type &nbsp;
        <BatteryTypeSection
          formik={formik}
          isDeviceInChargingState={isDeviceInChargingState}
          onFormEdited={() => setFormEdited(true)}
        />
        {
          isDeviceInChargingState && (<>&nbsp;&nbsp;
            <Tooltip message='The battery type cannot be changed while the EFOY Fuel Cell is charging the battery.' >
              <FontAwesomeIcon icon={['fas', 'question-circle']} />
            </Tooltip>
          </>)
        }
      </SectionTitle>
      <div className='form-group'>
        <BatteryVoltagesSections
          initialBatteryType={type}
          formik={formik}
          resetTrigger={resetTrigger}
          onFormEdited={() => setFormEdited(true)}
        />
        <ButtonSection>
          <Button data-cy="btn-save-changes" width='197.19px' type='submit' className='battery-edit-button' {...(!formEdited ? { disabled: true } : {})}>save changes</Button>
        </ButtonSection>
        <ButtonSection>
          <OutlineButton id='outline' type='reset' className='battery-edit-button' onClick={handleResetConfig}>reset charge thresholds</OutlineButton>
        </ButtonSection>
      </div>
    </Form>
  )
}
