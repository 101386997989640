// @flow
import styled from 'styled-components'

const ButtonCancel = styled.button`
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-text);
  padding: 0;
  max-height: 30px;
  display: flex;
  align-items: center;
  gap: 3px;

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid var(--color-text);
    filter: brightness(0.8);
  }
`

export default ButtonCancel
