// @flow

import styled from 'styled-components'

import { OutlineButton } from 'components/ReUsable'

type Props = {
  label: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  secondary?: boolean,
  dark?: boolean,
  iconName?: string,
}

const StyledButton = styled(OutlineButton)`
  border: 1px solid var(--color-table-border);
  border-radius: 0;
  margin: 0;
  color: var(--color-primary);
  transition: scale(1);
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: ${(props: any) => props.dark ? '#e9eaea' : 'transparent'};
  
  &:focus {
    box-shadow: none;
  }
  @media (min-width: 991.98px) {
    padding: 0 .85rem;
  }  

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-background-light);
    border-bottom-left-radius: var(--dim-border-radius);
    border-bottom-right-radius: var(--dim-border-radius);
  }


  @media (prefers-color-scheme: dark) {
    background: ${(props: any) => props.dark ? 'var(--color-background-primary)' : 'var(--color-background-secondary)'};
    &:last-child {
      border-bottom: 1px solid var(--color-table-border);
      border-bottom-left-radius: var(--dim-border-radius);
      border-bottom-right-radius: var(--dim-border-radius);
    }
  }

  &:enabled:hover, &:enabled:focus {
    border-color: var(--color-background-light);
    background: var(--color-background-secondary);
    transition: scale(1) !important;    
    transform: scale(1) !important;

    @media (prefers-color-scheme: dark) {
      border-color: var(--color-table-border);
      background: ${(props: any) => props.dark ? 'var(--color-background-secondary)' : 'var(--color-background-primary)'};
    }
  }
`
const StyledText = styled.span`
  flexGrow: 1;
  color: ${(props: any) => props.secondary ? 'var(--color-text)' : 'var(--color-primary)'};
`

const StyledImg = styled.img`
  width: 1rem;
  height: auto;
`

export default function ButtonOption (props: Props) {
  const { onClick, label, iconName, ...rest } = props
  let mode = 'lightMode'
  // Check if the user prefers a dark color scheme
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    mode = 'darkMode'
  }

  const imgUrl = mode === 'darkMode' ? iconName?.replace('-grey', '-dark-mode') : iconName

  return (
    <StyledButton onClick={onClick} {...rest}>
      <StyledText {...rest}>{label}</StyledText>
      {iconName &&
        <StyledImg style={iconName.indexOf('audit-log') > -1 ? { width: '1.4rem', position: 'relative', left: '0.4rem' } : {}} alt={label} src={`/imgs/${imgUrl}.svg`} />
      }
    </StyledButton>
  )
}
