import styled from 'styled-components'
import { AdminNotificationType } from './hooks/useAdminNotifications'
import NotificationContent from 'components/Application/Notification/notificationContent'
import NotificationInfo from './NotificationInfo'
import { useNotificationContext } from './context/NotificationContext'

export const StyledNoticeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin: 20px 0;
  max-width: 800px;
  
  @media (min-width: 991.98px) {
    grid-template-columns: 1fr 1fr;
    gap: 56px;
  }

  div.modal-content {
    width: min(75vw, 384px);
    min-height: 344px;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    background: var(--color-background-primary);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

    @media (min-width: 1200px) {
      width: min(75vw, 500px);
    }
  }

  div.modal-header {
    padding: 1rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;

    & > div {
      margin: 0 auto;
    }
  }
`

export default function NotificationGrid () {
  const { notifications } = useNotificationContext()
  if (notifications?.length === 0) return <p>You can add a new notice using the add button below.</p>
  if (!notifications) return null
  return (
    <StyledNoticeGrid data-cy="notice-grid">
      {notifications?.map(
        (adminNotification: AdminNotificationType, ind: number) => {
          const { publishDate, userConfirmations, id, title } =
            adminNotification
          return (
            <div key={`${title}+${ind}`} data-cy="grid-item">
              <NotificationGridItem notification={adminNotification} />
              <NotificationInfo {...{ publishDate, userConfirmations, id }} />
            </div>
          )
        }
      )}
    </StyledNoticeGrid>
  )
}

export function NotificationGridItem ({
  notification
}: {
  notification: AdminNotificationType
}) {
  const { title } = notification
  return (
    <div className='modal-content'>
      <div className='modal-header'>
        <div className='modal-title h4' style={{ minHeight: '32px' }}>
          {title}
        </div>
      </div>
      <div className='modal-body'>
        <NotificationContent activeNotification={notification} />
      </div>
    </div>
  )
}
