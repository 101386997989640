import { useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'

const StyledPopOverContent = styled.div`
  border: none;
  background: transparent;
`

const StyledSelectorButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid var(--color-border-light);
  width: 140px;
  height: 32px;
  padding: 0;
  border-radius: 0.375rem;
  gap: 6px;
  min-height: 32px;

  @media (max-width: 992px) {
    width: 120px;
  }

  & > div:first-of-type {
    width: 80%;
    min-height: 100%;
    border-bottom-left-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    display: flex;
    align-items: center;

    & > span {
      opacity: .6;
      font-size: 14px;
      padding-left: 8px;
    }
  }
`

export default function ColorSelector ({ color, updateColor }: { color: string, updateColor: (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <>
    <StyledSelectorButton onClick={handleOpen} type="button" data-cy="tag-color-button">
      <div style={{ backgroundColor: color }}><span>{!color && 'Select color'}</span></div>
      <FontAwesomeIcon
          style={
            open
              ? {
                  transform: 'rotate(-180deg)',
                  transition: 'all .25s ease-in-out',
                  color: 'var(--color-primary)'
                }
              : {
                  transform: 'rotate(0deg)',
                  transition: 'all .25s ease-in-out',
                  color: 'var(--color-primary)'
                }
          }
          icon={['fas', 'chevron-down']}
        />
    </StyledSelectorButton>
      <Popover
        id="color-selector"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
      <ClickAwayListener onClickAway={() => handleClose()}>
        <StyledPopOverContent>
          <ChromePicker disableAlpha={true} onChangeComplete={(color, event) => {
            updateColor(color, event)
          }} color={color}/>
        </StyledPopOverContent>
        </ClickAwayListener>
    </Popover>
    </>
  )
}
