// @flow

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  Row,
  Col,
  Gap,
  Card,
  Input,
  Button,
  ButtonBar,
  OutlineButton
} from 'components/ReUsable'
import Label from 'components/ReUsable/Label'

import {
  FULL_SUPPORT,
  EFOY_MONITORING,
  CARTRIDGE_MONITORING,
  delegationScreenName
} from 'appConstants'

import { StyledSubTitle } from 'components/ReUsable/HeaderBar'
import AddDelegationAlert from './AddDelegationAlert'
import SelectElement from 'components/ReUsable/SelectElement'

export const StyledCard = styled(Card)`
  position: relative;
  border: 1px solid var(--color-border-light);
  border-radius: 0.4rem;

  @media (min-width: 991.98px) {
    max-height: 18rem;
    min-height: 18rem;
  }
`

export const StyledCancelButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  color: var(--color-text);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-text);
  padding: 0;

  @media (max-width: 991.97px) {
    top: 1rem;
    right: 1rem;
  }

  @media (min-width: 991.98px) {
    bottom: 1.5rem;
    left: 1rem;
  }

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid var(--color-text);
    filter: brightness(0.8);
  }
`

const StyledHideMobileCol = styled(Col)`
  @media (max-width: 991.98px) {
    display: none;
  }
`

const StyledHideDesktopLabel = styled(Label)`
  @media (min-width: 991.98px) {
    display: none;
  }
`

export const StyledInput = styled(Input)`
  @media (min-width: 991.98px) {
    max-width: 23rem;
  }
`

const StyledEmailGroupCol = styled(Col)`
  @media (max-width: 991px) {
    min-width: 100%;
  }
`

const StyledError = styled.span`
  color: var(--color-alert-error);
  font-size: 1rem;
`

type FormProps = {
  backLink: string,
  error: number | string,
  success: boolean,
  formik: any,
  lastInsertedName?: string,
  isBatch?: boolean,
  mode?: 'ADD' | 'REMOVE',
};

const DelegationFormUI = function ({
  error,
  success,
  formik,
  backLink,
  lastInsertedName,
  isBatch,
  mode
}: FormProps) {
  const history = useHistory()
  return (
    <StyledCard>
      <form id="add-efoy-role-form" onSubmit={formik.handleSubmit}>
        <AddDelegationAlert
          success={success}
          error={error}
          name={lastInsertedName}
        />
        <Row>
          <Col base="60%">
            <StyledSubTitle>User/Group</StyledSubTitle>
          </Col>
          {!isBatch || mode === 'ADD' ? (
            <StyledHideMobileCol base="40%">
              <StyledSubTitle>Role</StyledSubTitle>
            </StyledHideMobileCol>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col base="60%">
            <Row alignItems="center">
              <StyledEmailGroupCol base="25%">
                <Label htmlFor="name">Email / Group ID</Label>
              </StyledEmailGroupCol>
              <StyledEmailGroupCol base="75%">
                <StyledInput
                  id="name"
                  className={`form-control ${
                    formik.errors.name ? 'input-errored' : ''
                  }`}
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && (
                  <StyledError>{formik.errors.name}</StyledError>
                )}
              </StyledEmailGroupCol>
            </Row>
          </Col>
          <Gap />
          {!isBatch || mode === 'ADD' ? (
            <Col base="40%" direction="row">
              <StyledHideDesktopLabel htmlFor="role">
                Role
              </StyledHideDesktopLabel>
              <SelectElement
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                className={`form-control ${
                  formik.errors.name ? 'input-errored' : ''
                }`}
               >
                <option
                  value={CARTRIDGE_MONITORING}
                  label={delegationScreenName(CARTRIDGE_MONITORING)}
                />
                <option
                  value={EFOY_MONITORING}
                  label={delegationScreenName(EFOY_MONITORING)}
                />
                <option
                  value={FULL_SUPPORT}
                  label={delegationScreenName(FULL_SUPPORT)}
                />
               </SelectElement>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        {!isBatch ? (
          <ButtonBar>
            <OutlineButton
              type="submit"
              id="button-add"
              onClick={(e) => { // eslint-disable-line @typescript-eslint/no-unused-vars
                formik.setFieldValue('submitBtnId', 'button-add')
              }}
            >
              Save & New
            </OutlineButton>
            <Button
              type="submit"
              id="button-save"
              onClick={(e) => { // eslint-disable-line @typescript-eslint/no-unused-vars
                formik.setFieldValue('submitBtnId', 'button-save')
              }}
            >
              Save & Go Back
            </Button>
          </ButtonBar>
        ) : (
          <ButtonBar>
            <Button
              type="submit"
              id="button-batch"
              onClick={(e) => { // eslint-disable-line @typescript-eslint/no-unused-vars
                formik.setFieldValue('submitBtnId', 'button-batch')
              }}
            >
              {mode === 'ADD' ? 'Save changes' : 'Delete'}
            </Button>
          </ButtonBar>
        )}
      </form>
      <StyledCancelButton
        id="button-cancel"
        onClick={() =>
          history.push({
            pathname: backLink,
            state: { from: history?.location?.pathname } //  Put referrer into the state. (Used on Home.tsx)
          })
        }
      >
        Cancel
      </StyledCancelButton>
    </StyledCard>
  )
}

export default DelegationFormUI
