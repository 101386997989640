// theme.ts
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--color-background-primary)'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: 'var(--color-text)',
          border: '1px solid var(--color-accordion-table-border)',
          backgroundImage: 'none'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: 'var(--color-text)',
          borderTop: '0.5px solid var(--color-accordion-table-border)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'var(--color-text)'
        }
      }
    }
  }
})

export default theme
