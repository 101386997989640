// @flow
import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { ColorResult } from 'react-color'

import { PERSONAL_TAG_PLACEHOLDER, Tag, TagPost, useTagContext } from './context/tagContext'
import ColorSelector from './colorSelector'
import { Button, ButtonCancel, Input } from 'components/ReUsable'
import DeleteMenu from 'components/ReUsable/DeleteMenu'
import { useFormik } from 'formik'
import { randomHexColorCode } from 'utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-light-svg-icons'

const StyledItemRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  align-items: flex-start;
  min-height: 35px;
  margin: 5px 0;

  @media (max-width: 767px) {
    grid-template-columns: 4fr 1fr 1fr;
  }

  & > div {
    text-align: left;
  }

  & > div:last-child {
    text-align: right;
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;
  }
`

const StyledItemFormRow = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr;
  width: 100%;
  align-items: flex-start;
  min-height: 35px;
  margin: 5px 0;

  @media (max-width: 767px) {
    grid-template-columns: 1.8fr 1fr;
    column-gap: 10px;
  }

  & > div {
    text-align: left;
  }

  & > div:last-child {
    text-align: right;
    @media (max-width: 767px) {
      text-align: left;
    }
    
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;
  }
`

const StyledInput = styled(Input)`
  max-width: 240px;
`

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`

const StyledButton = styled(Button)`
  min-height: 35px;
  min-width: 6rem;
  margin: 0;
`

const StyledLabel = styled.span`
  border-radius: 0.375rem;
  padding: 4px 10px;
  font-size: 14px;
  @media (max-width: 767px) {
    display: inline-block;
    white-space: nowrap;
    max-width: 50vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledItemButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: .5rem;
`

const StyledEditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
  background: transparent;

  &:hover {
    filter: brightness(70%);
  }
`

const StyledEditIcon = styled(FontAwesomeIcon)`
  min-width: 1.4rem;
  height: auto;
  color: var(--color-text);
`

const ErrorSpan = styled.span`
  font-size: 12px;
  color: var(--color-alert-error);
  font-family: open-sans-regular;
  letter-spacing: 0.14px;
  @media (max-width: 991px) {
    margin-top: 0 !important;
  }
  min-height: 17px;
`

const TagItem = ({ tag, inEditMode, onEditMode, groupSlug }: { tag: Tag; inEditMode: boolean; onEditMode: () => void, groupSlug?: string | null }) => {
  const { handleTagDelete } = useTagContext()
  if (inEditMode) {
    return <TagForm tag={tag} onEditMode={onEditMode} groupSlug={groupSlug} />
  }

  const deleteTag = async () => {
    if (tag && tag.id) {
      await handleTagDelete(tag?.id, groupSlug || PERSONAL_TAG_PLACEHOLDER)
    }
  }

  return (
    <StyledItemRow>
      <div>
        <StyledLabel data-cy="tag-label" style={{ border: `2px solid ${tag.color}`, color: tag.color }}>{tag.name}</StyledLabel>
      </div>
      <StyledItemButtons>
        <StyledEditButton onClick={onEditMode} data-cy="edit-tag">
          <StyledEditIcon icon={faPen as any} className='icon-dark-mode-ready' />
        </StyledEditButton>
        <DeleteMenu onDelete={deleteTag} />
      </StyledItemButtons>
    </StyledItemRow>
  )
}

export function TagForm ({ tag, groupSlug, onEditMode }: { tag: Tag | null; groupSlug?: string | null; onEditMode: () => void }) {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef) {
      inputRef?.current?.focus()
    }
  }, [])
  const { handleTagAddEdit, isUniqueInGroup } = useTagContext()
  const formik = useFormik({
    initialValues: {
      name: tag?.name || '',
      color: tag?.color || randomHexColorCode(),
      group: groupSlug || null,
      id: tag?.id || undefined
    },
    onSubmit: async (values) => {
      const isSuccess = await handleTagAddEdit(values, groupSlug || undefined)
      if (isSuccess) {
        onEditMode()
      }
    },
    validate: async (values) => {
      const errors: { name?: string; color?: string } = {}
      if (!values.name.trim()) {
        errors.name = 'Required!'
      }
      if (!isUniqueInGroup(values as TagPost)) {
        errors.name = 'This tag already exists!'
      }
      if (!values.color) {
        errors.color = 'Required!'
      }
      return errors
    },
    validateOnChange: true
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateColor = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('color', color.hex)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledItemFormRow>
        <div style={{ display: 'block' }}>
          <StyledInput ref={inputRef} name="name" data-cy="tag-label-input" placeholder="Tag Label" className="form-control" type="text" value={formik.values.name} onChange={formik.handleChange} maxLength={32} />
          <ErrorSpan data-cy="validation-error" className="error">{formik.errors.name}&nbsp;</ErrorSpan>
        </div>
        <div>
          <ColorSelector updateColor={updateColor} color={formik.values.color} />
          <ErrorSpan className="error">{formik.errors.color}&nbsp;</ErrorSpan>
        </div>
        <StyledButtonBar>
          <ButtonCancel style={{ fontSize: '12px', height: '22px' }} onClick={onEditMode} data-cy="cancel-tag" type="button">
            Cancel
          </ButtonCancel>
          <StyledButton data-cy="submit-label-button" type="submit">{tag?.id ? 'Save' : 'Add'}</StyledButton>
        </StyledButtonBar>
      </StyledItemFormRow>
    </form>
  )
}

export default TagItem
