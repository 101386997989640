import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import './css/general.scss'
import './css/navbar.scss'
import './css/burger_menu.scss'
import './css/home.scss'
import './css/home_dialog.scss'
import './css/battery_edit.scss'
import './css/contact.scss'
import './components/ReUsable/DatePicker/datepicker.scss'
import './css/static_home.scss'
import './css/charts.scss'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Div100vh from 'react-div-100vh'

import configureStore from './configureStore'
import history from './utils/history'

import Header from 'components/Application/Header'
import Router from 'components/Application/Router'
import Footer from 'components/Application/Footer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { ThemeProvider } from '@mui/material'

import AvailabilityCheck from 'components/Application/AvailabilityCheck'
import { useCallback, useState, useEffect } from 'react'
import { integrationTestRunning } from 'utils/helpers'
import theme from 'themes/theme'

// provide initial state if any
const store = configureStore({})

library.add(fas, far as any, fal as any, fad)

function useForceUpdate () {
  const [, setState] = useState(0)
  return useCallback(() => setState((prev) => prev + 1), [])
}

export default function App () {
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (!integrationTestRunning(process.env.REACT_APP_ENV_MODE) && process.env.REACT_MATOMO_URL) {
      const _mtm = (window as any)._mtm = (window as any)._mtm || []
      // eslint-disable-next-line quote-props
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
      const d = document
      const g = d.createElement('script')
      const s = d.getElementsByTagName('script')[0]
      g.async = true; g.src = process.env.REACT_MATOMO_URL; s?.parentNode?.insertBefore(g, s)
    }
  }, [])

  return (
    <Div100vh className="main-container">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <Header />
            <AvailabilityCheck>
              <Router />
            </AvailabilityCheck>
            <Footer onForceUpdate={forceUpdate} />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </Div100vh>
  )
}
