// @flow

import { useSelector } from 'react-redux'

import { TRY_CONNECTION_SCREEN_STEP } from 'appConstants'

export function AddEfoyStepThree () {
  const devices = useSelector((state: any) => state.devices)
  const {
    currentUserStep, currentStep, lastStep
  } = devices.pageDialog

  return currentStep === TRY_CONNECTION_SCREEN_STEP ? (
    <>
      <p className='add-efoy-message'>
        The connection of the EFOY Fuel Cell and your mobile device is set up.
        This can take a few minutes.
      </p>
      {/* this section can be a isolated component */}
      <div className='d-flex justify-content-between buttons-section'>
        <i className='fas fa-circle-notch fa-spin fa-3x loading-spinner'></i>
      </div>
      {/* this section can be a isolated component */}
      <p className='modal-step'>Step { currentUserStep } of { lastStep }</p>
    </>
  ) : null
}

export default AddEfoyStepThree
