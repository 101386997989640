// @flow
import styled from 'styled-components'
import TableHeading from './heading'
import TableRow from './row'

type Props = {
  table: any
  headings?: any,
  BuildHeadingContent?: any,
  BuildRowContent?: any,
  rows?: Array<any>,
  className?: string,
  tableClass?: string,
  theadLineClass?: string,
  editRowContent?: () => void,
  sortByColumn?: (sortKey: string) => void,
  hasFilters?: boolean

}

const DataTable = (props: Props) => {
  const {
    className,
    tableClass,
    theadLineClass,
    table,
    hasFilters
  } = props

  return (
    <table className={`${tableClass} ${className}`}>
      <thead className={theadLineClass}>
        {table.getHeaderGroups().map(headerGroup => (
          <TableHeading headerGroup={headerGroup} key={headerGroup.id} />
        ))}
      </thead>
      <tbody>
        {
          table.getRowModel().rows.length > 0 ? table.getRowModel().rows.map(row => (
            <TableRow row={row} key={row.id} style={table.options.meta?.getRowStyles(row)} />
          )) : (<tr><td colSpan={100}>{hasFilters ? 'No items to load. Please adjust your filter criteria.' : 'No items to load'}</td></tr>)
        }
      </tbody>
    </table>
  )
}

const CommonTable = styled(DataTable)`
  border-radius: var(--dim-border-radius);
  border: 0;
  overflow: hidden;

  td,
  th {
    border: 0;
    border-left: 1px solid var(--color-background-secondary);
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }

    &.sortable {
      cursor: pointer;
    }
  }

  th {
    font-weight: normal !important;

    span {
      display: inline-block;
    }

    svg {
      display: inline-block;
    }
  }

  td {
    border-left-color: var(--color-table-border);
    font-family: open-sans-regular;
    color: var(--color-table-text);

    img {
      max-height: 56px;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: var(--color-table-even-row);
    }

    tr:nth-child(odd) {
      background-color: var(--color-table-odd-row);
    }
  }

  thead {
    color: var(--color-lightest);
    background-color: var(--color-table-header);
  }
`

const CommonResponsiveTable = styled(DataTable)`
  border-radius: var(--dim-border-radius);
  border: 0;
  min-width: 100%;

  @media (max-width: 720px) {
    width: 100%;
    table-layout: fixed;
  }

  td,
  th {
    border: 0;
    border-left: 1px solid var(--color-table-header-border);
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }

    &.sortable {
      cursor: pointer;
    }
  }

  th {
    font-weight: normal !important;
    
    &:fist-child {
      width: 15%;
      @media (max-width: 992px) {
        width: 20%
      }
    }

    &:nth-child(2) {
      width: 25%;
      @media (max-width: 992px) {
        width: 25%
      }
    }


    &:nth-child(3) {
      width: 20%;
      @media (max-width: 992px) {
        width: 25%
      }
    }

    &:nth-child(4) {
      width: 40%;
      @media (max-width: 992px) {
        width: 30%
      }
    }
    span {
      display: inline-block;
    }
  }

  td {
    border-left-color: var(--color-table-border);
    font-family: open-sans-regular;
    color: var(--color-table-text);
    padding: 8px;

    &:fist-child {
      width: 15%;
      @media (max-width: 992px) {
        width: 20%
      }
    }

    &:nth-child(2) {
      width: 25%;
      @media (max-width: 992px) {
        width: 25%
      }
    }


    &:nth-child(3) {
      width: 20%;
      @media (max-width: 992px) {
        width: 25%
      }
    }

    &:nth-child(4) {
      width: 40%;
      @media (max-width: 992px) {
        width: 30%
      }
    }

    @media (max-width: 720px) {
      font-size: 12px;
      padding: .2rem;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: var(--color-table-even-row);
    }

    tr:nth-child(odd) {
      background-color: var(--color-table-odd-row);
    }
  }

  thead {
    color: var(--color-lightest);
    background-color: var(--color-table-header);
  }
`

export { DataTable, CommonTable, CommonResponsiveTable }
