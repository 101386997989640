// @flow

import { useDispatch } from 'react-redux'
import {
  Form,
  Col
} from 'react-bootstrap'
import { useFormik } from 'formik'

import {
  updateDeviceName
} from 'actions'

import {
  Button,
  Input
} from 'components/ReUsable'
import styled from 'styled-components'

const FieldError = styled.p`
  font-size: 12px;
  color: var(--color-alert-error);
  font-family: open-sans-regular;
  letter-spacing: 0.14px;
  min-height: 18px;
  width: 335px;
  margin: auto;
`

type Props = {
  device: any,
  togglePageDialog: () => void
}

const UpdateDeviceNameDialog = (props: Props) => {
  const {
    device,
    togglePageDialog
  } = props
  const dispatch = useDispatch()

  const formik = useFormik(
    {
      initialValues: {
        name: device.name
      },
      onSubmit: (values) => {
        dispatch(updateDeviceName({ serialNumber: device.serialNumber, name: values.name }))
        togglePageDialog()
      }
    }
  )
  const isNameValid = formik.values.name && formik.values.name.trim().length > 4

  return (
    <>
      <Col>
        <Form id='update-device-name-form' onSubmit={ formik.handleSubmit }>
          <Input
            type='text' id='name' name='name' placeholder='EFOY 85 - 12345'
            className={ 'form-control confirm-efoy' }
            onChange={ (event: any) => { formik.setFieldValue('name', event.target.value) } } value={ formik.values.name }
          />
          <FieldError>{!isNameValid ? 'Please input at least five characters.' : ''}</FieldError>
          <div className='d-flex justify-content-between buttons-section'>
            <Button
              type='submit' id='button-confirm-efoy'
              disabled={ !isNameValid }
            >
              SAVE AND CLOSE
            </Button>
          </div>
        </Form>
      </Col>
     </>
  )
}

export default UpdateDeviceNameDialog
