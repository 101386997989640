// @flow

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

type Props = {
  filterName: 'name' | 'tags' | 'firmware' | 'compoundState' | 'serialNumber',
  active?: boolean
}

export default function FilterIcon (props: Props) {
  const { filterName, active } = props

  if (!filterName) {
    return null
  }

  return <FontAwesomeIcon icon={[active ? 'fas' : 'far', 'filter']} style={{ color: 'var(--color-lightest)' }}/>
}

interface StyledIconProps {
  active: boolean | undefined;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<StyledIconProps>`
  color: var(--color-lightest);
  font-size: ${(props: any) => props.active ? '14px' : '17px'};
  margin-right: 2px;
  margin-top: 4px;
`

export function FilterByTagIcon (props: Props) {
  const { filterName, active } = props

  if (!filterName) {
    return null
  }

  return <StyledFontAwesomeIcon icon={[active ? 'fas' : 'far', 'tags']} active={active || undefined}/>
}
