// @flow

import { ButtonOption } from 'components/ReUsable'
import { NEW_API_ACTIVE } from '../../../../../src/featureToggles'

type Props = {
  togglePageDialog: (obj: any) => void,
};

export function DownloadReportButton (props: Props) {
  if (!NEW_API_ACTIVE) {
    return null
  }
  return (
    <ButtonOption
      onClick={() =>
        props.togglePageDialog({ dialogId: 'device-export-dialog' })
      } /*  */
      label="Download Report"
      secondary={true}
      dark={true}
    />
  )
}

export default DownloadReportButton
