// @flow
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NEW_API_ACTIVE } from 'featureToggles'
import styled from 'styled-components'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'

const StyledLegend = styled.div`
  margin: 0 .75rem;
  display: flex;
  align-items: center;
  
  @media (max-width: 800px) {
    margin-right: 0;
    flex-wrap: wrap;
    gap: .5rem;
    max-width: 70%;
  }
`

const StyledClusterIcon = styled.img`
  margin-right: .3rem;
  position: relative;
`

const StyledClusterLabel = styled.span`
  color: var(--color-text);
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-right: 1rem;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  font-size: 20px;
  margin-right: .3rem;
`

const ClusterLegend = function () {
  if (!NEW_API_ACTIVE) {
    return null
  }
  return (
    <StyledLegend>
      <div>
      <StyledClusterIcon src={'/imgs/upgrade.svg'}
        alt="License upgrade needed"
        style={{ width: '24px', height: '24px' }}
      />
      <StyledClusterLabel>
        License upgrade needed
      </StyledClusterLabel>
      </div>
      <div style={{ display: 'flex' }}>
        <StyledFontAwesomeIcon icon={faScrewdriverWrench as any} title="In Service"/>
        <StyledClusterLabel>
          In service
        </StyledClusterLabel>
      </div>
      <div>
      <StyledClusterIcon src={'/imgs/Role_controller.svg'}
        alt="Role controller"
        style={{ top: '2px' }}
      />
      <StyledClusterLabel>
        cluster controller
      </StyledClusterLabel>
      </div>
      <div>
      <StyledClusterIcon src={'/imgs/Role_client.svg'} style={{ bottom: '3px' }}
        alt="Role client"
      />
      <StyledClusterLabel>cluster client</StyledClusterLabel>
      </div>
    </StyledLegend>
  )
}

export default ClusterLegend
