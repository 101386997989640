import * as C from 'appConstants'

export const initialState = {
  graphScale: {
    timeScale: 'Hours',
    maxDays: 31
    // start date today - 3 days
  },
  _startDate: Date.now() - 3 * 24 * 60 * 60 * 1000,
  _endDate: Date.now(),
  _maxDate: Date.now(),
  _defaultStartDate: Date.now() - 29 * 24 * 60 * 60 * 1000,
  xExtremes: [null, null],
  chartRefs: []
}

export const defaultStartDateVal = Date.now() - 3 * 24 * 60 * 60 * 1000

const toggleGraphScale = (state: any, payload: any) => {
  return {
    ...state,
    graphScale: {
      timeScale: payload === 'HOURS' ? 'HOURS' : 'MINUTES',
      maxDays: payload === 'HOURS' ? 31 : 1
    }
  }
}

export const adjustDateRange = (state: any, payload: any) => {
  return {
    ...state,
    _startDate: payload._startDate,
    _endDate: payload._endDate
  }
}

export const setZoom = (state: any, payload: any) => {
  return {
    ...state,
    xExtremes: payload.xExtremes
  }
}

export const setNewRef = (state: any, payload: any) => {
  return {
    ...state,
    chartRefs: [...state.chartRefs, payload.currentRef]
  }
}

export default (state: any = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case C.TOGGLE_GRAPH_SCALE:
      return toggleGraphScale(state, payload)
    case C.ADJUST_DATE_RANGE:
      return adjustDateRange(state, payload)
    case C.SET_ZOOM:
      return setZoom(state, payload)
    case C.SET_NEW_REF:
      return setNewRef(state, payload)
    default:
      return state
  }
}
