import styled from 'styled-components'

import { Container, Row } from 'components/ReUsable'
import { NavLink, useRouteMatch } from 'react-router-dom'
import useAdminRoute from '../hooks/useAdminRoute'
import { LICENSE_WRITE, NOTICE_READ, USERS_READ } from '../constants'

const StyledContainer = styled(Container)`
  display: block;
  margin-top: 1rem;
`

const StyledNavLink = styled(NavLink)`
  color: var(--color-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-right: 12px;
`

const StyledNavBarRow = styled(Row)`
  flex-direction: row;
`

export default function NavBar () {
  const { url } = useRouteMatch()
  return (
  <StyledContainer data-cy="page-admin-navbar">
    <StyledNavBarRow margin="1rem 0">
      <AdminNavLink to={`${url}/licenses`} name="Licenses" rights={[LICENSE_WRITE]} dataCy="admin-link-licenses"/>
      <AdminNavLink to={`${url}/notices`} name="Notices" rights={[NOTICE_READ]} dataCy="admin-link-notices"/>
      <AdminNavLink to={`${url}/auditlogs`} name="Audit" rights={[USERS_READ]} dataCy="admin-link-auditlogs"/>
    </StyledNavBarRow>
  </StyledContainer>
  )
}

function AdminNavLink ({ name, to, rights, exact, dataCy }:{ name: string, to: string, rights: string[], exact?: boolean, dataCy?: string }) {
  const { pageAvailable } = useAdminRoute({ path: to, rights })
  if (pageAvailable) {
    return <StyledNavLink to={to} exact={exact} activeStyle={{ color: 'var(--color-primary)', textDecorationLine: 'underline' }} data-cy={dataCy}>{name}</StyledNavLink>
  }
  return null
}
