
import { StatusIndicator } from 'components/ReUsable'
import { NEW_API_ACTIVE } from 'featureToggles'
import styled from 'styled-components'

const Legend = styled.div<{newApi: boolean}>`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    margin-top:   ${props => props.newApi ? '1rem' : '0'};
  }

  @media (max-width: 800px) {
    max-width:   ${props => props.newApi ? '100%' : '60%'};
  }
`

const LegendText = styled.div`
  margin: 0 0 0 0.3rem;
  font-size: 13px;
  color: var(--color-table-text);
`

const LegendItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin: 0 0.5rem;
  width: max-content;
  justify-content: center;
  align-items: center;

  &:last-child, &:first-child {
    margin-right: 0;
  }
`
export default function Info () {
  return (
    <Legend newApi={NEW_API_ACTIVE}>
      <LegendItem>
        <StatusIndicator small={true} status='standby' isConnected={true}></StatusIndicator>
        <LegendText>status ideal</LegendText>
      </LegendItem>
      <LegendItem>
        <StatusIndicator small={true} status='warning' isConnected={true}></StatusIndicator>
        <LegendText>attention required</LegendText>
      </LegendItem>
      <LegendItem>
        <StatusIndicator small={true} status='error' isConnected={true}></StatusIndicator>
        <LegendText>action required</LegendText>
      </LegendItem>
      <LegendItem>
        <StatusIndicator small={true} status='unknown' isConnected={false}></StatusIndicator>
        <LegendText>inactive</LegendText>
      </LegendItem>
    </Legend>
  )
}
