// @flow

import styled from 'styled-components'
import { capFuelPercentageValue } from 'utils/helpers'

type Props = {
  large?: boolean,
  fuelCartridge: any,
}

const Wrapper = styled.div`
  display: block;
  position: relative;
  width: ${(props: any) => props.large ? '3.5' : '2.2'}rem;
  height: ${(props: any) => props.large ? '4.6' : '2.5'}rem;
`

const LevelMax = styled.div`
  --padding: ${(props: any) => props.large ? '0.3rem' : '0.2rem'};
  position absolute;
  left: var(--padding);
  bottom: var(--padding);
  right: var(--padding);
  top: ${(props: any) => props.large ? '0.8rem' : '0.6rem'};
`

const Level = styled.div<{height: any}>`
  position absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-primary);
  height: ${(props: any) => props.height}%;

  ${(props: any) => {
    if (props.height <= 10) {
      return 'background: var(--color-alert-error);'
    } else if (props.height <= 40) {
      return 'background: var(--color-alert-warning);'
    }
  }}
`
const fuelCartridgeImage = (fuelCartridge, large) => {
  let mode = ''
  // Check if the user prefers a dark color scheme
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    mode = '-dark'
  }
  if (fuelCartridge.portEnabled === false) {
    return <img alt='Remaining total fuel' src={ `/imgs/fuel-cartridge${large ? '' : '-small'}-deactivated${mode}.svg` } />
  } else if (fuelCartridge.cartridgeType === 'UNSPECIFIED') {
    return <img alt='Remaining total fuel' src={ `/imgs/fuel-cartridge${large ? '' : '-small'}-disconnected${mode}.svg` } />
  } else if (fuelCartridge.fuelLevel <= 0) {
    return <img alt='Remaining total fuel' src={ `/imgs/fuel-cartridge${large ? '' : '-small'}-empty${mode}.svg` } />
  }

  return <img alt='Remaining total fuel' src={ `/imgs/fuel-cartridge${large ? '' : '-small'}${mode}.svg` } />
}

export default function FuelCartridge (props: Props) {
  const {
    fuelCartridge,
    large
  } = props

  return (
    <Wrapper {...{ large }}>
      {
        fuelCartridge.cartridgeType === 'UNSPECIFIED' ? null : fuelCartridge.portEnabled && <LevelMax {...{ large }}>
          <Level {...{ height: capFuelPercentageValue(fuelCartridge.fuelLevel) }} />
        </LevelMax>
      }
      {fuelCartridgeImage(fuelCartridge, large)}
    </Wrapper>
  )
}
