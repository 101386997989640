// @flow

import styled from 'styled-components'
import { PERSONAL_TAG_PLACEHOLDER, Tag, useTagContext } from '../context/tagContext'
import { ALERT_TYPE, Alert } from 'components/ReUsable'

const StyledTagListHeader = styled.h3`
  font-size: 18px;
  color: var(--color-text);
`

const StyledTagList = styled.div`
  margin-bottom: 12px;
`

const StyledButton = styled.button`
  border-radius: 0.375rem;
  padding: 4px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: transparent;
`

const TagSelectionList = ({ groupSlug = null, groupName = null, tags, selectedTags, onSelectTag }: { groupSlug?: null | string; groupName?: null | string; tags: Tag[], selectedTags: string[], onSelectTag: (id: string) => void }) => {
  const { apiError } = useTagContext()

  if (tags.length === 0) return null

  return (
    <StyledTagList>
      <StyledTagListHeader>{`${groupName || 'My Tags'}:`}</StyledTagListHeader>
      {apiError.includes(groupSlug || PERSONAL_TAG_PLACEHOLDER) ? <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} /> : null }
      {tags.map((t: Tag) => {
        const styleOfButton = selectedTags.includes(t.id) ? { border: `2px solid ${t.color}`, backgroundColor: t.color, color: 'var(--color-lightest)' } : { border: `2px solid ${t.color}`, color: t.color }
        return (
          <StyledButton key={t.id} style={styleOfButton} onClick={() => onSelectTag(t.id)}>{t.name}</StyledButton>
        )
      })}
    </StyledTagList>
  )
}
export default TagSelectionList
