// @flow

import styled from 'styled-components'
import {
  ShowDataValue
} from 'components/ReUsable'

type Props = {
  data: any,
  property: string,
  suffix: string,
  title: string,
  decimals?: number,
  max?: number,
  onClick?: () => void,
}

const Wrapper = styled.figure`
  display: flex;
  flex-direction: column;
  width: 9rem;
  height: 9rem;
  position: relative;
  align-items: center;
`

const Title = styled.div`
  color: var(--color-text);
  font-family: open-sans-regular;
  font-size: 1rem;
  text-align: center;
  flex: 1 1 40%;
  position: relative;
  z-index: 2;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  max-width: 80%;
  margin-top: -0.2rem;
  line-height: 1.2rem;
`

const Value = styled.div`
  color: var(--color-primary);
  font-family: DigitalSerialBold;
  font-size: 1.65rem;
  text-align: center;
  flex: 1 1 50%;
  position: relative;
  z-index: 2;
  justify-content: center;
  display: flex;
  align-items: flex-end;
`

const Chart = styled.svg`
  stroke: var(--color-primary);
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const CircleBackground = styled.path`
  fill: none;
  stroke: #C0C0C0;
  stroke-width: 2.5;
`

const CircleBackgroundDark = styled.path`
  fill: none;
  stroke: #606060;
  stroke-width: 2.5;
`

const Circle = styled.path`
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
`

export default function CircularGraph (props: Props) {
  const decimals = props.decimals === undefined ? 1 : props.decimals
  const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  return (
    <Wrapper>
      <Value>
        <ShowDataValue min={0} data={ props.data } property={ props.property } suffix={ props.suffix } showMessage={ false } decimals={ decimals } showZero />
      </Value>
      <Title>{props.title}</Title>
      <Chart viewBox="0 0 36 36" data-cy={`chart-section-${props.title}`}>
        {darkMode
        ? <CircleBackgroundDark
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        : <CircleBackground
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        }
        {props.data && props.data[props.property] > 0 ? <Circle
          strokeDasharray={ [100, props.max || 100].join(',') }
          id="filled-graph"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        /> : <></> }
      </Chart>
    </Wrapper>
  )
}
