// @flow

import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Route } from 'react-router-dom'

import { integrationTestRunning } from 'utils/helpers'
import { useAuth0 } from 'utils/react_auth0_spa'

import NotificationModal from '../Notification'
import StaticHome from '../StaticHome'

type Props = {
  component: React.ElementType
  path: string
  rest?: any
  exact?: boolean
}

export default function PrivateRoute ({ component: Component, path, ...rest }: Props) {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const [session, setSession] = useState<string | null>(null)

  useLayoutEffect(() => {
    setSession(localStorage.getItem('session'))
  }, [])

  useEffect(() => {
    if (loading || isAuthenticated || integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
      return
    }
    const redirectIfNecessary = async () => {
      if (session === 'auth-set') {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname }
        })
      }
    }
    redirectIfNecessary()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  if (!session && !integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return <StaticHome />
  }

  const render = (props: any) => {
    if (isAuthenticated || integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
      return (
        <>
          {/** On admin pages, we do not add <NotificationModal/> it is only for website users. */}
          {path.indexOf('/admin') === -1 ? <NotificationModal /> : <></>}
          <Component {...props} />
        </>
      )
    }
    return null
  }
  return <Route path={path} render={render} {...rest} />
}
