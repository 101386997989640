import { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'

type SelectElementType = SelectHTMLAttributes<HTMLSelectElement> & {
  width?: string;
  height?: string;
}

const StyledSelect = styled.select<{ width?: string, height?: string }>`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '33.5px'};
  background: var(--color-background-primary);
  border: 1px solid var(--color-border-light);
  border-radius: 0.375rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent url('/imgs/select-arrow-down.svg') no-repeat;
  background-position: calc(100% - 10px) 50%;
  font-family: DigitalSerialBold;
  padding: .375rem 2rem .375rem 0.75rem;
  font-size: .9rem;
  color: var(--color-text);

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(101, 179, 48, 0.25);
  }
  
  &:disabled {
    opacity: 1;
    background: #e9ecef url('/imgs/select-arrow-down-disabled.svg') no-repeat;
    background-position: calc(100% - 10px) 50%;
  
    @media (prefers-color-scheme: dark) {
      background-color: var(--color-disabled);
      opacity: .7;
    }
  
  }

  & > option {
    font-family: DigitalSerialBold, system-ui;
  }
`

// The Select component
export default function SelectElement ({ children, width, height, ...rest } : SelectElementType) {
  return (
    <StyledSelect width={width} height={height} {...rest}>
      {children}
    </StyledSelect>
  )
}
