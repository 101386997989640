// @flow

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import {
  togglePageDialog,
  clearDevicesPageDialog,
  startClaimingSubmit
} from 'actions'

import {
  Button,
  OutlineButton,
  Input,
  Form
} from 'components/ReUsable'

import { SERIAL_NUMBER_SCREEN_STEP } from 'appConstants'

type StepOneStateProps = {
  devices: {
    pageDialog: {
      currentStep: number,
      currentUserStep: number,
      lastStep: number,
      requestErrorMessage: string
    },
    startClaimingForm: {
      serialNumber: string
    }
  }
}

const selectDeviceState = (state: any): StepOneStateProps => ({
  devices: state.devices
})

export function AddEfoyStepOne () {
  const dispatch = useDispatch()
  const { devices } = useSelector(selectDeviceState)
  const { startClaimingForm } = devices
  const _startClaimingSubmit = (payload: {serialNumber: string}) => dispatch(startClaimingSubmit(payload))
  const _togglePageDialog = () => dispatch(togglePageDialog())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())

  const {
    currentUserStep, currentStep, lastStep,
    requestErrorMessage
  } = devices.pageDialog

  const formik = useFormik(
    {
      initialValues: startClaimingForm,
      onSubmit: (values) => {
        _startClaimingSubmit(values)
      }
    }
  )

  return currentStep === SERIAL_NUMBER_SCREEN_STEP ? (
    <>
      <p className='add-efoy-message'>
        Please enter the serial number of the EFOY Fuel Cell you would like to claim to your account.
        You can find the serial number on your EFOY, or read it on your operating panel or in the myEFOY App.
      </p>
      <Form id='add-efoy-serial-number-form' handleSubmit={formik.handleSubmit}>
        <Input
          type='text' id='serialNumber' name='serialNumber'
          className={`form-control efoy-password ${requestErrorMessage ? 'input-errored' : ''}`}
          placeholder='123456-1234-12345' onChange={formik.handleChange} value={formik.values.serialNumber}
        />
        <p className='field-error'>{requestErrorMessage}</p>
        {/* this section can be a isolated component */}
        <div className='d-flex justify-content-between buttons-section'>
          <OutlineButton
            type='button' id='cancel'
            color='primary'
            onClick={ () => {
              _togglePageDialog()
              _clearDevicesPageDialog()
            } }
          >
            CANCEL
          </OutlineButton>
          <Button
            type='submit' id='button-next'
            color='primary'
            disabled={!formik.values.serialNumber}
          >
            NEXT
          </Button>
        </div>
      </Form>
      {/* this section can be a isolated component */}
      <p className='modal-step'>Step {currentUserStep} of {lastStep}</p>
    </>
  ) : null
}

export default AddEfoyStepOne
