// @flow

import { useEffect } from 'react'
import styled from 'styled-components'

import Slider from '@mui/material/Slider'
import { styled as muiStyled } from '@mui/styles'

import { getPropertyTitle } from 'helpers/battery'
import { NumberInput } from 'components/ReUsable'
import { roundedNumber } from 'utils/helpers'

const SectionTitle = styled.p`
  color: var(--color-dark);
  font-size: 18px;
  font-family: open-sans-bold;
  font-weight: bold;
  line-height: 24px;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;

  @media (prefers-color-scheme: dark) {
    color: var(--color-text);
  }
`

const SectionText = styled.span`
  color: var(--color-dark);
  font-size: 18px;
  font-family: open-sans-regular;
  line-height: 24px;
  height: 100px;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    color: var(--color-text);
  }
`

const InputsSection = styled.div`
  padding-top: 10px;
  width: 100%;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  @media (max-width: 1200px) {
    justify-content: space-evenly;
    align-items: baseline;
  }
`

const DefaultSectionForMobile = styled.div`
  margin-top: 10px;
  font-size: 10px;
`

// TODO: maybe it is possible to get dynamic width using sass?
// or we can also use js to do it ...
const MinMarker = styled.span`
  color: var(--color-text);
  font-size: 18px;
  font-family: digital-serial;
  width: 100px;
  display: inline-block;
`

const MaxMarker = styled.span`
  color: var(--color-text);
  font-size: 18px;
  font-family: digital-serial;
  width: 115px;
  display: inline-block;
`

const NotAvailabe = styled.div`
  color: var(--color-text);
  font-size: 18px;
  font-family: digital-serial;
  margin-top: 10px;
  margin-bottom: 10px;
`

const CustomBatterySlider = muiStyled(Slider)(() => ({
  '&.MuiSlider-root': {
    color: 'var(--color-primary)',
    height: 12,
    width: 400,
    top: -5,
    marginRight: 20,
    marginLeft: 20
  },
  '& .MuiSlider-thumb': {
    height: 19,
    width: 19,
    backgroundColor: 'var(--color-lightest)',
    border: '1px solid var(-color-table-border)',
    marginTop: 0,
    marginLeft: 0,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit'
    }
  },
  '& .MuiSlider-track': {
    height: 8
  },
  '& .MuiSlider-rail': {
    height: 8,
    backgroundColor: '#d2d2d2'
  },
  '& .MuiSlider-mark': {
    marginLeft: -6
  }
}))

function getPropertySymbol (itemName: string) {
  switch (itemName) {
    case 'switchOnVoltage':
    case 'switchOffVoltage':
    case 'deepDischargeProtectionVoltage':
    case 'beginOfChargeVoltage':
    case 'endOfChargeVoltage':
      return 'V'
    case 'cutOffCurrent':
      return 'A'
    case 'capacity':
      return 'Ah'
    case 'cutOffTime':
    case 'minimumChargeTime':
      return 'min'
    case 'maximumChargeTime':
      return 'h'
    case 'switchOnDelay':
      return 's'
    case 'deepDischargeProtectionSoc':
    case 'deepDischargeProtectionSOC':
    case 'maximumStateOfCharge':
    case 'maximumDepthOfDischarge':
    case 'minimumStateOfCharge':
      return '%'
    default:
      return ''
  }
}

function getPropertyText (itemName: string) {
  switch (itemName) {
    case 'capacity':
      return 'Total capacity of the battery/batteries connected.'
    case 'switchOnVoltage':
      return (
        `The EFOY Fuel Cell switches on automatically when the battery voltage drops below the switch-on voltage.
        The switch-on delay is taken into account.`
      )
    case 'switchOffVoltage':
      return (
        `The EFOY Fuel Cell charges the battery until the battery voltage reaches the switch-off voltage and then switches off.
        The duration of the shutdown depends on the cut-off current and the cut-off time.`
      )
    case 'cutOffCurrent':
      return (
        `If the switch-off voltage is reached, the charging current is reduced. If the charging current falls below the set cut-off current,
        the EFOY Fuel Cell switches off. The duration of the switch-off depends on the cut-off time in addition to the cut-off current.`
      )
    case 'cutOffTime':
      return (
        `If the switch-off voltage has been reached and the cut-off current is not reached within the cut-off time, the EFOY Fuel Cell switches
        off when the switch-off time is reached.`
      )
    case 'maximumChargeTime':
      return 'If the switch-off voltage is not reached within the max. charging time, the EFOY Fuel Cell switches off.'
    case 'minimumChargeTime':
      return (
        `The EFOY Fuel Cell calculates the average duration of a charging cycle. If this value falls below the min. Charging time is an
        indication of a defective, too old or too small battery. It could also indicate incorrectly set battery parameters.
        Persistent short charging cycles can damage the EFOY Fuel Cell or the battery.`
      )
    case 'switchOnDelay':
      return (
        `If the battery voltage falls below the switch-on voltage or the state of charge falls below the max. switch-on charge level
        for the time of the switch-on delay the EFOY Fuel Cell starts automatically.`
      )
    case 'deepDischargeProtectionSOC':
      return (
        'For the sleep mode the battery protection provides deep discharge protection for the battery.'
      )
    case 'deepDischargeProtectionVoltage':
      return (
        `For the sleep mode the battery protection provides deep discharge protection for the battery.
        If the battery voltage falls below the battery protection voltage the EFOY Fuel Cell charges the battery until the switch-off
        voltage is reached.`
      )
    case 'maximumDepthOfDischarge':
    case 'minimumStateOfCharge':
      return 'The EFOY Fuel Cell switches on automatically when the state of charge falls below the switch-on charge level. The switch-on delay is taken into account.'
    case 'maximumStateOfCharge':
      return (
        `The EFOY Fuel Cell charges the EFOY battery until the charge level reaches the switch-off state of charge level and then switches off.
        The duration of the shutdown depends on the cut-off current and the cut-off time.`
      )
    case 'beginOfChargeVoltage':
      return (
        `The EFOY Fuel Cell switches on automatically when the battery voltage drops below the switch-on voltage.
        The switch-on delay is taken into account.`
      )
    case 'endOfChargeVoltage':
      return (
        `The EFOY Fuel Cell charges the battery until the battery voltage reaches the switch-off voltage and then switches off.
        The duration of the shutdown depends on the cut-off current and the cut-off time.`
      )
    default:
      return ''
  }
}

export interface IBatteryItem {min: number, max: number, default: number, readOnly: boolean, current: number}

export function getConvertedUnitValues (itemName: string, batteryItem: IBatteryItem) {
  const divisorVal = calculateDivisorValue(itemName)

  if ((!batteryItem.min || !batteryItem.max) && batteryItem.min !== 0) {
    return { ...batteryItem, current: batteryItem.current / divisorVal }
  }

  return { ...batteryItem, min: batteryItem.min / divisorVal, max: batteryItem.max / divisorVal, default: batteryItem.default / divisorVal, current: batteryItem.current / divisorVal }
}

export function calculateDivisorValue (itemName: string) {
  let divisorVal = 1
  switch (itemName) {
    case 'cutOffTime':
    case 'minimumChargeTime':
      divisorVal = 60
      break
    case 'maximumChargeTime':
      divisorVal = 60 * 60
      break
    default:
      divisorVal = 1
      break
  }

  return divisorVal
}

export function convertedUnitValueBack (itemName: string, value: number) {
  switch (itemName) {
    case 'cutOffTime':
    case 'minimumChargeTime':
      return value * 60
      break
    case 'maximumChargeTime':
      return value * 60 * 60
    default:
      return value
  }
}

type Props = {
  index: number,
  itemName: string,
  value: number,
  disabled: boolean,
  batteryItem: IBatteryItem,
  formik: any,
  resetTrigger: number,
  handleFormEdited: () => void
}

const InputWrapper = styled.div`
  display: none !important;
  width: none;

  @media (min-width: 1200px) {
    display: inline-flex !important;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  & > input {
    font-family: digital-serial;
    font-size: 26px;
    border: 1px solid rgb(135, 134, 130);
    border-radius: 4px;
    outline: none;
    width: 120px;
    line-height: 40px;
    height: 44px;
    background: var(--color-background-primary);
    padding: 2px;

    &:hover {
      border: 2px solid rgb(135, 134, 130);
    }

    &:focus {
      border: 2px solid rgb(101, 179, 48);
    }

  }

  & > p {
    font-family: digital-serial;
    font-size: 26px;
    line-height: 40px;
    min-width: 60px;
    width: 60px;
    margin-left: 10px;
    margin-bottom: 0;
  }
`

const InputWrapperMobile = styled.div`
  display: none !important;
  width: none;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
  }

  div > input {
    font-family: digital-serial;
    font-size: 18px;
    border: 1px solid rgb(135, 134, 130)
    border-radius: 4px;
    outline: none;
    width: 80px;
    line-height: 26px;
    height: 36px;
    background: var(--color-background-primary);
    padding: 2px;
  }

  div > p {
    font-family: digital-serial;
    font-size: 18px;
    line-height: 26px;
    min-width: 30px;
    width: 30px;
    margin-left: 6px;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    width: 135px;
    display: inline-flex !important;
  }

  @media (max-width: 991px) {
    width: 135px;
    display: inline-flex !important;
  }
`

const BatteryVoltageSection = (props: Props) => {
  const { resetTrigger, formik, batteryItem, value, handleFormEdited } = props

  //  Set initial value for the first time.
  //  Do it only if type has been changed.
  useEffect(() => {
    formik.setFieldValue(props.itemName, getValidValue(value))
  }, [formik?.values?.type])

  const getValidValue = (value: any) => {
    return value || null
  }

  const handleSliderChange = (event: any, value: any) => {
    //  Do not allow not integer values if none of these properties has a floating number
    if (Number.isInteger(batteryItem.min) && Number.isInteger(batteryItem.max) && Number.isInteger(batteryItem.default) && Number.isInteger(batteryItem.current)) {
      value = Math.round(value)
    }
    formik.setFieldValue(props.itemName, getValidValue(value))
    handleFormEdited()
  }

  const handleInputChange = (value: string) => {
    formik.setFieldValue(props.itemName, getValidValue(value))
    handleFormEdited()
  }

  /*
  * The ids of the marks get rendered based on there value
  * in case the first mark object is on the same field as the minimal slider position
  * the rendered component throws an error.
  * by selecting an a lower value this error does not rise
  * */
  const getMarks = (batteryItem: any) => {
    return [
      { value: batteryItem.min - 1 },
      { value: batteryItem.default }
    ]
  }

  useEffect(() => {
    if (resetTrigger > 0) {
      formik.setFieldValue(props.itemName, getValidValue(batteryItem.default))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTrigger])

  const propSymbol = getPropertySymbol(props.itemName)
  return (
    <>
      <SectionTitle>{ getPropertyTitle(props.itemName) }</SectionTitle>
      <SectionText>{ getPropertyText(props.itemName) }</SectionText>
      { value
      ? <InputsSection>
          <MinMarker>Min. { roundedNumber(batteryItem.min || 0, 2) } { getPropertySymbol(props.itemName) }</MinMarker>
          <CustomBatterySlider
            id={ `${props.itemName}-slider` } name={ props.itemName } valueLabelDisplay='off' aria-label={ `input-slider${props.index}` } step={ 0.1 }
            min={ +roundedNumber(batteryItem.min || 0, 2) } max={ +roundedNumber(batteryItem.max || 0, 2) }
            marks={ getMarks(batteryItem) }
            value={ Number(formik?.values[props.itemName]) } onChange={ handleSliderChange } disabled={ props.disabled }
            style={{ top: '0px' }}
          />
          <InputWrapperMobile>
            <div>
            <NumberInput
                id={ `${props.itemName}-mobile-view` }
                name={ props.itemName }
                value={formik?.values[props.itemName]}
                onChange={ (v: string) => handleInputChange(v) }
                disabled={ props.disabled }
                aria-labelledby={`input-slider${props.index}`}
                decimalLimit={propSymbol === 'min' || propSymbol === 'h' || propSymbol === 's' ? 0 : 2}
              />
              <p>{ propSymbol }</p>
            </div>
            <DefaultSectionForMobile>{ batteryItem.default } { getPropertySymbol(props.itemName) } (Default)</DefaultSectionForMobile>
          </InputWrapperMobile>
          <MaxMarker>Max. { roundedNumber(batteryItem.max || 0, 2) } { getPropertySymbol(props.itemName) }</MaxMarker>
          <InputWrapper>
            <NumberInput
                id={ `${props.itemName}` }
                name={ props.itemName }
                value={formik?.values[props.itemName]}
                onChange={ (v: string) => handleInputChange(v) }
                disabled={ props.disabled }
                aria-labelledby={`input-slider${props.index}`}
                decimalLimit={propSymbol === 'min' || propSymbol === 'h' || propSymbol === 's' ? 0 : 2}
              />
            <p>{ propSymbol }</p>
          </InputWrapper>
        </InputsSection>
      : <NotAvailabe>Value is not available.</NotAvailabe>
      }
    </>
  )
}

export default BatteryVoltageSection
