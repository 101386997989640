// @flow

import moment from 'moment'
import styled from 'styled-components'

const StyledTimeStamp = styled.span`
  color: var(--color-text);
`

type Props = {
  data?: any,
  property?: string,
}

export default function ShowTimeStamp (props: Props) {
  const { data, property } = props

  if (data && property && data[property]) {
    return <StyledTimeStamp>{`${moment(data[property]).format('DD.MM.YY HH:mm')} h`}</StyledTimeStamp>
  }

  return null
}
