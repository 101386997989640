// @flow

import styled from 'styled-components'

type Props = {
  selected: boolean,
  size?: string
};

const StyledContainer = styled.div<{size?: any}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: ${(props: any) => props.size ? props.size : '20px' };
  width: ${(props: any) => props.size ? props.size : '20px' };
  border: 1px solid var(--color-background-light);
`
const StyledContent = styled.div<{selected?: boolean, size?: number}>`
  width: ${(props: any) => props.size ? props.size * 0.6 : '12px' };
  height: ${(props: any) => props.size ? props.size * 0.6 : '12px' };
  border-radius: 50%;
  background: ${(props: any) =>
    props.selected ? 'var(--color-primary)' : 'transparent'};
`

export default function RadioButton (props: Props) {
  const { selected, size } = props

  return (
    <StyledContainer size={size}>
      <StyledContent selected={selected}/>
    </StyledContainer>
  )
}
