// @flow

import styled from 'styled-components'

type Props = {
  count: number,
  margin?: string
};

const StyledSelectedEfoys = styled.p`
  margin-bottom: 0;
  color: var(--color-text);
  margin-top: 1px;
  margin: ${(props: any) => props.margin ? props.margin : '0 0 0 0'};
  span {
    color: var(--color-primary);
  }
  @media (max-width: 991px) {
    display: none;
  }
`

export default function SelectedEfoy (props: Props) {
  const { count, margin } = props

  return (
    <StyledSelectedEfoys {...{ margin: margin }}
     data-cy="selected-efoy-counter">
      Selected EFOYs: <span>{count}</span>
    </StyledSelectedEfoys>
  )
}
