// @flow

import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

// TODO: move to a folder of only types
import { ButtonCancel, DataTable, SelectedEfoy } from 'components/ReUsable'
import Form from 'components/ReUsable/Form'
import Info from 'components/Application/DevicesTable/info'
import { useDispatch, useSelector } from 'react-redux'
import DelegationButtons from './DelegationButtons'
import ClusterLegend from '../ClusterLegend'
import { applyDeviceFilter } from 'actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FilterDeviceColumns from 'components/Application/CommonTable/filterDeviceColumns'
import { NEW_API_ACTIVE } from 'featureToggles'

const TopPaginationDiv = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  justify-content: space-between;
`

const StyledTopPaginationLeftBlock = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: no-wrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap-reverse;
    max-width: 760px;
  }  
`

const StyledTopPaginationRightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const BottomPaginationDiv = styled.div<{newApi: boolean}>`
  display: flex;
  flex-direction:   ${props => props.newApi ? 'column-reverse' : 'row'};
  justify-content: ${props => props.newApi ? 'flex-end' : 'space-between'};;
  @media (min-width: 1200px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
`

const StyledSpan = styled.span`
  cursor: pointer;
  padding: 3px 5px 0 5px;
  color: var(--color-text);
`

const StyledSpanForInput = styled.span`
  padding: 0 5px 0 5px;
  color: var(--color-text);
  font-size: 13px;
`

const StyledInput = styled.input`
  border-radius: 0.25rem;
  text-align: center;
  width: 30px;
  border: var(--color-table-border) 1px solid;
  background-color: var(--color-background-secondary);
  color: var(--color-text);
  font-size: 13px;
`

const StyledPaginationAndClusterLegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 991px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const StyledDelegationButtonsContainer = styled.div`
  max-width: 720px;
  justify-content: flex-start;
  margin: 0;
  margin-right: 1rem;

  button {
    min-width: 8rem;
  }
`

const StyledSimpleBottomPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: .5rem;
`

const StyledClearFilterIcon = styled(FontAwesomeIcon)`
  color: var(--color-darkest);
  cursor: pointer;
`

// TODO: it would be nice if we have a fixed page numbers
// This is a temp hack because, we get the first page as 0 but is actually 1
const getHackedPage = (page: any) => (page === 0 ? 1 : page)

type FormProps = {
  page: number
  totalPages: number
  firstPage: boolean
  lastPage: boolean
  formik:
    | {
        values: {
          page: number
        }
        handleSubmit: () => void
        setFieldValue: (obj1?: any, obj?: any) => void
        handleChange: () => void
      }
    | any
  loading?: boolean
}
const ShowSpinner = ({ loading }: { loading: boolean | undefined }) => {
  const devices = useSelector((state: any) => state.devices)
  const { sorting, filter } = devices

  const [show, setShow] = useState(false)
  useEffect(() => {
    if ((loading && !show) || filter || sorting) {
      setShow(true)

      setTimeout(() => {
        setShow(false)
      }, 1000)
    }
  }, [loading, filter, sorting])
  return show ? <Spinner animation="border" variant="secondary" size="sm" /> : null
}

export const PaginationForm = (props: FormProps) => {
  const { firstPage, formik, page, loading } = props
  return (
    <div>
      <Form
        handleSubmit={(event) => {
          event.preventDefault()
          formik.handleSubmit()
        }}
      >
        {firstPage === false && (
          <StyledSpan
            data-cy="paginateit-previous"
            onClick={() => {
              formik.setFieldValue('page', getHackedPage(page) - 1)
              formik.handleSubmit()
            }}
          >
            {previousButton}
          </StyledSpan>
        )}
        <StyledSpanForInput>
          <StyledInput id="page" name="page" readOnly={props.totalPages === 1} max={props.totalPages} type="number" value={getHackedPage(props.formik.values.page)} onChange={props.formik.handleChange} /> of {props.totalPages}
          {loading !== undefined && <ShowSpinner loading={loading} />}
        </StyledSpanForInput>
        {props.lastPage === false && (
          <StyledSpan
            data-cy="paginateit-next"
            onClick={() => {
              props.formik.setFieldValue('page', getHackedPage(props.page) + 1)
              props.formik.handleSubmit()
            }}
          >
            {nextButton}
          </StyledSpan>
        )}
      </Form>
    </div>
  )
}

const previousButton = '<'
const nextButton = '>'

type Props = {
  page: number
  children: any | typeof DataTable
  totalPages: number
  firstPage: boolean
  lastPage: boolean
  jumpToPage: (payload: number) => void
  hideTopNavigation?: boolean
  formik?: {
    values: {
      page: number
    }
    handleSubmit: () => void
    setFieldValue: () => void
    handleChange: () => void
  }
  hideBottomNavigation?: boolean
  showLegend?: boolean
  selectionModeSupported?: boolean
  loading?: boolean
  columnSettingsVisible?: boolean
}

const PaginateIt = (props: Props) => {
  const dispatch = useDispatch()
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices.map((device: any) => device.serialNumber))
  const selectionActive = useSelector((state: any) => state.devices.selectionActive)
  const deviceFilters = useSelector((state: any) => state.devices?.filter)
  const formik = useFormik({
    initialValues: { page: getHackedPage(props.page) },
    onSubmit: (values) => {
      let pageNumber = Number(values.page)

      if (isNaN(pageNumber)) {
        pageNumber = 1
      }

      formik.setFieldValue('page', getHackedPage(pageNumber))
      props.jumpToPage(getHackedPage(pageNumber))
    }
  })

  useEffect(() => {
    formik.setFieldValue('page', getHackedPage(props.page))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page, props.loading])

  return (
    <div>
      {!props.hideTopNavigation ? (
        <TopPaginationDiv>
          <StyledTopPaginationLeftBlock>
            {props.selectionModeSupported ? (
              <StyledDelegationButtonsContainer>
                <DelegationButtons />
              </StyledDelegationButtonsContainer>
            ) : null}
            {deviceFilters && (
              <>
                <ButtonCancel data-cy="clear-device-filters" style={{ marginLeft: '1rem' }} onClick={() => dispatch(applyDeviceFilter(null))}>
                  <span>Clear all filters</span><StyledClearFilterIcon onClick={() => dispatch(applyDeviceFilter(null))} icon={['fas', 'filter']} className='icon-dark-mode-ready' />
                </ButtonCancel>
              </>
            )}
            {props.selectionModeSupported && selectionActive && <SelectedEfoy count={selectedDevices.length} />}
          </StyledTopPaginationLeftBlock>
          <StyledTopPaginationRightBlock>
            {props.columnSettingsVisible ? <FilterDeviceColumns inSelectionMode={props.selectionModeSupported && selectionActive}/> : null}
            <PaginationForm page={props.page} totalPages={props.totalPages} firstPage={props.firstPage} lastPage={props.lastPage} formik={formik} loading={props.loading} />
          </StyledTopPaginationRightBlock>
        </TopPaginationDiv>
      ) : null}
      <div>{props.children}</div>
      {!props.hideBottomNavigation
        ? props.showLegend
        ? (
        <BottomPaginationDiv newApi={NEW_API_ACTIVE}>
          <Info />
          <StyledPaginationAndClusterLegendContainer>
            {window.location.pathname === '/' ? <ClusterLegend /> : null}
            <PaginationForm page={props.page} totalPages={props.totalPages} firstPage={props.firstPage} lastPage={props.lastPage} formik={formik} />
          </StyledPaginationAndClusterLegendContainer>
        </BottomPaginationDiv>
      ) : <StyledSimpleBottomPagination><PaginationForm page={props.page} totalPages={props.totalPages} firstPage={props.firstPage} lastPage={props.lastPage} formik={formik} /></StyledSimpleBottomPagination> : null}
    </div>
  )
}

export default PaginateIt
