// @flow

import { Row, Col, Gap } from 'components/ReUsable'
import { FilterPropName } from '../Home/defineColumns'
import FilterPopOver from './filterPopOver'
import { useDispatch, useSelector } from 'react-redux'
import { applyDeviceFilter, openDeviceFilter } from 'actions'
import FilterOperatingStatePopOver from './filterOperatingStatePopOver'
import { StyledCol, StyledTableRow } from './commonHeadingDeviceCell'
import SortCell from './sortCell'
import { NEW_API_ACTIVE } from 'featureToggles'

type Props = {
  value: string | number
  hideable?: boolean
  sortable?: boolean
  sortKey?: string
  sorting?: any
  sortByColumn?: (params: any) => void
  id?: string
  filterable?: boolean
  filterPropName?: FilterPropName
}

export default function CommonHeadingCell (props: Props) {
  const { value, hideable, sortable, sortKey, sorting, sortByColumn, id, filterable, filterPropName } = props

  const dispatch = useDispatch()
  const currentFilterValue = useSelector((state: any) => (state.devices?.filter && filterPropName ? state.devices?.filter[filterPropName] : ''))
  const filterActive = currentFilterValue && currentFilterValue.length > 0

  let className = ' vertical-centered'
  if (sortable) {
    className = className.concat(' sortable')
  }

  if (hideable) {
    className = className.concat(' d-none d-lg-table-cell')
  }

  return (
    <StyledTableRow className={className} key={id}>
      <Row style={{ flexDirection: 'row' }}>
        <Col
          onClick={() => {
            if (!NEW_API_ACTIVE && sortKey !== 'serialNumber' && sortKey !== 'name') {
              return null
            }
            if (sortable && sortByColumn) {
              sortByColumn({ sortKey, sortDirection: sorting.sortDirection === 'asc' ? 'desc' : 'asc' })
            }
          }}
          justifyContent="center"
          style={{ flexGrow: '1', flexBasis: '0' }}
        >
          {value}
        </Col>
        {filterable ? (
          <>
            <Gap width="4px" />
            <StyledCol base="1px" justifyContent="center" alignItems="center">
              {filterPropName === 'compoundState' ? (
                <FilterOperatingStatePopOver id="compoundState" onFilterOpen={() => dispatch(openDeviceFilter())}/>
              ) : (
                <FilterPopOver
                  id={filterPropName || 'filter-popover'}
                  filterActive={filterActive}
                  currentFilterValue={currentFilterValue}
                  onFilterSave={(f: string) => dispatch(applyDeviceFilter({ name: filterPropName, value: f.length > 0 ? f : null }))}
                  onFilterOpen={() => dispatch(openDeviceFilter())}
                />
              )}
            </StyledCol>
          </>
        ) : (
          <></>
        )}

        <SortCell
          onSort={() => {
            if (sortable && sortByColumn) {
              sortByColumn({ sortKey, sortDirection: sorting.sortDirection === 'asc' ? 'desc' : 'asc' })
            }
          }}
          sortKey={sortKey}
          sorting={sorting}
        />
      </Row>
    </StyledTableRow>
  )
}
