// @flow

import styled from 'styled-components'

import BatteryVoltageSection, { getConvertedUnitValues } from './batteryVoltageSection'
import { useSelector } from 'react-redux'
import { roundedNumber } from 'utils/helpers'

const EvenSection = styled.div`
  background: var(--color-table-header-border);
  border: 1px solid var(--color-table-border);
  border-radius: 3px;
  margin-bottom: 0;
  padding: 10px;
  position: relative;
`

const OddSection = styled.div`
  background: var(--color-background-secondary);
  border: 1px solid var(--color-table-border);
  border-radius: 3px;
  margin-bottom: 0;
  padding: 10px;
  position: relative;
`

type Props = {
  resetTrigger: any,
  formik?: any,
  initialBatteryType: string,
  onFormEdited: () => void
}

const BATTERY_FIELDS_SORTING = [
  'capacity',
  'beginOfChargeVoltage',
  'endOfChargeVoltage',
  'cutOffCurrent',
  'cutOffTime',
  'maximumChargeTime',
  'minimumChargeTime',
  'switchOnDelay',
  'deepDischargeProtectionVoltage',
  'deepDischargeProtectionSOC',
  'deepDischargeProtectionSoc',
  'maximumStateOfCharge',
  'maximumDepthOfDischarge',
  'minimumStateOfCharge'
]

export default function BatteryVoltagesSections (props: Props): JSX.Element | null {
  const { resetTrigger, formik, onFormEdited } = props
  const batteryType = formik?.values?.type
  const limitsList = useSelector((state: any) => state?.battery?.limits)

  if (!limitsList) {
    return null
  }
  const selectedBatteryForm = limitsList[batteryType]

  if (!selectedBatteryForm) {
    return null
  }

  let sectionIndex = 0

  const sortedFields = Object.keys(selectedBatteryForm).sort((a, b) => BATTERY_FIELDS_SORTING.indexOf(a) - BATTERY_FIELDS_SORTING.indexOf(b)).map((itemName: string, index: number) => {
    const batteryItem = selectedBatteryForm[itemName]
    let returnedElement: JSX.Element | null = null

    if (!batteryItem) {
      return null
    }

    const convertedUnitsBatteryItem = getConvertedUnitValues(itemName, batteryItem)

    /* const value = formik.values[itemName] || (convertedUnitsBatteryItem.current || convertedUnitsBatteryItem.default) */
    const formValue = formik.values[itemName] || (convertedUnitsBatteryItem.current || convertedUnitsBatteryItem.default)
    const value = +roundedNumber(+formValue || 0, 2)

    returnedElement = (sectionIndex % 2 === 0) ? (
      <EvenSection key={ index }>
        <BatteryVoltageSection
          batteryItem={ convertedUnitsBatteryItem } itemName={ itemName } index={ index } value={ value }
          disabled={ convertedUnitsBatteryItem.readOnly } formik={ formik }
          resetTrigger={ resetTrigger }
          handleFormEdited={onFormEdited}
        />
      </EvenSection>
    ) : (
      <OddSection key={ index }>
        <BatteryVoltageSection
          batteryItem={ convertedUnitsBatteryItem } itemName={ itemName } index={ index } value={ value }
          disabled={ convertedUnitsBatteryItem.readOnly }
          formik={ formik }
          resetTrigger={ resetTrigger }
          handleFormEdited={onFormEdited}
        />
      </OddSection>
    )

    sectionIndex++
    return returnedElement
  })

  return <>{sortedFields}</>
}
