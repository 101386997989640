// @flow

import styled from 'styled-components'
import { DeviceTag, SelectedTagObj } from '../hooks/useDeviceTags'

const StyledTagListHeader = styled.h3`
  font-size: 18px;
  color: var(--color-text);
`

const StyledTagList = styled.div`
  margin-bottom: 12px;
`

const StyledButton = styled.button`
  border-radius: 0.375rem;
  padding: 4px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: transparent;
`

const DeviceTagSelectionList = ({ groupName = null, tags, onSelectTag, userSelection }: { groupName?: null | string; tags: DeviceTag[], onSelectTag: (selectedTag: SelectedTagObj) => void, userSelection: SelectedTagObj[] }) => {
  if (tags.length === 0) return null

  return (
    <StyledTagList>
      <StyledTagListHeader>{`${groupName || 'My Tags'}:`}</StyledTagListHeader>
      {tags.map((t: DeviceTag) => {
        const inUserSelection = userSelection.find((s: SelectedTagObj) => s.id === t.id)
        const isSelected: boolean | null = inUserSelection ? inUserSelection.selected : null
        const isButtonSelected = isSelected === null ? t.selected : isSelected
        const styleOfButton = isButtonSelected ? { border: `2px solid ${t.color}`, backgroundColor: t.color, color: 'var(--color-lightest)' } : { border: `2px solid ${t.color}`, color: t.color }
        return (
          <StyledButton data-cy={isButtonSelected ? 'selected' : 'not-selected'} key={t.id} style={styleOfButton} onClick={() => onSelectTag({ id: t.id, selected: !isButtonSelected })}>{t.name}</StyledButton>
        )
      })}
    </StyledTagList>
  )
}
export default DeviceTagSelectionList
