// @flow

import styled from 'styled-components'

const StyledButton = styled.button<Partial<IIconButton>>`
  position: relative;
  left: 4px;
  background: transparent;
  border: none;
  width: 36px;
  height: auto;
  padding: 0;
  
  &:disabled,
  &[disabled]{
    pointer-events: none;

    @media (prefers-color-scheme: dark) {
      opacity: .5;
    }
  }

  &:hover, &:focus {
    opacity: 0.9;
  }

  & > img {
    width: ${props => props.width || '36px'};
  }
`

interface IIconButton {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  src: string;
  alt?: string;
  disabled?: boolean;
  dataCy?: string;
  width?: string;
  style?: React.CSSProperties;
}

export default function IconButton ({ onClick, disabled, alt, src, dataCy, width, style }: IIconButton) {
  return (
    <StyledButton { ...(style ? { style } : {})} width={width} { ...(dataCy ? { 'data-cy': dataCy } : {})} onClick={onClick} { ...(disabled ? { disabled: true } : {}) }>
      <img alt={alt} src={`/imgs/${src}${disabled ? '-grey' : ''}.svg`} />
    </StyledButton>
  )
}
