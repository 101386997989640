import { useEffect, useState } from 'react'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import styled from 'styled-components'
import { Button, ButtonCancel } from 'components/ReUsable'
import { NEW_API_ACTIVE } from 'featureToggles'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedDeviceColumns } from 'actions'
import { allDeviceColumnsList, defaultDeviceColumns } from 'reducers/devicesReducer'
import { changeSelectedDeviceColumns, deleteSelectedDeviceColumns } from 'apis/devices'

function getPropertyName (value: string) {
  switch (value) {
    case 'name':
      return 'Name'
    case 'compoundState':
      return 'Operating state'
    case 'fuelLevel':
      return 'Fuel level'
    case 'firmwareVersion':
      return 'Firmware'
    case 'batteryVoltage':
      return 'Voltage / SOC'
    case 'stackOperationTime':
      return 'Operating hours'
    case 'serialNumber':
      return 'Serial number'
    case 'licenseType':
      return 'Current license type'
    case 'licenseExpire':
      return 'Final license expire'
    case 'accessType':
      return 'Access type'
    default:
      return value
  }
}

const StyledPopOverContent = styled.div`
  padding: 10px;
  padding-top: 16px;
  min-width: 360px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`

const StyledSaveButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
`

const StyledCheckBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  border-radius: 2px;
  border: 1px solid var(--color-border-light);
  padding: 4px;
  margin-bottom: 11px;
  padding-right: 0;
  flex-grow: 1;
`

const StyledInputContainer = styled.div`
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
`

export const StyledInput = styled.input`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0;
  background-color: var(--color-checkbox-default-background);
  &:checked {
    background-color: var(--color-checkbox-background);
    border-color: var(--color-checkbox-background);
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(46, 47, 48, 0.1);
  }
`

const StyledLabel = styled.span`
  padding-left: 3rem;
  text-align: left;
  font-family: open-sans-regular;
  color: var(--color-table-text);
  font-weight: bold;
  font-size: 1rem;
  flex-grow: 1;

  outline: none !important;
  cursor: pointer;

  &::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }
`

const StyledInfo = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
  font-family: open-sans-regular;
  font-size: 11px;
  color: var(--color-text);
`

const StyledDragDropButton = styled.button`
  border: none;
  background: none;
  pointer: cursor;
`

const StyledButton = styled(Button)`
  display: none;
  gap: 5px;
  margin: 0;
  margin-right: .5rem;
  max-height: 30px;
  min-height: 30px;
  font-size: 12px;
  text-transform: lowercase;
  padding: 0;
  min-width: 140px;
  margin-bottom: 2.8px;

  @media (min-width: 992px) {    
    display: flex;
  }

  &.device-selection-active {
    @media (min-width: 991px) {
      min-width: 40px;
      & > span {
        display: none;
      }
    }
    
    @media (min-width: 1200px) {
      min-width: 140px;
      & > span {
        display: block
      }
    }
  }

  span {
    text-transform: uppercase;
  }
`

export default function FilterDeviceColumns ({ inSelectionMode } : { inSelectionMode: boolean }) {
  const dispatch = useDispatch()
  const currentFilterValues = useSelector((state: any) => state.devices.selectedColumns)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [filterList, setFilterList] = useState<string[]>(currentFilterValues)
  const [deviceColumnsListState, setDeviceColumnsListState] = useState<string[]>(
    allDeviceColumnsList.sort((a: string, b: string) => {
      if ((currentFilterValues.indexOf(a) > -1 && currentFilterValues.indexOf(b) > -1)) {
        return currentFilterValues.indexOf(a) > currentFilterValues.indexOf(b) ? 1 : -1
      } else if (currentFilterValues.indexOf(a) > -1) {
        return -1
      } else if (currentFilterValues.indexOf(b) > -1) {
        return 1
      } else {
        return getPropertyName(a) > getPropertyName(b) ? 1 : -1
      }
    })
  )

  if (!NEW_API_ACTIVE) {
    return null
  }

  //  Reset state on open again.
  useEffect(() => {
    if (anchorEl) {
      setFilterList(currentFilterValues)
      setDeviceColumnsListState(allDeviceColumnsList.sort((a: string, b: string) => {
        if (currentFilterValues.indexOf(a) > -1 && currentFilterValues.indexOf(b) > -1) {
          return currentFilterValues.indexOf(a) > currentFilterValues.indexOf(b) ? 1 : -1
        } else if (currentFilterValues.indexOf(a) > -1) {
          return -1
        } else if (currentFilterValues.indexOf(b) > -1) {
          return 1
        } else {
          return getPropertyName(a) > getPropertyName(b) ? 1 : -1
        }
      }))
    }
  }, [anchorEl])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCheck = (val: string) => {
    const index = filterList.indexOf(val)
    if (index === -1) {
      if (filterList.length === 7) {
        return
      }
      setFilterList([...filterList, val])
    } else {
      setFilterList(filterList.filter((s: string) => s !== val))
    }
  }

  const handleSave = () => {
    const selectedList = deviceColumnsListState.filter((s: string) => filterList.indexOf(s) > -1)
    dispatch(updateSelectedDeviceColumns({ selectedList }))
    changeSelectedDeviceColumns(selectedList)
    handleClose()
  }

  const handleCancel = () => {
    dispatch(updateSelectedDeviceColumns({ selectedList: defaultDeviceColumns }))
    deleteSelectedDeviceColumns()
    handleClose()
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setDeviceColumnsListState((items: string[]) => {
        const oldIndex = items.findIndex((item) => item === active.id)
        const newIndex = items.findIndex((item) => item === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <StyledButton onClick={handleOpen} className={`${inSelectionMode ? 'device-selection-active' : ''}`} data-cy="select-columns">
        <img alt="Column Settings" src="/imgs/settings-white.svg" />
        <span >column settings</span>
      </StyledButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        data-cy="select-column-modal"
      >
        <ClickAwayListener onClickAway={handleSave}>
          <StyledPopOverContent>
            <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
              <SortableContext items={deviceColumnsListState}>
                {deviceColumnsListState.map((colId: string, index: number) => {
                  return <FilterDeviceColumnItem key={`${colId}##${index}`} value={colId} index={index} handleChange={handleCheck} checked={filterList.indexOf(colId) > -1} />
                })}
              </SortableContext>
            </DndContext>
            <StyledInfo>{`Select ${filterList.length} of 7 columns`}</StyledInfo>
            <StyledButtonContainer>
              <ButtonCancel onClick={handleCancel} data-cy="cancelFilter">
                Reset
              </ButtonCancel>
              <StyledSaveButton onClick={handleSave} data-cy="saveFilter">
                Apply Filter
              </StyledSaveButton>
            </StyledButtonContainer>
          </StyledPopOverContent>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

function FilterDeviceColumnItem ({ value, index, checked, handleChange }: { value: string; index: number; checked: boolean; handleChange: (val: string) => void }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <StyledCheckBoxRow style={{ ...style, opacity: checked ? '1' : '.6' }} ref={setNodeRef}>
      <StyledInputContainer>
        <StyledInput
          type="checkbox"
          className="form-check-input"
          id={`${value}##${index}`}
          value={value}
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        />
      </StyledInputContainer>
      <StyledLabel {...attributes} {...listeners}>
        {getPropertyName(value)}
      </StyledLabel>
      <StyledDragDropButton {...attributes} {...listeners}>
        <img alt="Drag and Drop" src="/imgs/dragdrop.svg" />
      </StyledDragDropButton>
    </StyledCheckBoxRow>
  )
}
