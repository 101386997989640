// @flow
import { useEffect } from 'react'
import styled from 'styled-components'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { useMediaQuery } from '@mui/material'

import { Button, Row } from 'components/ReUsable'
import { IOPort, IOPortForm } from '../hooks/useMultisense'
import { useFormik } from 'formik'
import SelectElement from 'components/ReUsable/SelectElement'
import { fillObject } from './index'
import { StyledCheckbox } from 'components/Application/Alerts/alertconfigForm'
import { useTheme } from '@mui/styles'
import MinMaxNumberInput from 'components/ReUsable/MinMaxNumberInput'

const StyledFormPage = styled.div`
  border: #BEBDBD 1px solid;
  border-radius: var(--dim-border-radius);
  padding: 1rem;
`

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  min-width: 100%;
  display: flex;

  & > select {
    border-radius: var(--dim-border-radius);
  }
  & > label {
    font-family: open-sans-regular;
  }

  & > select, & > select > option, & > input[type=number] {
    font-family: DigitalSerialBold, system-ui;
    color: var(--color-text);
  }

  @media (min-width: 991.98px) {
    align-items: center;
    & > label {
      flex: 0 0 34%;
    }

    & > select,
    & > .btn-group {
      flex: 0 0 66%;
      margin: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const StyledSubHeader = styled.p`
  margin: 1rem 0;
  font-family: open-sans-regular;
`

const StyledErrorOutputContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;

  input {
    flex-grow: 0;
  }

  div.btn-group {
    flex-grow: 1;
  }
`

const StyledInputContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  font-family: open-sans-regular;
  width: 100%;

  input {
    border: 1px solid var(--color-border-light);
    height: 40px;
    border-radius: var(--dim-border-radius);
    flex-grow: 1;

    &:focus {
      outline: 0px;
      box-shadow: rgba(101, 179, 48, 0.25) 0px 0px 0px 0.2rem;
    }
  }

  @media (prefers-color-scheme: dark) {
    input:focus {
      box-shadow: 0 0 0 0.25rem rgba(150, 150, 151, 0.25);
    }
  }
`

const StyledSmall = styled.small`
  font-size: 10px;
  @media (min-width: 991.98px) {
    position: relative;
    top: -5px;
  }
`

export function IOFormPage ({ portKey, portConfig, onSubmit }: { portKey: string; portConfig: IOPort | null; onSubmit: (portKey: string, values: IOPortForm) => void }) {
  const formik = useFormik({
    initialValues: fillObject(portKey, portConfig) as IOPortForm,
    onSubmit: (values: IOPortForm) => {
      let submitValues = { ...values }

      if (submitValues.direction === 'input') {
        submitValues = { ...submitValues, value: false, currentLimit: 0.0, errorOutput: false, errorOutputEnabled: false, defaultOutput: false }
      }
      formik.resetForm({ values: submitValues })
      onSubmit(portKey, submitValues)
    }
  })

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    formik.resetForm({ values: fillObject(portKey, portConfig) as IOPortForm })
  }, [portKey, portConfig])

  const portDisabled = !formik.values.enabled || formik.values.enabled.toString() !== 'true'

  return (
    <>
      <form id="multisense-config-form" onSubmit={formik.handleSubmit}>
        <StyledFormPage>
          <StyledRow>
            <label>Port</label>
            <ToggleButtonGroup name="enabled" type="radio" value={formik.values.enabled.toString()}>
              <ToggleButton variant="light" id="port-enabled-activated" value={true.toString()} onChange={formik.handleChange}>
                ACTIVATED
              </ToggleButton>
              <ToggleButton variant="light" id="port-enabled-deactivated" value={false.toString()} onChange={formik.handleChange}>
                DEACTIVATED
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledRow>
          <StyledRow>
            <label>Mode</label>
            <ToggleButtonGroup name="direction" type="radio" value={formik.values.direction}>
              <ToggleButton variant="light" id="port-direction-activated" value="input" onChange={formik.handleChange} disabled={portDisabled}>
                INPUT
              </ToggleButton>
              <ToggleButton variant="light" id="port-direction-deactivated" value="output" onChange={formik.handleChange} disabled={portDisabled}>
                OUTPUT
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledRow>
          <StyledRow>
            <label>Function</label>
            <SelectElement height="42px" name="function" value={formik.values.function} onChange={formik.handleChange} disabled={portDisabled}>
              <>
                <option value="not_configured">No Label</option>
                {formik.values.direction === 'input' ? (
                  <>
                    <option value="custom">Custom</option>
                    <option value="door">Door</option>
                    <option value="fuel_sensor">Fuel Sensor</option>
                  </>
                ) : (
                  <>
                    <option value="custom">Custom</option>
                    <option value="fan">Fan</option>
                    <option value="light">Light</option>
                    <option value="relay">Load Relay</option>
                  </>
                )}
              </>
            </SelectElement>
          </StyledRow>
          {formik.values.direction === 'output' ? (
            <StyledRow>
              <label>Output value</label>
              <ToggleButtonGroup name="value" type="radio" value={formik.values.value.toString()}>
                <ToggleButton variant="light" id="port-value-activated" value={true.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                  ON/HIGH/OPEN
                </ToggleButton>
                <ToggleButton variant="light" id="port-value-deactivated" value={false.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                  OFF/LOW/CLOSED
                </ToggleButton>
              </ToggleButtonGroup>
            </StyledRow>
          ) : null}
        </StyledFormPage>
        <StyledSubHeader>Advanced configuration: </StyledSubHeader>
        <StyledFormPage>
          <StyledRow>
            <label>Function Logic</label>
            <ToggleButtonGroup name="inverted" type="radio" value={formik.values.inverted.toString()}>
              <ToggleButton variant="light" id="port-inverted-activated" value={false.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                DEFAULT
              </ToggleButton>
              <ToggleButton variant="light" id="port-inverted-deactivated" value={true.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                INVERTED
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledRow>

          {formik.values.direction === 'output' ? (
            <>
              <StyledRow>
                <label>
                  Current limit {isDesktop && <br/>}<StyledSmall>0-1000 mA</StyledSmall>
                </label>
                <StyledInputContainer>
                  <MinMaxNumberInput name="currentLimit" min={0} max={1000} value={formik.values.currentLimit} onChange={formik.handleChange} disabled={portDisabled} />
                  <span>mA</span>
                </StyledInputContainer>
              </StyledRow>
              <StyledRow>
                <label>Default after restart</label>
                <ToggleButtonGroup name="defaultOutput" type="radio" value={formik.values.defaultOutput.toString()}>
                  <ToggleButton variant="light" id="port-defaultOutput-activated" value={true.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                    ON/HIGH/OPEN
                  </ToggleButton>
                  <ToggleButton variant="light" id="port-defaultOutput-deactivated" value={false.toString()} onChange={formik.handleChange} disabled={portDisabled}>
                    OFF/LOW/CLOSED
                  </ToggleButton>
                </ToggleButtonGroup>
              </StyledRow>
              <StyledRow>
                <label>
                  Use output value {isDesktop && <br/>}in case of error
                </label>
                <StyledErrorOutputContainer>
                  <StyledCheckbox
                    name="errorOutputEnabled"
                    type="checkbox"
                    className="form-check-input"
                    checked={formik.values.errorOutputEnabled}
                    onChange={formik.handleChange}
                    disabled={portDisabled}
                  />
                  <ToggleButtonGroup name="errorOutput" type="radio" value={formik.values.errorOutput.toString()}>
                    <ToggleButton variant="light" id="port-errorOutputEnabled-activated" value={true.toString()} onChange={formik.handleChange} disabled={portDisabled || !formik.values.errorOutputEnabled}>
                      ON/HIGH/OPEN
                    </ToggleButton>
                    <ToggleButton variant="light" id="port-errorOutputEnabled-deactivated" value={false.toString()} onChange={formik.handleChange} disabled={portDisabled || !formik.values.errorOutputEnabled}>
                      OFF/LOW/CLOSED
                    </ToggleButton>
                  </ToggleButtonGroup>
                </StyledErrorOutputContainer>
              </StyledRow>
            </>
          ) : null}
        </StyledFormPage>
        <StyledButtonBar>
          <Button type="submit" id="multisense-submit-button">
            Save changes
          </Button>
        </StyledButtonBar>
      </form>
    </>
  )
}
