export const SOMETHING_WENT_WRONG = 'Something went wrong.'

export const UPDATE_SUCCESSFULL = 'Data saved successfully.'

export const DEVICE_NOT_FOUND_ERROR = 'Please check the serial number of your EFOY and/or the internet connection.'
export const DEVICE_CLAIM_EXPIRED_ERROR = 'Your claiming key has already expired. Please start the claiming again.'
export const DEVICE_NOT_REACHABLE_ERROR = 'Please try again, EFOY is not reachable.'
export const DEVICE_ALREADY_CLAIMED_ERROR = 'This EFOY is already claimed.'

export const CLAIMING_PASSWORD_ERROR = 'The claiming key is incorrect. Please check your claiming key and try again.'

export const ALERT_EMAIL_ALREADY_EXISTS_ERROR = 'This email is already registered for Alert profile.'

export const INVALID_EMAIL_ADDRESS_ERROR = 'Please try again, the Email ID is not valid.'
export const IDENTITY_NOT_EXISTS = 'This email address is not registered as a user.'

export const DEVICE_OFFLINE_ERROR = 'DEVICE_OFFLINE_ERROR'

export const UNSUPPORTED_LICENSE_ERROR = 'Your current license does not support this feature. Please upgrade your license to see more information.'
export const DEVICE_OFFLINE_ERROR_MESSAGE = 'Your EFOY Fuel Cell is currently offline. Please reconnect your EFOY to the internet to be able to use all functions of the EFOY Cloud.'

export const SERVER_NOT_AVAILABLE_MESSAGE = 'Sorry, the server is currently unavailable. Please try again later.'
export const USER_OFFLINE_MESSAGE = 'You are currently offline. Please check your internet connection and try again later.'
export const FAILED_TO_FETCH = 'Failed to fetch'
export const FAILED_TO_FETCH_FF = 'NetworkError when attempting to fetch resource.'
export const FAILED_TO_FETCH_SAFARI = 'A network error occurred.'

export const GROUP_HAS_ATLEAST_ONE_ADMIN = 'A group must contain at least one Admin member.'
export const UNAUTHORIZED_ERROR = 'User is not authenticated'
export const INSUFFICIENT_USER_GROUP_PERMISSION = 'User does not have enough permissions in this group.'
export const USER_GROUP_NOT_FOUND = 'User group was not found.'
export const USER_GROUP_ALREADY_EXISTS = 'A group with this ID already exists.'

export const GROUP_VALIDATION_ERROR = 'There are one or more validation errors.'
