// @flow

import { useAuth0 } from 'utils/react_auth0_spa'
import { integrationTestRunning } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'

import { toggleDialog } from '../../../actions'
import CollapseNavBar from './collapseNavBar'
import CollapseNavBarModal from './collapseNavBarModal'

type Props = {
  hideContent?: boolean
}

export function NavBar (props: Props) {
  const dispatch = useDispatch()
  const isDialogOpen = useSelector((state: any) => state.dialog.isDialogOpen)
  const _toggleDialog = () => dispatch(toggleDialog())
  const { hideContent } = props
  const { loading, isAuthenticated } = useAuth0()
  const userHasLocalSession = localStorage.getItem('session') === 'auth-set' || process.env.npm_lifecycle_event === 'test' || integrationTestRunning(process.env.REACT_APP_ENV_MODE)
  const userIsLogged = (isAuthenticated && !loading) || integrationTestRunning(process.env.REACT_APP_ENV_MODE)

  const toggleDialogHandler = () => {
    _toggleDialog()
    window.scrollTo(0, 0)
  }

  return (
    userIsLogged &&
    userHasLocalSession && (
      <>
        <nav className={`navbar navbar-expand-lg ${isDialogOpen ? 'nav-blur-color' : ''}`}>
          <a className="navbar-brand" href="/">
            <img className={`logo-image ${isDialogOpen ? 'hide' : 'show'}`} height="55" width="115" src="/imgs/logo.svg" alt="Home" />
          </a>
          {userIsLogged && (
            <button className={`navbar-toggler ${!isDialogOpen ? 'collapsed' : ''}`} type="button" onClick={() => toggleDialogHandler()}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          )}
          {!hideContent ? <CollapseNavBar toggleDialog={toggleDialogHandler} /> : <></>}
        </nav>
        <CollapseNavBarModal toggleDialog={toggleDialogHandler} isDialogOpen={isDialogOpen} />
      </>
    )
  )
}

export default NavBar
