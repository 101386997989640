// @flow

import styled from 'styled-components'
import { PERSONAL_TAG_PLACEHOLDER, Tag, useTagContext } from './context/tagContext'
import TagItem, { TagForm } from './tagItem'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ALERT_TYPE, Alert } from 'components/ReUsable'

const StyledTagList = styled.div`
  &:not(:first-child){
    margin-bottom: 20px;
  }
`

const StyledTagItem = styled.div`
  margin-bottom: 16px;
`

const StyledGroupHeader = styled.div`
  display: flex;
  gap: 5px;

  h3 {
    color: var(--color-text);
    font-size: 20px;
  }
`

export const StyledHeader = styled.h3`
  color: var(--color-text);
  margin-bottom: 20px;
`

const StyledAddButton = styled.button`
  color: var(--color-primary);
  background-color: transparent;
  border: none;
  font-family: open-sans-regular;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }
`

const StyledAddNewContainer = styled.div`
  min-height: 35px;
  margin: 5px 0;
`

const TagList = ({ groupSlug = null, groupName = null, tags }: { groupSlug?: null | string; groupName?: null | string; tags: Tag[] }) => {
  const { apiError } = useTagContext()
  const [editModeIndex, setEditModeIndex] = useState(-1)
  const [addNew, setAddNew] = useState(false)

  useEffect(() => {
    if (editModeIndex > -1) {
      setAddNew(false)
    }
  }, [editModeIndex])

  const toggleNewTagForm = () => {
    setEditModeIndex(-1)
    setAddNew(!addNew)
  }

  return (
    <StyledTagList>
      {groupName && groupSlug ? <GroupHeader groupName={groupName} groupSlug={groupSlug} /> : <StyledHeader>My Tags:</StyledHeader>}
      {apiError.includes(groupSlug || PERSONAL_TAG_PLACEHOLDER) ? <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} /> : null }
      {tags.map((t: Tag, index: number) => {
        return (
          <StyledTagItem key={`${t}-${index}`}>
            <TagItem groupSlug={groupSlug} inEditMode={index === editModeIndex} tag={t} onEditMode={() => (editModeIndex === index ? setEditModeIndex(-1) : setEditModeIndex(index))} />
          </StyledTagItem>
        )
      })}
      {addNew ? <TagForm tag={null} groupSlug={groupSlug} onEditMode={toggleNewTagForm} /> : null}
      {!addNew && (
        <StyledAddNewContainer>
          <StyledAddButton onClick={toggleNewTagForm}>
            <FontAwesomeIcon icon={['far', 'circle-plus'] as any} />
            <span>add tag</span>
          </StyledAddButton>
        </StyledAddNewContainer>
      )}
    </StyledTagList>
  )
}
export default TagList

function GroupHeader ({ groupSlug, groupName }: { groupSlug: string; groupName: string }) {
  return (
    <StyledGroupHeader>
      <h3>{groupName}</h3>
      <h3 style={{ color: 'var(--color-text-muted)' }}>{`(${groupSlug})`}</h3>
    </StyledGroupHeader>
  )
}
