// @flow

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  DelegationResponse,
  DelegationItemResponse
} from '../hooks/useBatchEditDelegation'
import { Text, PageSubTitle, Card } from 'components/ReUsable'

export const StyledCard = styled(Card)`
  position: relative;
  border: 1px solid var(--color-border-light);
  border-radius: 0.4rem;

  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`

const StyledCancelButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  color: var(--color-text);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid var(-color-table-border);
  padding: 0;

  @media (min-width: 991.98px) {
    bottom: 1.5rem;
    right: 1.5rem;
  }

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid var(--color-text);
    filter: brightness(0.8);
  }
`

export const StyledResultHeader = styled(PageSubTitle)`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 14px;
`

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  justify: content: flex-start;
  margin-bottom: 0.2rem;
`

export const StyledSuccess = styled.span`
  margin-left: 0.3rem;
  color: var(--color-primary);
`

export const StyledError = styled.span`
  margin-left: 0.3rem;
  color: var(--color-alert-error);
`

const StyledDeviceName = styled.span`
  display: inline-block;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DelegationResultUI = function ({
  delegationResponse,
  backLink,
  mode = ''
}: {
  delegationResponse: DelegationResponse,
  backLink: string,
  mode: string,
}) {
  const history = useHistory()
  const totalDelegationsCount = delegationResponse.delegations.length
  const successfulDelegationsCount = delegationResponse.delegations.filter(
    (d) => d.success
  ).length

  return (
    <StyledCard>
      <StyledResultHeader data-cy="delegation-result-header">{`Results for ${mode.toLowerCase()} User/Group ${
        delegationResponse.identity
      }`}</StyledResultHeader>
      <Text>
        <span
          style={{ fontWeight: 'bold' }}
        >{`${successfulDelegationsCount}`}</span>{' '}
        {`of ${totalDelegationsCount} successful`}
      </Text>
      {delegationResponse.delegations.map(
        (d: DelegationItemResponse, index: number) => {
          if (d.success) {
            return (
              <StyledText key={`del-item-${index}`}>
                <span style={{ marginRight: '0.3rem' }}>{d.deviceSerialNumber}</span>
                {d.deviceName ? (
                  <>
                    (
                    <StyledDeviceName
                      title={d.deviceName}
                    >{`${d.deviceName}`}</StyledDeviceName>
                    )
                  </>
                ) : (
                  <></>
                )}
                <StyledSuccess>success</StyledSuccess>
              </StyledText>
            )
          } else {
            const errorMsg = d.error
              ? `error: ${d.error?.toLowerCase().replaceAll('_', ' ')}`
              : ''
            return (
              <StyledText key={`del-item-${index}`}>
                {d.deviceSerialNumber}
                <StyledDeviceName></StyledDeviceName>
                <StyledError>{errorMsg}</StyledError>
              </StyledText>
            )
          }
        }
      )}

      <StyledCancelButton
        id="button-cancel"
        onClick={() => history.push(backLink)}
      >
        Back to Fuel Cells
      </StyledCancelButton>
    </StyledCard>
  )
}

export default DelegationResultUI
