// @flow

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  PageTitle,
  PageSubTitle,
  Text,
  Button,
  Container
} from 'components/ReUsable'

const NoDecorationLink = styled(Link)`
  margin-bottom: 14px;
  margin-top: -4px;
  &:focus, &:hover, &:active {
    text-decoration: none;
  }
`

const SubTitle = styled.span`
  color: var(--color-primary);
  font-size: 14px;
`

export default function LegalNotice () {
  return (
    <Container className="bg-content mt-5 p-5">
      <Row margin='0 0 37px 0'>
        <Col>
          <PageTitle>Legal Notice</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubTitle>SFC Energy AG</SubTitle>
          <Text>
            Eugen-Sänger-Ring 7<br/>
            85649 Brunnthal-Nord
          </Text>
          <Text>
            tel: +49 89 67 35 92-0<br/>
            fax: +49 89 67 35 92-169<br/>
            email: info@sfc.com
          </Text>
          <Text>
            Board:<br/>
            Dr. Peter Podesser (CEO), Daniel Saxena (CFO), Hans Pol (COO)<br/>
            Chairwoman of the board: Sunaina Sinha Haldea
          </Text>
          <Text>
            Registry court:<br/>
            Munich County Court<br/>
            Registry number: HRB 144296<br/>
            VAT ID no.: DE 813032317<br/>
            WEEE reg. no.: DE 85008411
          </Text>
          <Text>
            Data Security Officer:<br/>
            Kivanç Semen<br/>
            DataCo GmbH, Dachauer Str. 65, D-80335 Munich<br/>
            email: datenschutz@dataguard.de
          </Text>
          <Text>
            Note according to § 36 VSBG<br/>
            SFC Energy AG is neither obligated nor willing to take part in a dispute settlement process before a consumer arbitration board. EU Commission Platform for online settlement of disputes: <a href="https://www.ec.europa.eu/consumers/odr">https://www.ec.europa.eu/consumers/odr</a>
          </Text>
          <PageSubTitle>Disclaimer</PageSubTitle>
          <SubTitle>1. Contents of the online offer</SubTitle>
          <Text>
            The author assumes no responsibility that the information provided is up-to-date, correct, or complete, or for the quality of the information provided. Liability claims against the author which relate to material or non-material damage that was caused by the use or non-use of information provided or by the use of incorrect or incomplete information, are fundamentally excluded insofar as there is no demonstrable fault of an intentional or grossly negligent nature on the part of the author. All offers are subject to alteration and are non-binding. The author expressly reserves the right to change, amend, delete, or temporarily or permanently remove from publication parts or pages or the entire offer without special notice.
          </Text>
          <SubTitle>2. References and links</SubTitle>
          <Text>
            In the case of direct or indirect references to external websites (“hyperlinks”), which are outside the responsibility of the author, a liability obligation would only come into effect if the author knew about the contents and it was technically possible and reasonable for the author to prevent the use in the event of illegal contents The author expressly declares that, at the time when the links were created, there was no illegal content on the linked pages. The author has no influence on the current and future design, contents, or authorship of the linked pages. The author therefore expressly distances himself from all contents of all linked pages which were changed after the links were created. This statement applies to all links and references within the author’s own internet contents as well as for external entries in guest books, discussion fora, link directories, mailing lists and all other forms of databases set up by the author which have external writing access. The provider of the page, which was referred to, is exclusively liable for illegal, incorrect or incomplete contents and, in particular, for damage caused by the use or non-use of such information provided, not the person who merely refers to such publications via links.
          </Text>
          <SubTitle>3. Copyright and trademark law</SubTitle>
          <Text>
            The author endeavours to observe the copyrights of the images, graphics, sound documents, video sequences and texts in all publications, to use images, graphics, sound documents, video sequences and texts created by the author or to make use of licence-free graphics, sound documents, video sequences and texts. All trademarks and brand names mentioned within the internet contents which may be protected by third parties, are subject in full to the terms of the relevant copyright law and the right of ownership as applied to the registered owner in each case. A sole mentioning should not lead to the assumption that the trademark is not protected by the rights of a third party! The copyright for published objects created by the author himself, remains with the author of the pages. Reproduction or use of such graphics, sound documents, video sequences and texts in other electronic or printed publications is not permitted without the express permission by the author.
          </Text>
          <SubTitle>4. Privacy</SubTitle>
          <Text>
            You can find all information about our Privacy Policy here:
          </Text>
          <NoDecorationLink to='/gdpr' target="_blank">
            <Button type='button' className='btn-add-device'>
              privacy procurement
            </Button>
          </NoDecorationLink>
          <SubTitle>5. Terms of Use</SubTitle>
          <Text>
            You can find all information about our Terms of Use here:
          </Text>
          <NoDecorationLink to='/termsofuse' target="_blank">
            <Button type='button' className='btn-add-device'>
              Terms of Use
            </Button>
          </NoDecorationLink>
          <SubTitle>6. Legal effect of this liability disclaimer</SubTitle>
          <Text>
            This liability disclaimer must be seen as part of the internet contents from which this page was referred to. If parts or individual expressions of this text do not correspond, no longer correspond or do not correspond completely to the current legal situation, the remaining parts of this document remain unaffected in their contents and validity.
          </Text>
        </Col>
      </Row>
    </Container>
  )
}
