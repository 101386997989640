import { useState, useEffect, ReactNode } from 'react'
import Collapse from '@mui/material/Collapse'
import styled from 'styled-components'
import { format } from 'date-fns'

import { AttributeInformationResponse, ListInformationResponse, TextInformationResponse } from 'apis/advancedInfomation'
import { ALERT_TYPE, Alert, IconButton } from 'components/ReUsable'
import { ErrorResponse, isErrorResponse } from 'apis'

const ERROR_TEXT = 'An error occured. Please try again later.'
const ERROR_TEXT_NO_FUEL_CELL = 'No fuel manager could be found on the device.'

const StyledSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 21px;
  }
`

const StyledHeader = styled.div`
  width: 100%;
  height: 57px;
  padding: 1rem;
  background: var(--color-background-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledCardSectionHeader = styled.div`
  font-size: 1.6rem;
  color: var(--color-table-text);
  font-weight: light;
  font-family: DigitalSerialBold;
  cursor: pointer;

  @media (max-width: 991.97px) {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`

const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`

const StyledLoaded = styled.span`
  font-family: open-sans-regular;
  color: var(--color-text);
  font-size: 14px;

  @media (max-width: 991.97px) {
    font-size: 11px;
  }
`

const StyledActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledErrorSection = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 7px;
  background: var(--color-background-secondary);
`

type AdvancedInfoSectionType = {
  serialNumber: string
  header: string
  commandName: string
  apiCall: (serialNumber: string, commandName: string) => Promise<TextInformationResponse | AttributeInformationResponse | ListInformationResponse | ErrorResponse>
  renderChild: (resp: TextInformationResponse | AttributeInformationResponse | ListInformationResponse | ErrorResponse | null, loading: boolean, error?: boolean) => ReactNode
  disabled: boolean
}

function AdvancedInfoSection ({ serialNumber, header, commandName, apiCall, renderChild, disabled }: AdvancedInfoSectionType) {
  const [expand, setExpand] = useState(false)
  const [response, setResponse] = useState<null | TextInformationResponse | AttributeInformationResponse | ListInformationResponse | ErrorResponse>(null)
  const [loading, setLoading] = useState<any>(false)
  const [errorCode, setErrorCode] = useState<null | number>(0)

  useEffect(() => {
    if (disabled) {
      setExpand(false)
    }
  }, [disabled])

  const handleCollapse = async () => {
    if (disabled) {
      setExpand(false)
      return
    }
    setExpand(!expand)
    if (!expand) {
      await fetchData()
    }
  }
  const fetchData = async () => {
    try {
      setResponse(null)
      if (!expand) setExpand(true)
      setLoading(true)
      const resp = await apiCall(serialNumber, commandName)
      if (isErrorResponse(resp)) {
        setErrorCode(resp.status)
      }
      setResponse(resp)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data: ', error)
    }
  }

  return (
    <StyledSection>
      <StyledHeader data-cy={header}>
        <StyledCardSectionHeader onClick={handleCollapse}>{header}</StyledCardSectionHeader>
        <StyledHeaderActions>
          {expand && !disabled ? (
            <StyledLoaded data-cy="loaded-at">{(response as any)?.loadedAt ? `Loaded on ${format(new Date((response as any)?.loadedAt), 'dd.MM.yyyy HH:mm:ss')}` : ''}</StyledLoaded>
          ) : null}
          <StyledActionButtonsContainer>
            {expand && !disabled ? <IconButton onClick={fetchData} dataCy="refresh" src="refresh" width="24px" disabled={loading} /> : null}
            <IconButton
              disabled={disabled}
              onClick={handleCollapse}
              dataCy="expand"
              src="arrow-right"
              width="36px"
              style={{ transform: 'rotate(90deg)', transition: 'transform .25s ease-in-out', ...(expand && { transform: 'rotate(-90deg)' }) }}
            />
          </StyledActionButtonsContainer>
        </StyledHeaderActions>
      </StyledHeader>
      <Collapse in={expand} timeout="auto" unmountOnExit data-cy={`${header}-section-content`}>
        {errorCode === 404 ? (
          <StyledErrorSection>
            <Alert message={ERROR_TEXT_NO_FUEL_CELL} type={ALERT_TYPE.danger} />
          </StyledErrorSection>
        ) : errorCode !== 0 ? (
          <StyledErrorSection>
            <Alert message={ERROR_TEXT} type={ALERT_TYPE.danger} />
          </StyledErrorSection>
        ) : (
          renderChild(response, loading)
        )}
      </Collapse>
    </StyledSection>
  )
}

export default AdvancedInfoSection
