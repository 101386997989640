// @flow

import { NavLink, useLocation } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { integrationTestRunning } from 'utils/helpers'
import styled from 'styled-components'
import { CurrentDeviceType } from 'types/devices'
import { toggleDialog } from '../../../actions'
import { CONSENT_STORAGE_PROP } from 'appConstants'

type Props = {
  dialog: { z; isDialogOpen: boolean }
  onForceUpdate: () => void
}

const Wrapper = styled.footer<{ userHasLocalSession: boolean }>`
  padding-top: 20px;
  margin-top: auto;
  background-color: ${(_: any) => (_.userHasLocalSession ? 'var(--color-background-secondary)' : 'var(--color-lightest)')};
`

const Container = styled.div`
  margin: auto !important;
`

const Text = styled.div`
  color: var(--color-text);
  font-size: 11px;
  text-align: center;
`

const GrayText = styled(Text)`
  color: var(--color-text-light);
  letter-spacing: 0.9px;
  font-size: 11px;
`

const urlPattern = /^\/devices\/\d{5,}/

export function Footer (props: Props) {
  const { isDialogOpen } = props.dialog
  const { onForceUpdate } = props
  const location = useLocation()
  const deviceId: string | null = urlPattern.test(location.pathname) ? location.pathname.replace('/devices/', '') : null
  const currentDevice: CurrentDeviceType | undefined = useSelector((state) => (state as any).devices?.current)

  const userHasLocalSession = localStorage.getItem('session') === 'auth-set' || process.env.npm_lifecycle_event === 'test' || integrationTestRunning(process.env.REACT_APP_ENV_MODE)

  const contactLink = currentDevice && deviceId && currentDevice.serialNumber === deviceId ? `/contact?deviceName=${encodeURIComponent(currentDevice.name)}&serialNumber=${deviceId}` : '/contact'

  const handleCookieSettingsChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    localStorage.removeItem(CONSENT_STORAGE_PROP)
    //  Rerender components after localStorage change in order immediately displaying request consent popup.
    onForceUpdate()
  }

  return (
    <Wrapper className={`text-muted ${isDialogOpen ? 'blur-element' : ''}`} {...{ userHasLocalSession }}>
      <Container className="container">
        <Text>
          <NavLink data-cy="cloud-legal-link" to="/legalnotice">
            LEGAL NOTICE
          </NavLink>
          &nbsp;/&nbsp;
          <NavLink data-cy="cloud-support-link" to={contactLink}>
            CLOUD SUPPORT
          </NavLink>
          &nbsp;/&nbsp;
          <NavLink data-cy="privacy-policy-link" to="/gdpr">
            PRIVACY POLICY
          </NavLink>
          &nbsp;/&nbsp;
          <NavLink data-cy="terms-of-use" to="/termsofuse">
            TERMS OF USE
          </NavLink>
          &nbsp;/&nbsp;<a href="https://www.efoy-pro.com/service/cloud-license/">EFOY CLOUD LICENSES</a>
          &nbsp;/&nbsp;
          <a data-cy="cookie-settings" href="" onClick={handleCookieSettingsChange}>
            COOKIE SETTINGS
          </a>
        </Text>
        <br />
        <GrayText>© SFC Energy AG {new Date().getFullYear()} | www.sfc.com</GrayText>
        <GrayText>SFC Energy AG | Eugen-Sänger-Ring 7 | 85649</GrayText>
        <GrayText>Brunnthal-Nord</GrayText>
      </Container>
    </Wrapper>
  )
}

const mapState = (state) => ({
  dialog: state.dialog
})

const mapDispatch = { toggleDialog }

export default connect(mapState, mapDispatch)(Footer)
