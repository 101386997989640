import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getEvents, renderTitleWithStyles } from 'components/ReUsable/Charts/utils/utils'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setZoom, setNewRef } from 'actions'

const zoomState = (state) => ({
  xExtremes: state.graphReducer.xExtremes
})
// eslint-disable-next-line
const MultisenseGraph = ({ deviceType, graphEvents, multisenseGraphData }) => {
  const multisenseChartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [originalZoomCordination, setOriginalZoomCordination] = useState<any>(null)

  const { xExtremes } = useSelector(zoomState)
  const dispatch = useDispatch()
  const _setZoom = (payload: any) => dispatch(setZoom(payload))
  const _setNewRef = (payload: any) => dispatch(setNewRef(payload))

  useEffect(() => {
    _setNewRef({ currentRef: multisenseChartComponentRef })
  }, [multisenseChartComponentRef])

  useEffect(() => {
    if (originalZoomCordination === null && multisenseChartComponentRef?.current?.chart) {
      setOriginalZoomCordination({
        xMin: multisenseChartComponentRef.current.chart.xAxis[0].min as number,
        xMax: multisenseChartComponentRef.current.chart.xAxis[0].max as number,
        yMin: multisenseChartComponentRef.current.chart.yAxis[0].min as number,
        yMax: multisenseChartComponentRef.current.chart.yAxis[0].max as number
      })
    }

    if (xExtremes && multisenseChartComponentRef.current) {
      multisenseChartComponentRef.current.chart.xAxis[0].setExtremes(xExtremes[0], xExtremes[1], false)
      multisenseChartComponentRef.current.chart.redraw()
    }
  }, [xExtremes])

  const syncZoom = (e: any) => {
    const thisChart = e.target.chart
    const xAxis = thisChart.xAxis[0]
    _setZoom({ xExtremes: [xAxis.min as number, xAxis.max as number] })
  }

  const customResetZoom = () => {
    if (multisenseChartComponentRef.current) {
      // Custom behavior before resetting zoom

      // Reset zoom programmatically
      // historyChartRef.current.chart.zoomOut()
      // Or manually reset extremes to null
      _setZoom({ xExtremes: [originalZoomCordination.xMin as number, originalZoomCordination.xMax as number] })
      multisenseChartComponentRef.current.chart.xAxis[0].setExtremes(originalZoomCordination.xMin, originalZoomCordination.xMax, false)
      // set yExtremes to yMin and yMax
      multisenseChartComponentRef.current.chart.yAxis[0].setExtremes(originalZoomCordination.yMin, originalZoomCordination.yMax, false)
      multisenseChartComponentRef.current.chart.redraw()
      // hide zoom reset button
      multisenseChartComponentRef.current.container.current?.querySelector('.highcharts-reset-zoom')?.classList.add('hidden')
    }
  }

  const createMultisenseOptions = (type, devicetype, events, series) => {
    const options: Highcharts.Options = {
      title: {
        text: type,
        align: 'left',
        useHTML: true
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: true,
              lineWidth: 4
            },
            inactive: {
              opacity: 1,
              enabled: false
            }
          },
          label: {
            connectorAllowed: false
          },
          lineWidth: 4,
          marker: {
            enabled: false // symbols on the graph series
          },
          point: {}
        }
      },
      xAxis: {
        title: {
          text: ''
        },
        crosshair: true,
        labels: {
          distance: 10,
          rotation: 0
        },
        width: '100%',
        type: 'datetime',
        plotLines: getEvents(events, devicetype),
        events: {
          afterSetExtremes: (e) => {
            // zoomCharts(e, chartComponentRef.current)
            // synczoom here
            syncZoom(e)
            multisenseChartComponentRef?.current?.container?.current?.querySelector('.highcharts-reset-zoom')?.classList.remove('hidden')
          }
        }
      },
      yAxis: [
        {
          title: {
            style: {
              fontFamily: 'open-sans-regular'
            },
            align: 'middle',
            rotation: 270,
            text: series && `${renderTitleWithStyles(series, 0)}`
          },
          labels: {
            distance: 15,
            format: '{value}'
          },
          lineWidth: 1, // This line makes the Y-axis bar visible
          // gray color
          lineColor: 'rgba(80, 80, 80, 0.5)' // Sets the color of the Y-axis line (red in this case)
        },
        {
          title: {
            text: series && `${renderTitleWithStyles(series, 1)}`,
            style: {
              fontWeight: 'bold',
              fontFamily: 'open-sans-regular'
            },
            align: 'middle'
          },
          opposite: true,
          labels: {
            distance: 12,
            format: '{value}'
          },
          lineWidth: 1, // This line makes the Y-axis bar visible
          // gray color
          lineColor: 'rgba(80, 80, 80, 0.5)' // Sets the color of the Y-axis line (red in this case)
        }
      ],
      series: series,
      responsive: {
        rules: [
          {
            condition: {},
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                useHTML: true,
                itemStyle: {
                  fontSize: '12px',
                  fontFamily: 'open-sans-regular'
                }
              },
              xAxis: {
                labels: {
                  // format: '{value:%H:%M}'
                }
              },
              yAxis: {
                labels: {}
              }
            }
          }
        ]
      },
      chart: {
        type: 'spline',
        marginRight: 80, // Adds 50 pixels of margin to the right side of the chart
        height: 400,
        zooming: {
          type: 'xy',
          mouseWheel: true
        },
        events: {
          selection: function (event) {
            if (event.resetSelection) {
              // Custom behavior when the "Reset Zoom" button is clicked
              customResetZoom()
              // Optionally perform additional actions here
              return false // Prevent default zoom-out action
            }
          },
          load: function () {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const chart = multisenseChartComponentRef?.current?.chart
            // Customize the appearance or behavior of the default "Reset Zoom" button
          }
        }
      },
      credits: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
      tooltip: {
        shared: true,
        distance: 30,
        padding: 5,
        useHTML: true,
        style: {
          fontSize: '14px',
          fontFamily: 'open-sans-regular'
        },
        headerFormat: '<div class="tooltip-component highcharts-tooltip-box"><table><tr><th colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' + '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '<tr class="event-warning hidden"><td>Event</td><td>{point.key}</td></tr></table></div>'
      },
      time: {
        useUTC: false
      }
    }
    return options
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...createMultisenseOptions('Multisense', deviceType, graphEvents, multisenseGraphData)
      }}
      ref={multisenseChartComponentRef}
    />
  )
}

export default MultisenseGraph
