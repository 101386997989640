// @flow

import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Spinner
} from 'react-bootstrap'
import styled from 'styled-components'

import {
  Button, PermissionCheck
} from 'components/ReUsable'

import AlertConfigMessages from 'components/ReUsable/Alert/alertConfigMessages'

import {
  RESET_FUEL_CELL_POLLING_MS_INTERVAL,
  WAITING_COMPLETE,
  DEVICE_DETAILS_PERMISSIONS_LIST,
  LOADING_SPINNER,
  RESET_FUEL_CELL_SUCCESS_MESSAGE,
  RESET_COMPLETE
} from 'appConstants'
import OperatingModeToggle from './operatingModeToggle'

const SectionTitle = styled.p`
  color: var(--color-text);
  font-family: digital-serial;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding-top: 1.5rem;
`

const SectionText = styled.p`
  color: var(--color-text);
  font-family: DigitalSerialBold;
  line-height: 1.4rem;
  font-size: 13px;
`

const ContentDiv = styled.div`
  padding: 0px 50px 25px;
`

const LengendText = styled.p`
  color: var(--color-text);
  font-size: 16px;
  font-family: open-sans-regular;
  line-height: 21px;
  padding-top: 20px;
`

const LengendTextitle = styled.span`
  font-weight: bold;
`
const StyledResetFuelCellStatus = styled.span`
  font-size: 16px;
  font-family: open-sans-regular;
  color: var(--color-primary);
`

type ResetFuelCellStatusProps = {
  serialNumber: string,
  resetStatus: string,
  resetFuelCell: (obj: any) => void,
  resetFuelCellStatus: (obj: any) => void,
  permissions: any
}

const ResetFuelCellStatus = (props: ResetFuelCellStatusProps) => {
  const { serialNumber, resetFuelCell, resetStatus, resetFuelCellStatus, permissions } = props
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null)
  const callResetStatus = () => {
    resetFuelCellStatus({ serialNumber })
  }

  const executeTimer = () => {
    if (intervalId) return
    const id = setInterval(() => {
      callResetStatus()
    }, RESET_FUEL_CELL_POLLING_MS_INTERVAL)
    setIntervalId(id)
  }

  //  Check reset status immediately if the user opens up the modal.
  useEffect(() => {
    callResetStatus()
  }, [])

  // Use useEffect to clean up the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (resetStatus === RESET_COMPLETE) {
        if (intervalId) {
          clearInterval(intervalId)
          setIntervalId(null)
        }
        return
    }
    executeTimer()
  }, [resetStatus])

  switch (resetStatus) {
    case LOADING_SPINNER:
    case WAITING_COMPLETE:
      return <Spinner animation='border' variant='secondary' />
    case RESET_COMPLETE:
    default:
      return !intervalId && resetStatus === RESET_COMPLETE ? <StyledResetFuelCellStatus>{RESET_FUEL_CELL_SUCCESS_MESSAGE}</StyledResetFuelCellStatus> : (<PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.RESET]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.RESET}
      >
        <Button onClick={() => {
          resetFuelCell({ serialNumber })
        }}>reset</Button>
      </PermissionCheck>)
  }
}

type Props = {
  serialNumber: string,
  operatingMode: string,
  requestErrorMessage: string,
  chargeFuelCellSuccess: string,
  resetStatus: string,
  chargeFuelCell: (obj: any) => void,
  updateOperatingMode: (obj: any) => void,
  resetFuelCell: (obj: any) => void,
  resetFuelCellStatus: (obj: any) => void,
  togglePageDialog: () => void,
  isRequesting: boolean,
  permissions: any,
}

export const FuelCellSettingsEditContent = (props: Props) => {
  const {
    serialNumber,
    operatingMode,
    chargeFuelCell,
    resetFuelCell,
    resetFuelCellStatus,
    updateOperatingMode,
    resetStatus,
    requestErrorMessage,
    isRequesting,
    permissions
  } = props

  const pageDialog = {
    requestErrorMessage,
    fuelCellConfigSavedSuccess: isRequesting !== undefined && !isRequesting && requestErrorMessage.length === 0
  }
    return (
      <ContentDiv>
        <AlertConfigMessages pageDialog={pageDialog} />
        <SectionTitle>Operating mode</SectionTitle>
        <SectionText>You can choose between automatic and sleep operating mode.</SectionText>
        <OperatingModeToggle {...{ serialNumber, permissions, updateOperatingMode, operatingMode, chargeFuelCell }} />
        <SectionTitle>Reset EFOY Fuel Cell</SectionTitle>
        <ResetFuelCellStatus
          serialNumber={serialNumber}
          resetStatus={resetStatus?.[serialNumber]}
          resetFuelCell={resetFuelCell}
          resetFuelCellStatus={resetFuelCellStatus}
          permissions={permissions}
        />
        <LengendText>
          <LengendTextitle>AUTO:</LengendTextitle> The fuel cell will switch on and off according to the preset thresholds.
        </LengendText>
        <LengendText>
          <LengendTextitle>Sleep:</LengendTextitle> The fuel cell will switch off. Frost protection and battery protection will be performed if necessary.
        </LengendText>
        <LengendText>
          <LengendTextitle>Charge now:</LengendTextitle> The fuel cell will start charging if possible. The operating mode will switch to AUTO.
        </LengendText>
      </ContentDiv>
    )
}

const mapState = (state: any): any => (
  {
    isRequesting: state.request.isRequesting
  }
)

export default connect<object, object, any>(
  mapState
)(FuelCellSettingsEditContent)
